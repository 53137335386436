import React from "react";

function Card({
  padding,
  borderColor = "rgba(194, 191, 184, 0.35)",
  children,
}) {
  return (
    <div
      className={`flex flex-col h-full border ${
        padding ? padding : "p-0.625"
      } bg-gray-background rounded-medium transition-all duration-1000`}
      style={{ border: `1px solid ${borderColor}` }}
    >
      {children}
    </div>
  );
}

export default Card;
