import React from "react";

const SankeyChart = ({
  selectedInteraction,
  totalInteractionsResponded = 0,
  totalInteractionsClicked = 0,
  totalInteractionsKeyDown = 0,
  totalInteractionsDataEntered = 0,
  totalInteractionsNoEngagement = 0,
  totalInteractionsReported = 0,
  totalInteractionsQRCodesScanned = 0,
  totalInteractionsDownloadAttachments = 0,
  totalInteractionsMFAEntered = 0,
  onClickInteraction,
}) => {
  const handleClickInteraction = (interactionType, total) => {
    onClickInteraction(interactionType, total);
  };

  const renderText = (x, y, text, interactions, interactionType) => {
    return (
      <text
        className={`${
          interactionType !== "no_action" && "cursor-pointer"
        } font-medium text-[10px] uppercase`}
        x={x}
        y={y}
        fill="#000000"
        onClick={
          interactionType !== "no_action"
            ? () => handleClickInteraction(interactionType, interactions)
            : null
        }
      >
        <tspan className="font-bold text-[12px]">
          {interactions.toLocaleString("en-US")}
        </tspan>{" "}
        {text}
      </text>
    );
  };

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1009 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Beginning node */}
      <rect y="49" width="6.64036" height="110" rx="3.32018" fill="#DCDADA" />
      {/* Links Clicked */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 67.4384V75C81.8982 75 81.8982 17.452 153.835 17.452V8C81.8982 8 81.8982 67.4384 9.96094 67.4384Z"
        fill="url(#paint5_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="3"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="167.116"
        width="123"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction("phished", totalInteractionsClicked)
        }
      />
      {renderText(
        "177.116",
        "17",
        "Links Clicked",
        totalInteractionsClicked,
        "phished"
      )}
      {selectedInteraction === "phished" && (
        <>
          <g filter="url(#filter0_f_16626_12099)">
            <rect
              x="167.116"
              y="28"
              width="123"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="167.116"
            y="28"
            width="123"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* QR Code Scanned */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 81.4384V89C81.8982 89 81.8982 53.452 153.835 53.452V44C81.8982 44 81.8982 81.4384 9.96094 81.4384Z"
        fill="url(#paint4_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="39"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="167.116"
        y="36"
        width="146"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction(
            "qr_codes_scanned",
            totalInteractionsQRCodesScanned
          )
        }
      />
      {renderText(
        "177.116",
        "53",
        "QR Codes Scanned",
        totalInteractionsQRCodesScanned,
        "qr_codes_scanned"
      )}
      {selectedInteraction === "qr_codes_scanned" && (
        <>
          <g filter="url(#filter0_f_16626_12099_2)">
            <rect
              x="167.116"
              y="64"
              width="146"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="167.116"
            y="64"
            width="146"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* Download Attachments */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 95.4426V103C81.8982 103 81.8982 91.4467 153.835 91.4467V82C81.8982 82 81.8982 95.4426 9.96094 95.4426Z"
        fill="url(#paint3_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="75"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="167.116"
        y="72"
        width="185"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction(
            "downloads",
            totalInteractionsDownloadAttachments
          )
        }
      />
      {renderText(
        "177.116",
        "89",
        "Download Attachments",
        totalInteractionsDownloadAttachments,
        "downloads"
      )}
      {selectedInteraction === "downloads" && (
        <>
          <g filter="url(#filter0_f_16626_12099_3)">
            <rect
              x="167.116"
              y="100"
              width="185"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="167.116"
            y="100"
            width="185"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* Tests Responded To */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 110V117.565C81.8982 117.565 81.8982 126 153.835 126V116.543C81.8982 116.543 81.8982 110 9.96094 110Z"
        fill="url(#paint2_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="111"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="167.116"
        y="108"
        width="151"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction("smtp_reply", totalInteractionsResponded)
        }
      />
      {renderText(
        "177.116",
        "125",
        "Tests Responded To",
        totalInteractionsResponded,
        "smtp_reply"
      )}
      {selectedInteraction === "smtp_reply" && (
        <>
          <g filter="url(#filter0_f_16626_12099_4)">
            <rect
              x="167.116"
              y="136"
              width="151"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="167.116"
            y="136"
            width="151"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* Tests Ignored */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 124V131.565C81.8982 131.565 81.8982 162 153.835 162V152.543C81.8982 152.543 81.8982 124 9.96094 124Z"
        fill="url(#paint1_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="147"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#EBE9E0"
      />
      <rect x="167.116" y="144" width="144" height="26" rx="8" fill="white" />
      {renderText(
        "177.116",
        "161",
        "Tests Ignored",
        totalInteractionsNoEngagement,
        "no_action"
      )}
      {/* Reported to Watchtower */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.96094 139V146.565C81.8982 146.565 81.8982 197 153.835 197V187.543C81.8982 187.543 81.8982 139 9.96094 139Z"
        fill="url(#paint0_linear_14884_1612)"
      />
      <rect
        x="157.155"
        y="183"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#00FF82"
      />
      <rect
        className="cursor-pointer"
        x="167.116"
        y="180"
        width="190"
        height="26"
        rx="8"
        fill="#00FF82"
        onClick={() =>
          handleClickInteraction(
            "watchtower_reported",
            totalInteractionsReported
          )
        }
      />
      {renderText(
        "177.116",
        "197",
        "Reported to Watchtower",
        totalInteractionsReported,
        "watchtower_reported"
      )}
      {selectedInteraction === "watchtower_reported" && (
        <>
          <g filter="url(#filter0_f_16626_12099_5)">
            <rect
              x="167.116"
              y="208"
              width="190"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="167.116"
            y="208"
            width="190"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* Credentials Entered */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416 79.4373V87C353.5 87 353.5 17.4534 291 17.4534V8C353.5 8 353.5 79.4373 416 79.4373Z"
        fill="url(#paint6_linear_14884_1612)"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416 79.7734V87C364.077 87 367.923 53.0332 316 53.0332V44C367.923 44 364.077 79.7734 416 79.7734Z"
        fill="url(#paint7_linear_14884_1612)"
      />
      <rect
        x="419.449"
        y="75"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#FFEC77"
      />
      <rect
        className="cursor-pointer"
        x="429.41"
        y="72"
        width="160"
        height="26"
        rx="8"
        fill="#FFEC77"
        onClick={() =>
          handleClickInteraction("key_down", totalInteractionsKeyDown)
        }
      />
      {renderText(
        "439.41",
        "89",
        "Credentials Entered",
        totalInteractionsKeyDown,
        "key_down"
      )}
      {selectedInteraction === "key_down" && (
        <>
          <g filter="url(#filter0_f_16626_12099_6)">
            <rect
              x="429.41"
              y="100"
              width="160"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="429.41"
            y="100"
            width="160"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* Credentials Submitted */}
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M651 91L651 79C620.5 79 620.5 79 590 79L590 91C620.5 91 620.5 91 651 91Z"
        fill="url(#paint8_linear_14884_1612)"
      />
      <rect
        x="654.077"
        y="75"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#FFB55F"
      />
      <rect
        className="cursor-pointer"
        x="664.038"
        y="72"
        width="165"
        height="26"
        rx="8"
        fill="#FFB55F"
        onClick={() =>
          handleClickInteraction("data_entered", totalInteractionsDataEntered)
        }
      />
      {renderText(
        "674.038",
        "89",
        "Credentials Submitted",
        totalInteractionsDataEntered,
        "data_entered"
      )}
      {selectedInteraction === "data_entered" && (
        <>
          <g filter="url(#filter0_f_16626_12099_7)">
            <rect
              x="664.038"
              y="100"
              width="165"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="664.038"
            y="100"
            width="165"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}
      {/* MFA Entered */}
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M890 91L890 79C859 79 859 79 828 79L828 91C859 91 859 91 890 91Z"
        fill="url(#paint9_linear_14884_1612)"
      />
      <rect
        x="893.129"
        y="75"
        width="6.64036"
        height="20"
        rx="3.32018"
        fill="#FF6D55"
      />
      <rect
        className="cursor-pointer"
        x="903.09"
        y="72"
        width="105"
        height="26"
        rx="8"
        fill="#FF6D55"
        onClick={() =>
          handleClickInteraction("mfa_entered", totalInteractionsMFAEntered)
        }
      />
      {renderText(
        "913.09",
        "89",
        "MFA Entered",
        totalInteractionsMFAEntered,
        "mfa_entered"
      )}
      {selectedInteraction === "mfa_entered" && (
        <>
          <g filter="url(#filter0_f_16626_12099_8)">
            <rect
              x="903.09"
              y="100"
              width="105"
              height="2.35373"
              rx="1.17687"
              fill="#00FF82"
            />
          </g>
          <rect
            x="903.09"
            y="100"
            width="105"
            height="2.35373"
            rx="1.17687"
            fill="#00FF82"
          />
        </>
      )}

      <defs>
        <linearGradient
          id="paint0_linear_14884_1612"
          x1="85.0259"
          y1="119.772"
          x2="85.0259"
          y2="195.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFECE3" />
          <stop offset="1" stop-color="#00FF82" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14884_1612"
          x1="85.0259"
          y1="104.772"
          x2="85.0259"
          y2="180.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFECE3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14884_1612"
          x1="92.5"
          y1="104"
          x2="76"
          y2="148.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14884_1612"
          x1="98"
          y1="60"
          x2="71.5"
          y2="141.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14884_1612"
          x1="85.0259"
          y1="44"
          x2="85.0259"
          y2="112.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14884_1612"
          x1="23.5"
          y1="66"
          x2="154"
          y2="11.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_14884_1612"
          x1="350.783"
          y1="46.2155"
          x2="350.783"
          y2="121.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B7B7B7" />
          <stop offset="1" stop-color="#FEE442" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_14884_1612"
          x1="365.666"
          y1="80.517"
          x2="365.666"
          y2="152.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B7B7B7" />
          <stop offset="1" stop-color="#FEE442" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_14884_1612"
          x1="651.377"
          y1="85"
          x2="590.377"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F59A31" />
          <stop offset="1" stop-color="#FEE442" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_14884_1612"
          x1="890.383"
          y1="85"
          x2="828.383"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EF3C1F" />
          <stop offset="1" stop-color="#F59A31" />
        </linearGradient>
        <filter
          id="filter0_f_16626_12099"
          x="167.116"
          y="22"
          width="135"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_2"
          x="167.116"
          y="58"
          width="146"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_3"
          x="167.116"
          y="94"
          width="185"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_4"
          x="167.116"
          y="130"
          width="151"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_5"
          x="167.116"
          y="202"
          width="190"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_6"
          x="429.116"
          y="92"
          width="160"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_7"
          x="664.038"
          y="92"
          width="165"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
        <filter
          id="filter0_f_16626_12099_8"
          x="903.09"
          y="92"
          width="105"
          height="14.3538"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_16626_12099"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SankeyChart;
