import React from "react";
import { colors } from "../../../../../../../styles";

function GreenMinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21 6"
      fill="none"
    >
      <path
        d="M20 5H20.5V4.5V1.5V1H20H1H0.5V1.5V4.5V5H1H20Z"
        fill={colors.green}
        stroke={colors.green}
      />
    </svg>
  );
}

export default GreenMinusIcon;
