import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getInsightsUsers, getTestHTML, getTestHTMLDemo } from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

import { PersonFilledIcon } from "../../../images";

/**
 * Component for displaying user-level insights.
 * Fetches relevant insights data of a user.
 */
function InsightsEmployees({
  isInitialLoading,
  email,
  setIsPlatformLoading,
  setNewTitle,
}) {
  // Insights
  const [profileData, setProfileData] = useState(null);
  const [blastRadius, setBlastRadius] = useState(null);
  const [riskScore, setRiskScore] = useState(0);
  const [comparedRiskScore, setComparedRiskScore] = useState(null);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [recentTestingActivity, setRecentTestingActivity] = useState(null);
  const [learningModulesActivity, setLearningModulesActivity] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All Time)

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All Time") {
        // If all time, use the master set riskScoreOverAllTime.

        let scoreChange = 0;

        if (
          riskScoreOverAllTime.length &&
          riskScoreOverAllTime[0].riskScore &&
          riskScore
        ) {
          scoreChange = riskScore - riskScoreOverAllTime[0].riskScore;
        }

        setScoreChange(scoreChange);

        setRiskScoreOverTime(riskScoreOverAllTime);
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          }
        );

        let scoreChange = 0;

        if (
          filteredRiskScoreOverTime.length > 0 &&
          filteredRiskScoreOverTime[0].riskScore &&
          riskScore
        ) {
          scoreChange = riskScore - filteredRiskScoreOverTime[0].riskScore;
        }
        setScoreChange(scoreChange);

        setRiskScoreOverTime(filteredRiskScoreOverTime);
      }
    },
    [riskScoreOverAllTime, riskScore]
  );

  const loadTestHTML = async (id) => {
    if (!(profileData && profileData.email)) {
      const errorMessage = "Target email not found";
      const testHTML = {
        result: {
          testHTMLCode: "",
        },
        error: {
          message: errorMessage,
        },
      };

      console.error(errorMessage);

      return testHTML;
    } else if (profileData.email.endsWith("@dune.demo")) {
      // Demo account
      let testHTMLDemo = {};

      if (
        profileData.email === "aaron.chavez@dune.demo" ||
        profileData.email === "duncan.idaho@dune.demo"
      ) {
        testHTMLDemo = await getTestHTMLDemo(id, profileData.email);
      } else {
        testHTMLDemo = await getTestHTMLDemo(id);
      }

      return testHTMLDemo;
    }

    const testHTML = await getTestHTML(id, profileData.email);

    return testHTML;
  };

  useEffect(() => {
    const loadInsightsUsers = async (email) => {
      setIsPlatformLoading(true);

      const insightsUser = await getInsightsUsers(email);

      if (Object.keys(insightsUser.error).length > 0) {
        console.error(insightsUser.error.message);
      } else {
        const {
          profileData,
          blastRadius,
          riskScore,
          userAverage,
          riskScoreOverTime,
          areasToImprove,
          complianceFrameworks,
          recentTestingActivity,
          learningModulesActivity,
        } = insightsUser.result;

        if (email === "aaron.chavez@dune.demo") {
          // Demo account
          setProfileData({
            firstName: "Aaron",
            lastName: "Chavez",
            email: "aaron.chavez@dune.demo",
            department: "Capital",
            role: "Chief Financial Officer",
            directManager: "",
          });
        } else {
          setProfileData(profileData);
        }

        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setBlastRadius({
            level: "Senior Management",
            function: "Finance",
          });
        } else if (email === "aaron.chavez@dune.demo") {
          // Demo account
          setBlastRadius({
            level: "C-Suite",
            function: "Finance",
          });
        } else {
          setBlastRadius(blastRadius);
        }

        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setRiskScore(97);
        } else {
          setRiskScore(riskScore);
        }

        if (email.endsWith("@dune.demo")) {
          // Demo account
          setComparedRiskScore(46);
        } else {
          setComparedRiskScore(userAverage);
        }

        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          let riskScoreOverTime = [];

          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - 90);

          let riskScore = 50;

          const targetScore = 97;
          const totalDays = 90;

          const options = {
            month: "short",
            day: "numeric",
          };

          for (let i = 0; i < totalDays; i++) {
            const formattedDate = currentDate.toLocaleDateString(
              "en-US",
              options
            );

            let stepChange = (targetScore - riskScore) / (totalDays - i);

            let variability = (Math.random() - 0.5) * stepChange;

            let fluctuation =
              Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

            riskScore += stepChange + variability + fluctuation;

            if (i === totalDays - 1 && riskScore < targetScore) {
              riskScore = targetScore;
            } else if (riskScore > targetScore) {
              riskScore = targetScore - Math.random();
            }

            riskScoreOverTime.push({
              date: formattedDate,
              riskScore: Math.round(riskScore),
            });

            currentDate.setDate(currentDate.getDate() + 1);
          }

          setRiskScoreOverTime(riskScoreOverTime);
          setRiskScoreOverAllTime(riskScoreOverTime);
        } else if (email === "aaron.chavez@dune.demo") {
          // Demo account
          let riskScoreOverTime = [];

          const currentDate = new Date(); // Start with today's date.
          currentDate.setDate(currentDate.getDate() - 90);

          let riskScore = 78; // Start with a risk score of 78.

          const targetScore = 14;
          const totalDays = 90;

          const options = {
            month: "short",
            day: "numeric",
          };

          for (let i = 0; i < totalDays; i++) {
            const formattedDate = currentDate.toLocaleDateString(
              "en-US",
              options
            );

            let stepChange = (riskScore - targetScore) / (totalDays - i);

            let variability = (Math.random() - 0.5) * stepChange;

            let fluctuation =
              Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

            riskScore -= stepChange + variability + fluctuation;

            if (i === totalDays - 1 && riskScore > targetScore) {
              riskScore = targetScore;
            } else if (riskScore < targetScore) {
              riskScore = targetScore + Math.random();
            }

            riskScoreOverTime.push({
              date: formattedDate,
              riskScore: Math.round(riskScore),
            });

            currentDate.setDate(currentDate.getDate() + 1);
          }

          setRiskScoreOverTime(riskScoreOverTime);
          setRiskScoreOverAllTime(riskScoreOverTime);
        } else {
          setRiskScoreOverAllTime(riskScoreOverTime);
        }

        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setAreasToImprove([
            {
              name: "Display name deception",
            },
            {
              name: "Fake technical notifications",
            },
            {
              name: "Impersonation of internal processes",
            },
            {
              name: "Link manipulation",
            },
          ]);
        } else {
          setAreasToImprove(areasToImprove);
        }

        if (email === "duncan.idaho@dune.demo") {
          // Demo account for end user
          let recentTestingActivity = [
            {
              id: "1",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: true,
                isKeyDown: true,
                isDataEntered: true,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Adobe",
            },
            {
              id: "2",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "microsoft",
                isVisited: true,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: true,
              internalOrExternal: "Internal",
              difficulty: "Easy",
              attackVector: "Email phishing",
              methodOfAttack: "Impersonation of internal processes",
              impersonator: "Chase",
            },
            {
              id: "3",
              passed: true,
              source: {
                isURLClicked: null,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "ConEdison",
            },
            {
              id: "4",
              passed: true,
              source: {
                isURLClicked: null,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Easy",
              attackVector: "Email phishing",
              methodOfAttack: "Unexpected financial transactions",
              impersonator: "Delta",
            },
            {
              id: "5",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: true,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Display name deception",
              impersonator: "Google",
            },
            {
              id: "6",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: true,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Link manipulation",
              impersonator: "Intuit",
            },
            {
              id: "7",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: true,
                isKeyDown: true,
                isDataEntered: true,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Microsoft",
            },
            {
              id: "8",
              passed: true,
              source: {
                isURLClicked: null,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Display name deception",
              impersonator: "PayPal",
            },
            {
              id: "9",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: true,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Ticketmaster",
            },
            {
              id: "10",
              passed: false,
              source: {
                isURLClicked: true,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "microsoft",
                isVisited: true,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Request for action",
              impersonator: "1Password",
            },
          ];

          const dateForActivity = new Date();
          dateForActivity.setDate(dateForActivity.getDate() - 60);

          const optionsForActivity = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          };

          for (let i = 0; i < recentTestingActivity.length; i++) {
            const randomDays = Math.floor(Math.random() * 3 + 3); // Generates 3, 4 or 5 days.
            dateForActivity.setDate(dateForActivity.getDate() + randomDays);

            const dayOfWeek = dateForActivity.getDay();

            if (dayOfWeek === 0 || dayOfWeek === 6) {
              dateForActivity.setDate(
                dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2)
              );
            }

            recentTestingActivity[i].date = dateForActivity.toLocaleDateString(
              "en-US",
              optionsForActivity
            );
          }

          // Make the date of the last test today's date.
          const today = new Date();
          recentTestingActivity[recentTestingActivity.length - 1].date =
            today.toLocaleDateString("en-US", optionsForActivity);

          setRecentTestingActivity(recentTestingActivity);
        } else if (email === "aaron.chavez@dune.demo") {
          // Demo account for manager
          let recentTestingActivity = [
            {
              id: "1",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Adobe",
            },
            {
              id: "2",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "microsoft",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "Internal",
              difficulty: "Easy",
              attackVector: "Email phishing",
              methodOfAttack: "Impersonation of internal processes",
              impersonator: "Chase",
            },
            {
              id: "3",
              passed: true,
              source: {
                isURLClicked: null,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "ConEdison",
            },
            {
              id: "4",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Easy",
              attackVector: "Email phishing",
              methodOfAttack: "Unexpected financial transactions",
              impersonator: "Delta",
            },
            {
              id: "5",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Display name deception",
              impersonator: "Google",
            },
            {
              id: "6",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Link manipulation",
              impersonator: "Intuit",
            },
            {
              id: "7",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Microsoft",
            },
            {
              id: "8",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Display name deception",
              impersonator: "PayPal",
            },
            {
              id: "9",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "google",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Hard",
              attackVector: "Email phishing",
              methodOfAttack: "Fake technical notifications",
              impersonator: "Ticketmaster",
            },
            {
              id: "10",
              passed: true,
              source: {
                isURLClicked: false,
                isQRScanned: null,
              },
              imposterPortal: {
                subdomain: "microsoft",
                isVisited: false,
                isKeyDown: false,
                isDataEntered: false,
              },
              hasReplied: false,
              internalOrExternal: "External",
              difficulty: "Medium",
              attackVector: "Email phishing",
              methodOfAttack: "Request for action",
              impersonator: "1Password",
            },
          ];

          const dateForActivity = new Date();
          dateForActivity.setDate(dateForActivity.getDate() - 60);

          const optionsForActivity = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          };

          for (let i = 0; i < recentTestingActivity.length; i++) {
            const randomDays = Math.floor(Math.random() * 3 + 3); // Generates 3, 4 or 5 days.
            dateForActivity.setDate(dateForActivity.getDate() + randomDays);

            const dayOfWeek = dateForActivity.getDay();

            if (dayOfWeek === 0 || dayOfWeek === 6) {
              dateForActivity.setDate(
                dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2)
              );
            }

            recentTestingActivity[i].date = dateForActivity.toLocaleDateString(
              "en-US",
              optionsForActivity
            );
          }

          // Make the date of the last test today's date.
          const today = new Date();
          recentTestingActivity[recentTestingActivity.length - 1].date =
            today.toLocaleDateString("en-US", optionsForActivity);

          setRecentTestingActivity(recentTestingActivity);
        } else {
          setRecentTestingActivity(recentTestingActivity);
        }

        if (email === "aaron.chavez@dune.demo") {
          // Demo account
          setComplianceFrameworks([
            {
              name: "SOC 2 Type 2",
              progress: true,
            },
            {
              name: "HIPAA",
              progress: true,
            },
            {
              name: "ISO 27001",
              progress: true,
            },
          ]);
        } else if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setComplianceFrameworks([
            {
              name: "SOC 2 Type 2",
              progress: false,
            },
            {
              name: "HIPAA",
              progress: false,
            },
            {
              name: "ISO 27001",
              progress: false,
            },
          ]);
        } else {
          setComplianceFrameworks(complianceFrameworks);
        }

        if (email === "aaron.chavez@dune.demo") {
          const completionDate = new Date();
          completionDate.setDate(completionDate.getDate() - 7);

          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          };

          const formattedCompletionDate = completionDate
            .toLocaleDateString("en-US", options)
            .replace(/\//g, "/");

          setLearningModulesActivity([
            {
              id: "1",
              name: "Domain Spoofing",
              status: "completed",
              completionDate: formattedCompletionDate,
              justificationSentiment:
                "The user's response is positive and shows engagement with the topic.",
              justificationUnderstanding:
                "The user has a good understanding of the key points discussed in the training, such as verifying sender's email address, avoiding clicking on links in emails, and using strong passwords with two-factor authentication.",
              scoreOverall: 9,
              scoreSentiment: 8,
              userAnswer:
                "Keep an eye out for phishing attempts. Verify senders email address matches the senders name. Do not click on links in email, go to bookmarks or type in address myself. Keep personal data safe. Use strong passwords and 2 factor authentication.",
            },
          ]);
        } else if (email === "duncan.idaho@dune.demo") {
          setLearningModulesActivity([
            {
              id: "1",
              name: "Domain Spoofing",
              status: "Not started",
              completionDate: "",
              justificationSentiment: "",
              justificationUnderstanding: "",
              scoreOverall: 0,
              scoreSentiment: 0,
              userAnswer: "",
            },
            {
              id: "2",
              name: "Malicious Attachments",
              status: "Not started",
              completionDate: "",
              justificationSentiment: "",
              justificationUnderstanding: "",
              scoreOverall: 0,
              scoreSentiment: 0,
              userAnswer: "",
            },
            {
              id: "3",
              name: "Business Email Compromise",
              status: "Not started",
              completionDate: "",
              justificationSentiment: "",
              justificationUnderstanding: "",
              scoreOverall: 0,
              scoreSentiment: 0,
              userAnswer: "",
            },
          ]);
        } else {
          setLearningModulesActivity(learningModulesActivity);
        }

        setIsPlatformLoading(false);
      }
    };

    const updateComponent = async () => {
      if (state && state.email) {
        const email = state.email;

        await loadInsightsUsers(email);
      } else {
        // If there is no email in location, redirect the user to the search employee page.
        navigate("/insights/search-employee/");
      }
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email, state, setIsPlatformLoading, navigate]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  // Update the page title to be the employee's first and last name.
  useEffect(() => {
    if (profileData && (profileData.firstName || profileData.lastName)) {
      setNewTitle(
        <>
          <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#4f4e4a] h4 rounded-full">
            {profileData.initials}
          </div>
          <h1 className="h2 ml-[4px]">
            {profileData.firstName && profileData.firstName}
            {profileData.firstName && profileData.lastName && " "}
            {profileData.lastName && profileData.lastName}
          </h1>
          <div className="flex flex-row items-center bg-gray-badge-dark py-[2px] px-[8px] rounded-[4px] ml-[10px]">
            <div className="w-[12px] h-[12px]">
              <PersonFilledIcon />
            </div>
            <div className="ml-[4px]">
              <p className="detailed-emphasized">Employee</p>
            </div>
          </div>
        </>
      );
    }
  }, [profileData, setNewTitle]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.EMPLOYEES}
      profileData={profileData}
      blastRadius={blastRadius}
      riskScore={riskScore}
      comparedRiskScore={comparedRiskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      recentTestingActivity={recentTestingActivity}
      learningModulesActivity={learningModulesActivity}
      complianceFrameworks={complianceFrameworks}
      onChangeSelectedDays={handleChangeSelectedDays}
      onLoadTestHTML={loadTestHTML}
    />
  );
}

export default InsightsEmployees;
