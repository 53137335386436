import React from 'react';

import '../../../App.css';
import { Translate } from 'react-auto-translate';

const GreenItalicText = ({ text }) => {
  return (
    <span className="text-green italic leading-[1.2] italic-font">
      <Translate>{text}</Translate>
    </span>
  );
};

export default GreenItalicText;
