import React from 'react';

import { Box } from '@mui/material';

import './fade-in-section.css';

function FadeInSection({ children }) {
  const [isVisible, setVisible] = React.useState(false);

  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <Box
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}>
      {children}
    </Box>
  );
}

export default FadeInSection;
