import React from "react";

const GPPGoodFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M11.4882 22.3846C11.7822 22.5416 12.1512 22.5406 12.4452 22.3836C20.4571 18.0796 21.0261 9.6706 21.0191 7.2796C21.0184 7.08743 20.9616 6.89965 20.8557 6.73927C20.7499 6.57888 20.5996 6.45284 20.4232 6.3766L12.3732 2.8106C12.245 2.75413 12.1065 2.72505 11.9665 2.72522C11.8265 2.72539 11.6881 2.75481 11.5602 2.8116L3.56615 6.3776C3.39235 6.45389 3.24414 6.57853 3.13918 6.73668C3.03422 6.89482 2.97693 7.07981 2.97415 7.2696C2.94015 9.6486 3.41915 18.0756 11.4882 22.3846ZM8.67415 10.9236L10.9672 13.2166L15.2602 8.9236L16.6742 10.3376L10.9672 16.0446L7.26015 12.3376L8.67415 10.9236Z"
        fill="white"
      />
    </svg>
  );
};

export default GPPGoodFilledIcon;
