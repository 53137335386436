import React from "react";

const DonutFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9 0.630615V20.6306C3.93 20.1306 0 15.8406 0 10.6306C0 5.42062 3.93 1.13062 9 0.630615ZM11.03 0.630615V9.62062H20C19.53 4.88062 15.76 1.10062 11.03 0.630615ZM11.03 11.6406V20.6306C15.77 20.1606 19.53 16.3806 20 11.6406H11.03Z"
        fill="white"
      />
    </svg>
  );
};

export default DonutFilledIcon;
