import React, { useEffect, useState } from "react";

import { Calendar, CautionIcon, LeftArrowIcon, RightArrowIcon } from "./utils";

import { Card } from "../../..";

import { CheckmarkIcon, FailIcon } from "../../../../../images";

function CardRecentTestingActivity({
  recentTestingActivity,
  firstName,
  onLoadTestHTML,
}) {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [testHTML, setTestHTML] = useState("");

  const getHighlights = () => {
    const highlights = [];

    if (selectedActivity) {
      if (!selectedActivity.passed) {
        // Fail

        // Replied
        if (selectedActivity.hasReplied) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } responded to the message.`,
          });
        }

        // Clicked
        if (selectedActivity.source && selectedActivity.source.isURLClicked) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } clicked on the link.`,
          });
        }

        // QR code scanned
        if (selectedActivity.source && selectedActivity.source.isQRScanned) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } scanned the QR code.`,
          });
        }

        // Download attachemnt
        if (selectedActivity.source && selectedActivity.source.hasDownloaded) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } downloaded the attachment.`,
          });
        }

        // Credentials
        if (
          selectedActivity.imposterPortal &&
          selectedActivity.imposterPortal.isKeyDown
        ) {
          highlights.push({
            icon: <FailIcon />,
            description: `${firstName ? firstName : "You"} entered ${
              firstName ? "their" : "your"
            } credentials.`,
          });
        }

        // MFA code
        if (
          selectedActivity.imposterPortal &&
          selectedActivity.imposterPortal.isMFAEntered
        ) {
          highlights.push({
            icon: <FailIcon />,
            description: `${firstName ? firstName : "You"} entered ${
              firstName ? "their" : "your"
            } MFA code.`,
          });
        }
      } else {
        // Pass

        if (!selectedActivity.hasReported) {
          // Ignore
          highlights.push({
            icon: <CheckmarkIcon />,
            description: `${
              firstName ? firstName : "You"
            } ignored the message.`,
          });
        } else {
          // Reported
          highlights.push({
            icon: <CheckmarkIcon />,
            description: `${
              firstName ? firstName : "You"
            } successfully identified the simulated attack and reported it.`,
          });
        }
      }

      if (highlights.length < 2) {
        // Add additional highlights if there are less than 2 highlights.
        if (!selectedActivity.passed) {
          // Fail
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } failed to successfully identify this simulated attack and did not report it.`,
          });
        } else {
          // Pass
          highlights.unshift({
            icon: <CautionIcon />,
            description:
              "The attack used a domain associated with a phishing simulation email. ",
          });
        }
      }
    }

    return highlights;
  };

  const getFormattedDate = (date, includeYear = true) => {
    const dateObject = new Date(date);

    const options = { month: "long", day: "numeric" };

    if (includeYear) {
      options.year = "numeric";
    }

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObject
    );

    // Add ordinal suffix to the day
    const day = dateObject.getDate();
    let daySuffix;
    if (day % 10 === 1 && day !== 11) {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const dayWithSuffix = day + daySuffix;

    return formattedDate.replace(day, dayWithSuffix);
  };

  const handleClickLeftArrow = () => {
    if (!selectedActivity) {
      setSelectedActivity(recentTestingActivity[0]);
    } else {
      const currentIndex = recentTestingActivity.findIndex(
        (activity) => activity === selectedActivity
      );

      const newIndex =
        currentIndex === 0
          ? recentTestingActivity.length - 1
          : currentIndex - 1;

      setSelectedActivity(recentTestingActivity[newIndex]);
    }
  };

  const handleClickRightArrow = () => {
    if (!selectedActivity) {
      setSelectedActivity(recentTestingActivity[0]);
    } else {
      const currentIndex = recentTestingActivity.findIndex(
        (activity) => activity === selectedActivity
      );

      const newIndex =
        currentIndex === recentTestingActivity.length - 1
          ? 0
          : currentIndex + 1;

      setSelectedActivity(recentTestingActivity[newIndex]);
    }
  };

  useEffect(() => {
    if (
      !selectedActivity &&
      recentTestingActivity &&
      Array.isArray(recentTestingActivity) &&
      recentTestingActivity.length > 0
    ) {
      setSelectedActivity(
        recentTestingActivity[recentTestingActivity.length - 1]
      );
    }
  }, [selectedActivity, recentTestingActivity]);

  useEffect(() => {
    const loadTestHTML = async () => {
      const testHTML = await onLoadTestHTML(selectedActivity.id);

      const { testHTMLCode } = testHTML.result;
      setTestHTML(testHTMLCode);
    };

    if (selectedActivity && selectedActivity.id) {
      loadTestHTML();
    }
  }, [selectedActivity, onLoadTestHTML]);

  return (
    <Card>
      <div className="flex flex-row">
        <div>
          <div>
            <h3 className="card-header">Recent Testing Activity</h3>
          </div>
          <div className="flex flex-row mt-[10px]">
            <div>
              <Card>
                <Calendar
                  recentTestingActivity={recentTestingActivity}
                  selectedActivity={selectedActivity}
                  setSelectedActivity={setSelectedActivity}
                />
              </Card>
            </div>
          </div>
        </div>
        {selectedActivity && (
          <div className="flex flex-row justify-between w-full">
            <div className="mt-1.75 ml-1.75">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="flex flex-row items-center">
                    {selectedActivity.passed ? (
                      <div className="flex bg-green rounded-[4px] mr-[8px] py-[2px] px-[8px]">
                        <p className="font-semibold uppercase text-gray-dark detailed">
                          Pass
                        </p>
                      </div>
                    ) : (
                      <div className="flex bg-red rounded-[4px] mr-[8px] py-[2px] px-[8px]">
                        <p className="font-semibold uppercase text-gray-dark detailed">
                          Fail
                        </p>
                      </div>
                    )}
                    <div className="flex">
                      <h3 className="h4">
                        {getFormattedDate(selectedActivity.date, false)} | Test
                        Details
                      </h3>
                    </div>
                  </div>
                  {selectedActivity.passed ? (
                    <div className="flex flex-row items-center mt-0.75">
                      <div className="flex flex-col items-center">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background: !selectedActivity.hasReported
                              ? "#00ff82"
                              : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color: !selectedActivity.hasReported
                                ? "#ffffff"
                                : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Ignored
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background: selectedActivity.hasReported
                              ? "#00ff82"
                              : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color: selectedActivity.hasReported
                                ? "#ffffff"
                                : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Reported
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center mt-0.75">
                      <div className="flex flex-col items-center">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background: selectedActivity.hasReplied
                              ? "#ef2c1f"
                              : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color: selectedActivity.hasReplied
                                ? "#ffffff"
                                : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Replied
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedActivity.source &&
                              selectedActivity.source.isURLClicked
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedActivity.source &&
                                selectedActivity.source.isURLClicked
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Clicked
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedActivity.source &&
                              selectedActivity.source.isQRScanned
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedActivity.source &&
                                selectedActivity.source.isQRScanned
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            QR Code
                          </p>
                        </div>
                      </div>
                      {/* <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedActivity.source &&
                              selectedActivity.source.hasDownloaded
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedActivity.source &&
                                selectedActivity.source.hasDownloaded
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Download
                          </p>
                        </div>
                      </div> */}
                      <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedActivity.imposterPortal &&
                              selectedActivity.imposterPortal.isKeyDown
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedActivity.imposterPortal &&
                                selectedActivity.imposterPortal.isKeyDown
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Creds
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center ml-[10px]">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedActivity.imposterPortal &&
                              selectedActivity.imposterPortal.isMFAEntered
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-[4px]">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedActivity.imposterPortal &&
                                selectedActivity.imposterPortal.isMFAEntered
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            MFA
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-1.25">
                    <p className="description">
                      On {getFormattedDate(selectedActivity.date)}, a social
                      engineering test{" "}
                      {selectedActivity.impersonator
                        ? `impersonating ${
                            selectedActivity.impersonator
                              .charAt(0)
                              .toUpperCase() +
                            selectedActivity.impersonator.substring(1)
                          }`
                        : ""}{" "}
                      was administered.
                    </p>
                  </div>
                  <div className="mt-1.25">
                    <div>
                      <p className="font-semibold description">
                        Below are highlights from the test:
                      </p>
                    </div>
                    <div>
                      {getHighlights().map((highlight, index) => (
                        <div
                          className="flex flex-row items-center mt-[14px]"
                          key={`highlight-${index}`}
                        >
                          <div className="flex-shrink-0 w-[16px] h-[16px]">
                            {highlight.icon}
                          </div>
                          <div className="flex ml-[6px]">
                            <p className="description">
                              {highlight.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center mt-1.25">
                  <div className="flex">
                    <button
                      className="border border-white rounded-[32px] p-[8px]"
                      title="View the previous testing activity."
                      aria-label="View the previous testing activity."
                      onClick={handleClickLeftArrow}
                    >
                      <LeftArrowIcon />
                    </button>
                  </div>
                  <div className="flex ml-[10px]">
                    <button
                      className="border border-white rounded-[32px] p-[8px]"
                      title="View the next testing activity."
                      aria-label="View the next testing activity."
                      onClick={handleClickRightArrow}
                    >
                      <RightArrowIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-1.75 ml-1.75">
              {testHTML && (
                <iframe
                  className="bg-white rounded-[4px] border-none"
                  title={`${getFormattedDate(selectedActivity.date)} test`}
                  srcdoc={testHTML}
                  height="366px"
                ></iframe>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

export default CardRecentTestingActivity;
