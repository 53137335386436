import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Box,
  Stack,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  AdsClickOutlined,
  AnalyticsOutlined,
  AssignmentOutlined,
  FeedbackOutlined,
  Person,
  StarBorderOutlined,
  VerifiedUserOutlined,
  ViewInArOutlined,
} from '@mui/icons-material';

import { DuneButton, FadeInSection } from '../../../components';

import { createClient } from 'contentful';

import { spacing } from '../../../styles';

import EcosystemDiagram from './utils/ecosystem-diagram/ecosystem-diagram';

import AntlerLogo from './utils/antler-logo/antler-logo';
import NetlifyLogo from './utils/netlify-logo/netlify-logo';
import WarnerMusicGroupLogo from './utils/warner-music-group-logo/warner-music-group-logo';
import SaveTimeLogo from './utils/savetime-logo/savetime-logo';
import SaveMoneyLogo from './utils/savemoney-logo/savemoney-logo';
import ReduceRiskLogo from './utils/reducerisk-logo/reducerisk-logo';

import { Translate } from 'react-auto-translate';

import './home.css';
import GreenItalicText from '../../../components/common/green-italic-text/green-italic-text';

function Home() {
  const customersDesktop = [
    {
      name: 'Netlify',
      logo: <NetlifyLogo />,
    },
    {
      name: 'Craft',
      logo: (
        <img
          className="w-full h-full"
          src="https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png"
          alt="Craft logo"
        />
      ),
    },
    {
      name: 'Warner Music Group',
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: 'Antler',
      logo: <AntlerLogo />,
    },
  ];

  const customersMobile = [
    {
      name: 'Warner Music Group',
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: 'Netlify',
      logo: <NetlifyLogo />,
    },
    {
      name: 'Craft',
      logo: (
        <img
          className="w-full h-full"
          src="https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png"
          alt="Craft logo"
        />
      ),
    },
    {
      name: 'Antler',
      logo: <AntlerLogo />,
    },
  ];

  const threats = [
    {
      title: 'Increased Use of Generative AI for Attacks',
      icon: 'https://d3oo9a669kwmx7.cloudfront.net/icons/connection.gif',
      staticIcon: 'https://d3oo9a669kwmx7.cloudfront.net/icons/connection.png',
      description:
        'Cybercriminals use AI tools like ChatGPT to create highly convincing phishing attacks, increasing both sophistication and scale.',
    },
    {
      title: 'Advanced Persistent Threats and State-Sponsored Attacks',
      icon: 'https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.gif',
      staticIcon:
        'https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.png',
      description:
        'APTs and state-sponsored attacks use complex social engineering techniques to gain and maintain long-term access to targeted systems.',
    },
    {
      title: 'Rise of Business Email Compromise (BEC)',
      icon: 'https://d3oo9a669kwmx7.cloudfront.net/icons/message.gif',
      staticIcon: 'https://d3oo9a669kwmx7.cloudfront.net/icons/message.png',
      description:
        'BEC attacks manipulate employees into transferring funds or disclosing sensitive information by impersonating executives or trusted partners.',
    },
  ];

  const categorySolutions = [
    {
      category: 'Security Awareness Training',
      solutions: [
        {
          title: 'User-Adaptation',
          icon: <Person />,
          description:
            'Training modules adjust based on user behavior and risk level, enhancing learning effectiveness and engagement.',
        },
        {
          title: 'Real Time Feedback',
          icon: <FeedbackOutlined />,
          description:
            'Immediate, personalized interventions reinforce positive behaviors and address potential vulnerabilities, proactively mitigating risks.',
        },
        {
          title: 'Comprehensive Reporting',
          icon: <AssignmentOutlined />,
          description:
            'Comprehensive analytics provide actionable intelligence on user progress, risk reduction trends, and overall security posture improvements, facilitating strategic decision-making and simplified compliance reporting.',
        },
        {
          title: 'Automatic Compliance Training',
          icon: <VerifiedUserOutlined />,
          description:
            'Automate and optimize adherence to industry standards and regulations, ensuring thorough coverage while reducing administrative burden.',
        },
      ],
    },
    {
      category: 'Red Team Social Engineering',
      solutions: [
        {
          title: 'Hyper-Realistic Simulations',
          icon: <ViewInArOutlined />,
          description:
            'Test defenses with sophisticated, real-world attack scenarios customized to your organization, proactively identifying and addressing critical vulnerabilities.',
        },
        {
          title: 'Sophisticated and Specific',
          icon: <StarBorderOutlined />,
          description:
            'Utilize advanced techniques like phishing, smishing, vishing, and deepfakes to evaluate responses and gain strategic insights.',
        },
        {
          title: 'Comprehensive Assessments',
          icon: <AnalyticsOutlined />,
          description:
            'Detailed, actionable reports outline vulnerabilities and remediation strategies, enhancing overall security posture.',
        },
        {
          title: 'Direct Engagement',
          icon: <AdsClickOutlined />,
          description:
            'Work with SOCs and CISOs to customize threat scenarios and enhance security measures, delivering tailored and impactful insights.',
        },
      ],
    },
  ];

  const aiPoints = [
    {
      id: 0,
      title: 'Quantify employee risk',
      description:
        'Comprehensively quantify user risk using a variety of input source data, and aggregate the results at organizational, departmental, manager, and other levels.',
      picture: 'https://d3oo9a669kwmx7.cloudfront.net/ai-quantify.png',
    },
    {
      id: 1,
      title: 'Reduce employee risk',
      description:
        'Quantifiably and automatically reduce risk for users through adaptive, timely training tailored to individual and departmental needs in real-time. Ensure compliance and robust protection by continuously enhancing employee awareness and behavior.',
      picture: 'https://d3oo9a669kwmx7.cloudfront.net/ai-reduce.png',
    },
    {
      id: 2,
      title: 'Adapt security controls around risk',
      description:
        'For the highest-risk users, seamlessly integrate with your IDP and existing tech stack to dynamically apply restrictions, adjust permissions, and enhance security controls, such as email filters.',
      picture: 'https://d3oo9a669kwmx7.cloudfront.net/ai-adapt.png',
    },
  ];

  const benefitsIconClassName = 'home-icon-benefit';
  const benefits = [
    {
      title: 'Save Time',
      icon: <SaveTimeLogo />,
      description:
        'Automate cybersecurity and reduce manual security tasks like campaign scheduling, training assignments, security controls and reporting.',
    },
    {
      title: 'Reduce Risk',
      icon: <ReduceRiskLogo />,
      description:
        'Leverage AI to detect and mitigate risks by analyzing user behavior, offering tailored training and dynamic security to prevent breaches.',
    },
    {
      title: 'Save Money',
      icon: <SaveMoneyLogo className={benefitsIconClassName} />,
      description:
        'Lower security costs with targeted interventions for high-risk users, cutting down on breaches and inefficient training programs.',
    },
  ];

  const cisoAdvisoryCouncilQuotes = [
    {
      cisos: [
        {
          name: 'Jeannine Gaudreau',
          title: 'Technical CISO',
          companyName: 'HealthEdge',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/jeannine-gaudreau.png',
          quote:
            "Finally! A learning platform that's as dynamic as the threat landscape.",
        },
        {
          name: 'Bradley Schaufenbuel',
          title: 'VP, CISO',
          companyName: 'Paychex',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/bradley-schaufenbuel.png',
          quote:
            "I chose to join the Dune Security CISO Advisory Council because of Dune's holistic and context-sensitive approach to improving the security awareness of employees.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Benjamin Corll',
          title: 'CISO Americas',
          companyName: 'Zscaler',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/benjamin-corll.png',
          quote:
            'When it comes to Dune and their product, their vision, their mission, just yes. I am fully on board. They are taking a fresh approach to security awareness, using data, insights, and AI capabilities to provide advanced contextualized testing and training, which will actually solve this problem for enterprises.',
        },
        {
          name: 'Diego Souza',
          title: 'EVP, CISO',
          companyName: 'Prog Holdings',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/diego-souza.png',
          quote:
            "Dune Security isn't just a provider; they're pioneers in safeguarding tomorrow's digital world. With their best-in-class solutions and forward-looking cyber awareness services, they're shaping the future of cybersecurity.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Alicia Lynch',
          title: 'CISO',
          companyName: 'TD SYNNEX',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/alicia-lynch.png',
          quote:
            "Companies often wait until after a breach to invest in preventive products. Dune's powerful AI-driven platform modifies employee behavior by training them to identify and prevent threat actor actions, reducing catastrophic risks.",
        },
        {
          name: 'Roy Foster',
          title: 'CISO',
          companyName: 'University of Chicago',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/leroy-foster.png',
          quote:
            "I joined the Dune Security CISO Advisory Council because Technology alone isn't enough; cybersecurity is a human endeavor. Dune Security empowers your team with the knowledge and tools to defend against phishing threats.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Jim Motes',
          title: 'CISO',
          companyName: 'Ryan',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/jim-motes.png',
          quote:
            'Dune offers a solution perfectly aligned with industry needs, focusing on the biggest threat: employee behavior. Their approach provides CISOs with precise tools to target and train at-risk employees.',
        },
        {
          name: 'Dr. Tyrone Grandison',
          title: 'CTO, Apps, Infra & Security',
          companyName: 'Microsoft',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/dr-tyrone-grandison.png',
          quote:
            'I joined the Dune Security CISO Advisory Council because of the vision of tailored, AI-driven cybersecurity readiness.',
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Marcos Marrero',
          title: 'CTO',
          companyName: 'H.I.G. Capital',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/marcos-marrero.png',
          quote:
            "I joined the CISO Advisory Council because I firmly believe in Dune's mission to revolutionize cybersecurity by accurately measuring and drastically reducing Human Cyber Risk. Dune is setting new standards in the industry.",
        },
        {
          name: 'Murtaza Nisar',
          title: 'VP, CISO',
          companyName: 'Clario',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/murtaza-nisar.png',
          quote:
            "I chose to join the Dune CISO Advisory Council because in the fight against cyber threats, there's an opportunity to convert people from one of the key risks to biggest assets",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Konrad Fellmann',
          title: 'CISO',
          companyName: 'Cubic Corporation',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/konrad-fellmann.png',
          quote:
            "Most breaches occur due to human error. I joined the CISO Advisory Council because Dune's innovative approach is transforming the human side of security, significantly reducing risks and enhancing protection.",
        },
        {
          name: 'Thanh Thai',
          title: 'CISO',
          companyName: 'Constellis',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/thanh-thai.png',
          quote:
            "I joined the Dune Security Advisory Council after seeing their solution. Dune elevates phishing defense with personalized campaigns that effectively fill knowledge gaps. It's like having a white hat hacker on your team!",
        },
      ],
    },
  ];

  const investors = [
    {
      name: 'Craft',
      picture: 'https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png',
    },
    {
      name: 'Alumni Ventures',
      picture:
        'https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png',
    },
    {
      name: 'Antler',
      picture: 'https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png',
    },
    {
      name: 'Firestreak',
      picture:
        'https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png',
    },
    {
      name: 'Sequoia Scout',
      picture:
        'https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png',
    },
  ];

  const [selectedTabSolution, setSelectedTabSolution] = useState(
    categorySolutions[0].solutions[0]
  );
  const [selectedTabSolutionTitle, setSelectedTabSolutionTitle] = useState(
    categorySolutions[0].solutions[0].title
  );
  const [selectedAIPoint, setSelectedAIPoint] = useState(aiPoints[0]);
  const [resources, setResources] = useState([]);
  const [hoveredThreat, setHoveredThreat] = useState(null);

  const productRef = useRef(null);

  const location = useLocation();

  const handleChangeTabSolution = (event, newValue) => {
    setSelectedTabSolutionTitle(newValue);

    const allSolutions = categorySolutions.flatMap(
      (category) => category.solutions
    );
    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === newValue
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }

    // TO DELETE:
    console.log(resources);
  };

  useEffect(() => {
    const aiPointTimeout = setTimeout(() => {
      const currentAIPointID = selectedAIPoint.id;

      if (currentAIPointID === aiPoints.length - 1) {
        setSelectedAIPoint(aiPoints[0]);
      } else {
        const filteredAIPoints = aiPoints.filter(
          (aiPoint) => aiPoint.id === currentAIPointID + 1
        );

        if (filteredAIPoints.length > 0) {
          setSelectedAIPoint(filteredAIPoints[0]);
        }
      }
    }, 5000);

    return () => clearTimeout(aiPointTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiPoints]);

  useEffect(() => {
    if (location.pathname === '/product/') {
      productRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [location]);

  useEffect(() => {
    const fetchResources = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        let fetchedResourcesBootcamp = [];
        let fetchedResourcesTeam = [];

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: 'resourcesBootcamp',
            limit: 3,
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesBootcamp = response.items.map((item) => {
              const { fields, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: 'resourcesTeam',
            limit: 3,
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesTeam = response.items.map((item) => {
              const { fields, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: fields.resourceURL || '',
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
          })
          .catch(console.error);

        const fetchedResourcesMostRecent = [
          ...fetchedResourcesBootcamp,
          ...fetchedResourcesTeam,
        ];

        fetchedResourcesMostRecent.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          if (dateA < dateB) {
            return 1;
          } else if (dateA > dateB) {
            return -1;
          }

          return a.title.localeCompare(b.title);
        });

        setResources(fetchedResourcesMostRecent.slice(0, 3));
      }
    };

    fetchResources();
  }, []);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentage < 90) {
      const interval = setInterval(() => {
        setPercentage((prevPercentage) => prevPercentage + 1);
      }, 15);

      return () => clearInterval(interval);
    }
  }, [percentage]);

  const aiPointsRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrollingThroughAIPoints, setIsScrollingThroughAIPoints] =
    useState(false);
  const [aiPointsInView, setAiPointsInView] = useState(false);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = (event) => {
      event.preventDefault();

      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (event.deltaY > 0) {
          if (currentIndex < aiPoints.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        } else if (event.deltaY < 0) {
          if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        }
      }, 0);
    };

    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    const handleScrollLock = () => {
      if (window.scrollY > 0 && !isScrollingThroughAIPoints) {
        setIsScrollingThroughAIPoints(true);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          setIsScrollingThroughAIPoints(true);
        } else {
          setIsScrollingThroughAIPoints(false);
        }
      },
      { threshold: 1.0 }
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    if (isScrollingThroughAIPoints) {
      window.addEventListener('wheel', handleScroll, { passive: false });
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('scroll', handleScrollLock);
    } else {
      window.removeEventListener('wheel', handleScroll, { passive: false });
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('scroll', handleScrollLock);
    }

    return () => {
      clearTimeout(debounceTimeout);
      window.removeEventListener('wheel', handleScroll, { passive: false });
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('scroll', handleScrollLock);
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, [
    isScrollingThroughAIPoints,
    currentIndex,
    aiPointsInView,
    aiPoints.length,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isScrollingThroughAIPoints, aiPointsInView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAiPointsInView(entry.isIntersecting);
        setIsScrollingThroughAIPoints(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    return () => {
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, []);

  return (
    <>
      <FadeInSection>
        <div
          style={{
            '--image-url': `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png')`,
          }}
          className="flex justify-center px-1.875 mt-1.875 bg-[image:var(--image-url)] bg-center">
          <div className="max-w-78.75 w-full">
            <div className="max-w-full w-full py-6.25 pl-0 md:pl-1.75">
              <div className="text-white px-0.5 text-h1">
                <span>
                  <Translate>Employee risk management, </Translate>
                </span>
                <p>
                  <GreenItalicText text="powered by AI" />
                </p>
              </div>
              <p className="mt-1.5 text-white max-w-11.5 md:max-w-37.5">
                <Translate>
                  Prevent sophisticated social engineering attacks with
                  user-adaptive security awareness training and red team
                  analysis.
                </Translate>
              </p>
              <div className="mt-1.5 max-w-full">
                <Link to="/demo/">
                  <DuneButton variant="dune-glow" text="Learn more" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="py-2.5 px-1.875">
          <div className="flex-wrap items-center justify-between hidden w-full md:flex">
            {customersDesktop.map((customer, index) => (
              <div key={`customer-desktop-${index}`} className="h-3.375 my-1">
                {customer.logo}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center md:hidden">
            {customersMobile.map((customer, index) => (
              <div key={`customer-mobile-${index}`} className="h-3.375 my-1.5">
                {customer.logo}
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className="bg-green-background flex justify-center py-5 xs:mt-5 md:mt-7.5"
          ref={productRef}>
          <div className={`max-w-full md:max-w-78.75 w-full`}>
            <div className="w-[90%] mx-auto text-center">
              <span
                className="text-center text-white text-h2 leading-[1.2]"
                textAlign="center">
                <GreenItalicText text={percentage} />
                <GreenItalicText text="% " />{' '}
                <Translate>of breaches start with human behavior</Translate>
              </span>
            </div>
            <div className="max-w-40.625 w-[90%] mx-auto">
              <p className="text-white mt-1.5 text-center px-1 md:px-0">
                <Translate>
                  Historically the biggest challenge for enterprise security
                  teams, this trend is worsening with new trends that increase
                  the sophistication and scale of attacks on end users.
                </Translate>
              </p>
            </div>
            <div className="flex flex-wrap justify-center md:justify-between items-center mt-3.75 w-full">
              {threats.map((threat, index) => (
                <div
                  className={`max-w-15.75 w-full ${
                    index > 0 ? 'mt-5' : 'mt-0'
                  } md:mt-0`}
                  key={`threat-${index}`}>
                  <div className="flex justify-center h-6.25">
                    <img
                      className="rounded-small bg-white p-1.25 "
                      onMouseEnter={() => setHoveredThreat(index)}
                      onMouseLeave={() => setHoveredThreat(null)}
                      src={
                        hoveredThreat === index
                          ? threat.staticIcon
                          : threat.icon
                      }
                      alt={threat.title}
                    />
                  </div>
                  <div className="mt-1.875">
                    <div className="text-h4 text-center font-medium leading-[1.2] text-white">
                      <Translate>{threat.title}</Translate>
                    </div>
                  </div>
                  <div className="text-body mt-0.625">
                    <p className="text-center text-white">
                      <Translate>{threat.description}</Translate>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 30px">
          <Box
            maxWidth={{
              xs: '100%',
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%">
            <Box>
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center">
                <Translate>
                  Dune Security identifies and resolves risky behavior,{' '}
                </Translate>
                <GreenItalicText text=" automatically" />
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body-gray"
                component="p"
                textAlign="center"
                marginTop="12px">
                <Translate>
                  With a suite of services built to combat the tactics of
                  modern-day social engineers.
                </Translate>
              </Typography>
            </Box>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="center"
              marginTop="60px">
              <Box
                className="home-box-solutions-tabs"
                maxWidth={{
                  xs: '100%',
                  md: '300px',
                }}
                padding="20px">
                <Box display={{ xs: 'none', md: 'block' }}>
                  {categorySolutions.map((categoryItem, categoryItemIndex) => (
                    <>
                      <Box
                        marginTop={categoryItemIndex > 0 ? '30px' : 0}
                        key={`category-item-${categoryItemIndex}`}>
                        <Typography
                          component="span"
                          className="home-text-category-solution-name">
                          <Translate>{categoryItem.category}</Translate>
                        </Typography>
                      </Box>
                      <Box marginTop="10px">
                        <Tabs
                          className="home-tabs"
                          orientation="vertical"
                          value={
                            <Translate>{selectedTabSolution.title}</Translate>
                          }
                          onChange={handleChangeTabSolution}>
                          {categoryItem.solutions.map(
                            (solution, solutionIndex) => (
                              <Tab
                                className="home-tab"
                                icon={solution.icon}
                                iconPosition="start"
                                label={<Translate>{solution.title}</Translate>}
                                value={<Translate>{solution.title}</Translate>}
                                key={`category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                              />
                            )
                          )}
                        </Tabs>
                      </Box>
                    </>
                  ))}
                </Box>
                <Box display={{ xs: 'block', md: 'none' }}>
                  <Select
                    backgroundColor="black"
                    value={selectedTabSolutionTitle}
                    onChange={(event) =>
                      handleChangeTabSolution(event, event.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    renderValue={(selected) => (
                      <Box display="flex" alignItems="center">
                        <ListItemIcon sx={{ color: 'white', minWidth: '40px' }}>
                          {
                            categorySolutions
                              .flatMap((cat) => cat.solutions)
                              .find((sol) => sol.title === selected)?.icon
                          }
                        </ListItemIcon>
                        <ListItemText primary={selected} />
                      </Box>
                    )}
                    sx={{
                      'color': 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}>
                    {categorySolutions
                      .map((categoryItem, categoryItemIndex) => [
                        <ListSubheader
                          key={`subheader-${categoryItemIndex}`}
                          sx={{
                            backgroundColor: 'black',
                            color: '#00FF82',
                            fontSize: '14px',
                            fontWeight: 400,
                            textTransform: 'uppercase',
                            letterSpacing: '0.1em',
                            padding: '16px 16px 8px',
                            lineHeight: '20px',
                          }}>
                          <Translate>{categoryItem.category}</Translate>
                        </ListSubheader>,
                        ...categoryItem.solutions.map(
                          (solution, solutionIndex) => (
                            <MenuItem
                              value={<Translate>{solution.title}</Translate>}
                              className="dune-menu-item"
                              key={`mobile-category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                              sx={{
                                'backgroundColor': 'black',
                                'color': 'white',
                                '&.Mui-selected': {
                                  backgroundColor: '#262525 !important',
                                },
                                '&.Mui-selected:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                              }}>
                              <Box display="flex" alignItems="center">
                                <ListItemIcon
                                  sx={{ color: 'white', minWidth: '40px' }}>
                                  {solution.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Translate>{solution.title}</Translate>
                                  }
                                  primaryTypographyProps={{ variant: 'body1' }}
                                />
                              </Box>
                            </MenuItem>
                          )
                        ),
                      ])
                      .flat()}
                  </Select>
                </Box>
              </Box>
              <Box
                maxWidth="600px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: '60px',
                }}
                marginTop={{
                  xs: '30px',
                  md: 0,
                }}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  height="100%">
                  <Box
                    className="home-box-background-gray"
                    height={{
                      xs: '230px',
                      md: '360px',
                    }}
                    width="100%"
                    sx={{
                      background: 'inherit !important',
                    }}>
                    <Box padding="4px">
                      <Box
                        component="img"
                        src="https://d3oo9a669kwmx7.cloudfront.net/home-dashboard-new.png"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="home-box-background-gray"
                    padding="20px"
                    marginTop="10px">
                    <Box>
                      <Typography variant="h3" component="h3">
                        {<Translate>{selectedTabSolutionTitle}</Translate>}
                      </Typography>
                    </Box>
                    <Box marginTop="10px">
                      <Typography variant="body-gray" component="p">
                        {
                          <Translate>
                            {selectedTabSolution.description}
                          </Translate>
                        }
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <EcosystemDiagram />
      </FadeInSection>
      <FadeInSection>
        <div
          className="min-w-full md:w-78.75 w-full mx-auto flex flex-col md:flex-row-reverse justify-center items-center scroll-smooth mt-7.5"
          ref={aiPointsRef}>
          <div className="w-31.25 md:w-37.5 relative mb-1 md:mb-0 overflow-hidden">
            {aiPoints.map((point, index) => (
              <div
                key={point.id}
                className={`${
                  currentIndex === index ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-500 ease-in-out ${
                  currentIndex === index ? 'relative' : 'absolute'
                } w-full top-0 left-0 mt-3.125`}>
                <img
                  src={point.picture}
                  alt={<Translate>{point.title}</Translate>}
                  style={{ width: '100%', height: 'auto', display: 'block' }}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center md:items-start">
            {aiPoints.map((point, index) => (
              <div
                className={`max-w-31.25 w-[80%] md:w-full mb-1 ${
                  currentIndex === index
                    ? 'border border-0.031 border-[#E9E9E7]'
                    : 'border border-0.031 border-transparent'
                } rounded-small p-1.25 text-white`}
                key={point.id}>
                <div className="text-h4 text-white mb-0.5">
                  {<Translate>{point.title}</Translate>}
                </div>
                <div className="text-white text-h5">
                  {<Translate>{point.description}</Translate>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={`flex justify-center xs:mt-5 md:mt-7.5 px-0 py-1.875`}>
          <div className={`max-w-full w-full mx-auto`}>
            <div>
              <div className="text-center text-white text-h2">
                <GreenItalicText text=" Secure " />
                <span>
                  <Translate>smarter,</Translate>
                </span>{' '}
                <GreenItalicText text=" operate " />
                <Translate> better, </Translate>
                <GreenItalicText text=" empower " />{' '}
                <Translate>your team.</Translate>
              </div>
              <div className="text-center pt-0.625 text-white">
                <Translate>
                  See how AI can personalize testing and training at the
                  organization and user level.
                </Translate>
              </div>
            </div>
            <div className="max-w-78.75 w-full mx-auto">
              <div className="md:flex xs:flex-col justify-center items-center mt-2.25">
                {benefits.map((benefit, index) => (
                  <div
                    className="bg-[#141414] md:flex xs:flex-col mb-1.25 md:mb-0  border border-[#E9E9E7] border-opacity-30 p-1.25 rounded-small my-0 mx-0.25"
                    key={index}>
                    <div className="bg-[#292929] rounded-small w-full mb-1.25 md:mb-0 xs:mr-0 md:mr-1.25 flex justify-center items-center p-0.625">
                      {benefit.icon}
                    </div>
                    <div>
                      <div className="text-white text-h3">
                        {<Translate>{benefit.title}</Translate>}
                      </div>
                      <p className="mt-0.75 text-h5 text-white">
                        {<Translate>{benefit.description}</Translate>}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className={`w-full font-semibold`}>
            <div>
              <p className="text-h5 text-white text-center tracking-[9.6px] uppercase leading-[1.2] font-semibold">
                <Translate>The CISO Advisory Council</Translate>
              </p>
            </div>
            <div className="mt-0.625">
              <div className="text-h2 leading-[1.2] text-white text-center">
                <Translate>Built</Translate> <GreenItalicText text="for " />
                <Translate>enterprise CISOs,</Translate>{' '}
                <GreenItalicText text="by " />
                <Translate>enterprise CISOs.</Translate>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-3.75">
          <div className={`w-full`}>
            <div className="box-border relative flex w-full overflow-x-auto hover:pause no-scrollbar">
              <div className="flex w-fit-content hover:pause animate-scroll">
                {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                  <div
                    className={`w-18.75 ${quotesIndex > 0 ? 'ml-1.25' : ''}`}
                    key={`quotes-item-${quotesIndex}`}>
                    {quotesItem.cisos.map((ciso, cisoIndex) => (
                      <div
                        className={`p-0.625 ${
                          cisoIndex > 0 ? 'mt-1.25' : ''
                        } text-white text-h6 bg-[#141414] border border-gray border-opacity-50 rounded-small`}
                        key={`quotes-item-${quotesIndex}-ciso-${cisoIndex}`}>
                        <div>
                          <p className="text-white text-h5">
                            {<Translate>{ciso.quote}</Translate>}
                          </p>
                        </div>
                        <div className="mt-1.875">
                          <div className="flex flex-row items-center">
                            <div>
                              <img
                                className="h-2.5 w-2.5 rounded-small"
                                src={ciso.profilePicture}
                                alt={ciso.name}
                              />
                            </div>
                            <div className="ml-0.75">
                              <div>
                                <span className="font-semibold text-white text-h6">
                                  {ciso.name}
                                </span>
                              </div>
                              <div>
                                <span className="text-white text-h6">
                                  {ciso.title} | {ciso.companyName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                  <div
                    className="w-18.75 ml-1.25"
                    key={`quotes-item-duplicate-${quotesIndex}`}>
                    {quotesItem.cisos.map((ciso, cisoIndex) => (
                      <div
                        className={`p-0.625 ${
                          cisoIndex > 0 ? 'mt-1.25' : ''
                        } border border-gray border-opacity-50 bg-[#141414] rounded-small`}
                        key={`quotes-item-duplicate-${quotesIndex}-ciso-${cisoIndex}`}>
                        <div>
                          <p className="text-white text-h5">
                            {<Translate>{ciso.quote}</Translate>}
                          </p>
                        </div>
                        <div className="mt-1.875">
                          <div className="flex items-center">
                            <div>
                              <img
                                className="rounded-small h-2.5 w-2.5"
                                src={ciso.profilePicture}
                                alt={ciso.name}
                              />
                            </div>
                            <div className="ml-0.75">
                              <div>
                                <span className="font-semibold leading-normal text-white text-h6">
                                  {ciso.name}
                                </span>
                              </div>
                              <div>
                                <span className="leading-normal text-white text-h6">
                                  {ciso.title} | {ciso.companyName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className={`max-w-full md:max-w-78.75 w-full`}>
            <div className="mt-0.625">
              <div className="text-h2 text-white text-center leading-[1.2]">
                <Translate>Best-in-class technology</Translate>{' '}
                <GreenItalicText text="with " />
                <Translate>best-in-class investors.</Translate>
              </div>
            </div>
            <div className="mt-1.875">
              <div
                className="flex flex-wrap items-center justify-between"
                container>
                {investors.map((investor, index) => (
                  <div
                    className={`
                    flex flex-wrap justify-center
                    ${index > 0 ? 'mt-10' : 'mt-0'} 
                    ${index > 1 ? 'sm:mt-10' : 'sm:mt-0'} 
                    ${index > 2 ? 'md:mt-10' : 'md:mt-0'} 
                    lg:mt-0
                    w-full sm:w-1/2 md:w-1/3 lg:w-auto
                  `}>
                    <img
                      className={`
                        ${index % 2 !== 0 ? 'sm:ml-0' : 'ml-0'}
                        ${index % 3 !== 0 ? 'md:ml-0' : 'ml-0'}
                        ${index > 0 ? 'lg:ml-0' : 'ml-0'}
                        xs:ml-0 h-9 w-auto
                      `}
                      src={investor.picture}
                      alt={investor.name}
                      key={`investor-${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          style={{
            '--image-url': `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png')`,
          }}
          className={`flex justify-center m-auto mt-5 rounded-small border border-gray border-opacity-50 home-box-top max-w-78.75 bg-[image:var(--image-url)] bg-center`}>
          <div className={`max-w-78.75 w-full md:pl-2.5`}>
            <div className="w-full max-w-full md:max-w-40.625 mx-1.25 py-11.25 md:py-5">
              <div className="text-h1 font-medium leading-[1.2] text-white">
                <Translate>
                  Meet the experts behind our cybersecurity solutions
                </Translate>
              </div>
              <p className="text-white text-h5 max-w-[90%] mt-1.5">
                <Translate>
                  Meet the experts behind our success and learn more about our
                  dedicated team.
                </Translate>
              </p>
              <div className="w-1/2 md:w-fit mt-1.5">
                <Link to="/demo/">
                  <DuneButton variant="dune-glow" text="Meet the team" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
    </>
  );
}

export default Home;
