import React from "react";

function Select({ value, onChange, children }) {
  return (
    <>
      <style>
        {`
          .select-wrapper::after {
            content: "▼";
            font-size: 12px;
            top: 9px;
            right: 12px;
            position: absolute;
            color: #ffffff;
          }`}
      </style>
      <div className="relative select-wrapper">
        <select
          className="cursor-pointer rounded-[4px] py-[10px] pl-[12px] pr-[36px] appearance-none border w-full border-border-color text-ellipsis bg-inherit card-header normal-case focus:ring-0 focus:outline-none focus:border-green"
          value={value}
          onChange={onChange}
          style={{ textTransform: "uppercase" }}
        >
          {children}
        </select>
      </div>
    </>
  );
}

export default Select;
