import React from "react";

const CheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00065 1.3335C4.32065 1.3335 1.33398 4.32016 1.33398 8.00016C1.33398 11.6802 4.32065 14.6668 8.00065 14.6668C11.6807 14.6668 14.6673 11.6802 14.6673 8.00016C14.6673 4.32016 11.6807 1.3335 8.00065 1.3335ZM6.19398 10.8602L3.80065 8.46683C3.73893 8.40511 3.68997 8.33183 3.65657 8.25119C3.62316 8.17055 3.60597 8.08412 3.60597 7.99683C3.60597 7.90954 3.62316 7.82311 3.65657 7.74247C3.68997 7.66182 3.73893 7.58855 3.80065 7.52683C3.86237 7.46511 3.93565 7.41615 4.01629 7.38274C4.09693 7.34934 4.18336 7.33215 4.27065 7.33215C4.35794 7.33215 4.44437 7.34934 4.52501 7.38274C4.60566 7.41615 4.67893 7.46511 4.74065 7.52683L6.66732 9.44683L11.254 4.86016C11.3786 4.73551 11.5477 4.66548 11.724 4.66548C11.9003 4.66548 12.0693 4.73551 12.194 4.86016C12.3186 4.98481 12.3887 5.15388 12.3887 5.33016C12.3887 5.50645 12.3186 5.67551 12.194 5.80016L7.13398 10.8602C7.07231 10.922 6.99905 10.971 6.9184 11.0045C6.83775 11.0379 6.7513 11.0551 6.66398 11.0551C6.57667 11.0551 6.49022 11.0379 6.40957 11.0045C6.32892 10.971 6.25566 10.922 6.19398 10.8602Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default CheckmarkIcon;
