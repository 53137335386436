import React from "react";

const ButtonContainedGreen = ({
  children,
  padding = "py-[5px] px-[10px]",
  additionalClassName = "",
  onClick,
}) => {
  return (
    <button
      className={`description-emphasized ${padding} rounded-small bg-green ${additionalClassName}`}
      onClick={onClick ? onClick : null}
    >
      {children}
    </button>
  );
};

export default ButtonContainedGreen;
