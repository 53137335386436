import { getResponseGet } from "../utils";

/**
 * Fetches a list of users.
 * @returns {Object} An object containing the list of departments or an error.
 *   - The 'result' field is an array of user objects. Each user object includes 'name', 'email', 'department', 'title', 'compliant', and 'riskScore' fields.
 *   - The 'error' field contains an error object if the request fails.
 */
const getUsers = async (includeRiskScore = true) => {
  let users = {
    result: [],
    error: {},
  };

  try {
    const response = await getResponseGet(
      includeRiskScore ? "/all_users_table/" : "/check_users_exist/"
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    for (let i = 0; i < data.length; i++) {
      const userData = data[i];

      let complianceFrameworks = [];

      if (userData.compliance_frameworks) {
        const userComplianceFrameworks = Object.keys(
          userData.compliance_frameworks
        ).map((key) => {
          return {
            name: key,
            progress: userData.compliance_frameworks[key],
          };
        });

        const filteredComplianceFrameworks = userComplianceFrameworks.filter(
          (complianceFramework) =>
            typeof complianceFramework.progress === "boolean"
        );

        complianceFrameworks = filteredComplianceFrameworks;
      }

      const user = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        initials: `${
          userData.first_name && userData.first_name.charAt(0).toUpperCase()
        }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
        email: userData.email,
        department: userData.department,
        role: userData.title,
        compliant: userData.compliant,
        complianceFrameworks,
      };

      if (includeRiskScore) {
        if (userData.risk_score) {
          user.riskScore = +userData.risk_score.toFixed();
        } else {
          user.riskScore = 0;
        }
      }

      users.result.push(user);
    }
  } catch (error) {
    users.error = error;
  }

  return users;
};

export default getUsers;
