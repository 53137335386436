import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M11.6261 11H11.0336L10.8236 10.7975C11.2923 10.253 11.6349 9.61156 11.8267 8.91919C12.0186 8.22682 12.0551 7.5006 11.9336 6.79248C11.5811 4.70748 9.84108 3.04248 7.74108 2.78748C7.00279 2.69408 6.25292 2.77081 5.54885 3.0118C4.84478 3.25279 4.20516 3.65165 3.67895 4.17785C3.15274 4.70406 2.75388 5.34368 2.5129 6.04775C2.27191 6.75182 2.19518 7.50169 2.28858 8.23998C2.54358 10.34 4.20858 12.08 6.29358 12.4325C7.00169 12.554 7.72792 12.5175 8.42029 12.3256C9.11266 12.1338 9.75406 11.7912 10.2986 11.3225L10.5011 11.5325V12.125L13.6886 15.3125C13.9961 15.62 14.4986 15.62 14.8061 15.3125C15.1136 15.005 15.1136 14.5025 14.8061 14.195L11.6261 11ZM7.12608 11C5.25858 11 3.75108 9.49248 3.75108 7.62498C3.75108 5.75748 5.25858 4.24998 7.12608 4.24998C8.99358 4.24998 10.5011 5.75748 10.5011 7.62498C10.5011 9.49248 8.99358 11 7.12608 11Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchIcon;
