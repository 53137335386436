import React, { useState } from "react";

import { TooltipIcon } from "./utils";

const Tooltip = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex items-center">
      <div
        className="cursor-pointer"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <TooltipIcon />
      </div>
      {showTooltip && (
        <div
          className="absolute top-full left-1/2 mt-[10px] transform -translate-x-1/2 border border-border-color description py-[4px] px-[8px] z-50 bg-gray-tooltip max-w-[300px] w-max rounded-small"
          style={{
            boxShadow:
              "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
