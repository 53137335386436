import React from 'react';

import { Translate } from 'react-auto-translate';

const DuneButton = ({
  text,
  variant = 'dune-contained',
  onMouseDown,
  className,
}) => {
  const variants = {
    'dune-contained': 'py-0.75 bg-green shadow-sm shadow-green text-black',
    'dune-outlined': 'py-0.75 border border-gray text-white',
    'dune-glow':
      'bg-green shadow-[0px_0px_18px_0px_#028334] text-black py-0.75 hover:bg-green-hover transition ease-in-out duration-300 md:w-fit w-full',
  };

  const baseClasses = 'px-1 rounded-small font-medium';

  return (
    <button
      type="submit"
      className={`${baseClasses} ${variants[variant]} ${className}`}
      onMouseDown={onMouseDown}>
      <Translate>{text}</Translate>
    </button>
  );
};

export default DuneButton;
