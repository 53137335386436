import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Translate } from 'react-auto-translate';

import { ButtonCTA, FadeInSection, Testimonials } from '../../../components';

import { spacing } from '../../../styles';

import { FadedInImage } from './utils';

import './ciso-advisory-council.css';

function CISOAdvisoryCouncil() {
  const cisos = [
    {
      name: 'Harshal Mehta',
      role: 'CISO',
      companyName: 'CWT',
      headshotFileName: 'harshal.jpeg',
    },
    {
      name: 'Jeannine Gaudreau',
      role: 'Technical CISO',
      companyName: 'HealthEdge',
      headshotFileName: 'jeannine.jpeg',
    },
    {
      name: 'Konrad Fellmann',
      role: 'CISO',
      companyName: 'Cubic Corporation',
      headshotFileName: 'konrad.jpeg',
    },
    {
      name: 'Bradley Schaufenbuel',
      role: 'VP, CISO',
      companyName: 'Paychex',
      headshotFileName: 'bradley.png',
    },
    {
      name: 'Benjamin Corll',
      role: 'CISO Americas',
      companyName: 'Zscaler',
      headshotFileName: 'benjamin.jpeg',
    },
    {
      name: 'LeRoy Foster',
      role: 'CISO',
      companyName: 'University of Chicago',
      headshotFileName: 'leroy.jpeg',
    },
    {
      name: 'Diego Souza',
      role: 'EVP, CISO',
      companyName: 'Prog Holdings',
      headshotFileName: 'diego.jpeg',
    },
    {
      name: 'Jim Motes',
      role: 'CISO',
      companyName: 'Ryan',
      headshotFileName: 'jim.jpeg',
    },
    {
      name: 'Alicia Lynch',
      role: 'CISO',
      companyName: 'TD SYNNEX',
      headshotFileName: 'alicia.jpeg',
    },
    {
      name: 'Dr. Tyrone Grandison',
      role: 'CTO, Apps, Infra & Security',
      companyName: 'Microsoft',
      headshotFileName: 'tyrone.jpeg',
    },
    {
      name: 'Murtaza Nisar',
      role: 'VP, CISO',
      companyName: 'Clario',
      headshotFileName: 'murtaza.jpeg',
    },
    {
      name: 'Marcos Marrero',
      role: 'CTO',
      companyName: 'H.I.G. Capital',
      headshotFileName: 'marcos.jpeg',
    },
    {
      name: 'Thanh Thai',
      role: 'CISO',
      companyName: 'Constellis',
      headshotFileName: 'thanh.jpeg',
    },
    {
      name: 'Steve Safranek',
      role: 'CISO',
      companyName: 'West Chester University of Pennsylvania',
      headshotFileName: 'steve.jpeg',
    },
  ];

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="60px"
          padding="0 30px">
          <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Typography
              variant="spaced-header"
              component="h1"
              textAlign="center">
              The CISO Advisory Council
            </Typography>
            <Typography
              variant="h1"
              component="h2"
              textAlign="center"
              marginTop="10px">
              Built{' '}
              <Typography component="span" className="dune-text-italic">
                for
              </Typography>{' '}
              enterprise CISOs,{' '}
              <Typography component="span" className="dune-text-italic">
                by
              </Typography>{' '}
              enterprise CISOs.
            </Typography>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={{ xs: '0 30px', md: '0 15px' }}>
          <Box
            maxWidth={spacing.MAX_WIDTH_WEBSITE}
            width="100%"
            marginTop="60px">
            <Box display="flex" justifyContent="center" width="100%">
              <Grid container justifyContent="center" width="100%">
                {cisos.map((ciso, index) => (
                  <Grid
                    item
                    xs={6}
                    md={2.4}
                    display="flex"
                    marginTop={{
                      xs: index > 1 ? '30px' : 0,
                      md: index > 4 ? '30px' : 0,
                    }}
                    paddingLeft={{
                      xs: index % 2 === 0 ? 0 : `${30 / 2}px`,
                      md: '15px',
                    }}
                    paddingRight={{
                      xs: index % 2 === 0 ? `${30 / 2}px` : 0,
                      md: '15px',
                    }}
                    key={`ciso-data-${index}`}>
                    <Box
                      className="dune-box-background-gray"
                      padding="30px 20px"
                      maxWidth={{
                        xs: '100%',
                        md: '180px',
                      }}
                      width="100%">
                      <FadedInImage
                        alt={ciso.name}
                        src={`https://d3oo9a669kwmx7.cloudfront.net/headshots/${ciso.headshotFileName}`}
                      />
                      <Box marginTop="10px">
                        <Typography
                          variant="body-emphasized"
                          component="p"
                          textAlign="center">
                          {ciso.name}
                        </Typography>
                        <Typography
                          variant="description"
                          component="p"
                          textAlign="center"
                          marginTop="4px">
                          {ciso.role} | {ciso.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="60px"
          padding="0 30px">
          <Testimonials />
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="80px"
          padding="0 30px">
          <Box
            className="ciso-advisory-council-box-call-to-action"
            maxWidth={spacing.MAX_WIDTH_WEBSITE}
            width="100%">
            <Box maxWidth="400px" width="100%" padding="60px 30px">
              <Box>
                <Typography variant="h2" component="h2">
                  Discover the{' '}
                  <Typography component="span" className="dune-text-italic">
                    future
                  </Typography>{' '}
                  of cybersecurity
                </Typography>
              </Box>
              <Box marginTop="20px">
                <Typography variant="p" component="p">
                  <Translate>
                    Meet the experts behind our success and learn more about our
                    dedicated team.
                  </Translate>
                </Typography>
              </Box>
              <Box marginTop="20px">
                <ButtonCTA />
              </Box>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
    </>
  );
}

export default CISOAdvisoryCouncil;
