const colors = {
  green: "#00ff82",
  greenText: "#014d10",
  orange: "#f59a31",
  orangeText: "#693900",
  red: "#ef3c1f",
  redText: "#520b00",
  yellow: "#fee442",
  yellowText: "#493f01",
};

export default colors;
