const capitalizeWords = (str) => {
  return str.replace(/\b\w+/g, (word) => {
    return word === "bec"
      ? "BEC"
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

const formatAreasToImproveDepartment = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  const formattedAreasToImproveDepartment = areasToImprove
    .sort((a, b) => b.count - a.count) // Sort by value (descending).
    .slice(0, 6) // Limit to top 6.
    .map(({ threat }) => ({
      name: capitalizeWords(threat), // Map to the desired format.
    }));

  return formattedAreasToImproveDepartment;
};

export default formatAreasToImproveDepartment;
