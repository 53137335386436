import React, { useEffect, useState } from "react";

import { Card, UsersTable } from "../../..";

import { LegendBoxIcon, TrainingPieChart } from "./utils";

function CardTrainingCompletion({ trainingCompletion, users, email }) {
  const headers = [
    {
      id: "name",
      label: "Name",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "252px",
    },
    {
      id: "email",
      label: "Email",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "208px",
    },
    {
      id: "riskScore",
      label: "Risk score",
      isSortable: true,
      isSearchable: false,
      align: "center",
    },
    {
      id: "riskLevel",
      label: "Risk level",
      isSortable: false,
      isSearchable: false,
      align: "center",
      width: "164px",
    },
  ];

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedTrainingStatus, setSelectedTrainingStatus] = useState({
    name: "Not Started",
  }); // Default is Not Started.

  const formatToPercentage = (number) => {
    return (number * 100).toFixed(2);
  };

  const handleClickCell = (status) => {
    updateSelectedTrainingStatus(status);
  };

  const handleClickLegendBox = (status) => {
    updateSelectedTrainingStatus(status);
  };

  const updateSelectedTrainingStatus = (status) => {
    if (status) {
      setSelectedTrainingStatus({ name: status });
    }
  };

  useEffect(() => {
    if (selectedTrainingStatus && selectedTrainingStatus.name && users) {
      const filteredUsers = users.filter(
        (user) => user.trainingStatus === selectedTrainingStatus.name
      );

      setFilteredUsers(filteredUsers);
    }
  }, [selectedTrainingStatus, users]);

  return (
    <Card>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div>
            <h3 className="card-header">Training Status</h3>
          </div>
          <div className="mt-1.25 w-[300px]">
            <TrainingPieChart
              trainingCompletion={trainingCompletion}
              onClickCell={handleClickCell}
            />
          </div>
          <div className="flex flex-col items-center mt-1.25">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => handleClickLegendBox("Completed")}
            >
              <div className="w-[16px] h-[16px]">
                <LegendBoxIcon color="#00ff82" />
              </div>
              <div className="ml-[4px]">
                <p className="description">
                  <span className="description-emphasized">Completed</span>:{" "}
                  {formatToPercentage(trainingCompletion["Completed"])}%
                </p>
              </div>
            </div>
            <div
              className="flex flex-row items-center cursor-pointer mt-0.625"
              onClick={() => handleClickLegendBox("In Progress")}
            >
              <div className="w-[16px] h-[16px]">
                <LegendBoxIcon color="#00994e" />
              </div>
              <div className="ml-[4px]">
                <p className="description">
                  <span className="description-emphasized">In Progress</span>:{" "}
                  {formatToPercentage(trainingCompletion["In Progress"])}%
                </p>
              </div>
            </div>
            <div
              className="flex flex-row items-center cursor-pointer mt-0.625"
              onClick={() => handleClickLegendBox("Not Started")}
            >
              <div className="w-[16px] h-[16px]">
                <LegendBoxIcon color="#006634" />
              </div>
              <div className="ml-[4px]">
                <p className="description">
                  <span className="description-emphasized">Not Started</span>:{" "}
                  {formatToPercentage(trainingCompletion["Not Started"])}%
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full ml-1.25">
          {users && (
            <UsersTable
              data={filteredUsers}
              headers={headers}
              defaultOrderBy="name"
              rowsPerPage={6}
              customHeader={
                <div className="flex flex-col">
                  <div className="border-b-2 border-green w-fit">
                    <p className="h4">
                      {selectedTrainingStatus && selectedTrainingStatus.name}
                    </p>
                  </div>
                </div>
              }
              fixedTotal={
                email === "david@dune.demo"
                  ? selectedTrainingStatus && selectedTrainingStatus.name
                    ? selectedTrainingStatus.name === "Completed"
                      ? 9060
                      : selectedTrainingStatus.name === "In Progress"
                      ? 1091
                      : selectedTrainingStatus.name === "Not Started"
                      ? 331
                      : null
                    : null
                  : null
              }
            />
          )}
        </div>
      </div>
    </Card>
  );
}

export default CardTrainingCompletion;
