import React, { useEffect, useState } from "react";

import { SankeyChart } from "./utils";

import { Card, UsersTable } from "../../../../common";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "208px",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "center",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "center",
    width: "164px",
  },
  {
    id: "frequency",
    label: "Frequency",
    isSortable: true,
    isSearchable: false,
    align: "center",
  },
];

function CardInteractions({
  interactions,
  usersInteraction,
  selectedDays,
  onClickInteraction,
  email,
}) {
  const [animatedTotalInteractions, setAnimatedTotalInteractions] = useState(0);
  const [selectedInteraction, setSelectedInteraction] = useState("");
  const [selectedInteractionTotal, setSelectedInteractionTotal] = useState(0);

  const interactionsMap = {
    phished: "Links Clicked",
    qr_codes_scanned: "QR Codes Scanned",
    key_down: "Credentials Entered",
    data_entered: "Credentials Submitted",
    mfa_entered: "MFA Entered",
    downloads: "Download Attachments",
    smtp_reply: "Tests Responded To",
    no_action: "Test Ignored",
    watchtower_reported: "Reported to Watchtower",
  };

  useEffect(() => {
    if (interactions.totalInteractions) {
      let start = 0;
      const duration = 1000;
      const increment =
        Math.abs(interactions.totalInteractions) / (duration / 50);

      const interval = setInterval(() => {
        start += increment;

        if (start >= Math.abs(interactions.totalInteractions)) {
          setAnimatedTotalInteractions(interactions.totalInteractions);
          clearInterval(interval);
        } else {
          setAnimatedTotalInteractions(start);
        }
      }, 50);

      return () => clearInterval(interval);
    } else if (
      !interactions.totalInteractions &&
      animatedTotalInteractions !== 0
    ) {
      setAnimatedTotalInteractions(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions.totalInteractions]);

  useEffect(() => {
    if (email === "david@dune.demo" && !selectedInteraction) {
      setSelectedInteraction("phished");
      setSelectedInteractionTotal(2735);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleClickInteraction = async (interaction, total) => {
    setSelectedInteraction(interaction);
    setSelectedInteractionTotal(total);
    await onClickInteraction(interaction, selectedDays);
  };

  // const handleCloseDialog = () => {
  //     setOpenModal(false)
  // };

  return (
    <>
      <Card>
        {interactions &&
          interactions.totalInteractions !== null &&
          interactions.totalInteractions !== undefined && (
            <div>
              <div>
                <p className="h2">
                  {Math.round(
                    Math.abs(animatedTotalInteractions)
                  ).toLocaleString("en-US")}
                </p>
              </div>
              <div className="mt-0.25">
                <p className="card-header">
                  Total Interaction
                  {interactions.totalInteractions !== 1 ? "s" : ""}
                </p>
              </div>
            </div>
          )}
        {interactions &&
          interactions.totalInteractions !== null &&
          interactions.totalInteractions !== undefined &&
          interactions.totalInteractionsResponded !== null &&
          interactions.totalInteractionsResponded !== undefined &&
          interactions.totalInteractionsClicked !== null &&
          interactions.totalInteractionsClicked !== undefined &&
          interactions.totalInteractionsKeyDown !== null &&
          interactions.totalInteractionsKeyDown !== undefined &&
          interactions.totalInteractionsDataEntered !== null &&
          interactions.totalInteractionsDataEntered !== undefined &&
          interactions.totalInteractionsNoEngagement !== null &&
          interactions.totalInteractionsNoEngagement !== undefined &&
          interactions.totalInteractionsReported !== null &&
          interactions.totalInteractionsReported !== undefined && (
            <div className="mt-1.25">
              <SankeyChart
                selectedInteraction={selectedInteraction}
                totalInteractionsResponded={
                  interactions.totalInteractionsResponded
                }
                totalInteractionsClicked={interactions.totalInteractionsClicked}
                totalInteractionsKeyDown={interactions.totalInteractionsKeyDown}
                totalInteractionsDataEntered={
                  interactions.totalInteractionsDataEntered
                }
                totalInteractionsNoEngagement={
                  interactions.totalInteractionsNoEngagement
                }
                totalInteractionsReported={
                  interactions.totalInteractionsReported
                }
                totalInteractionsQRCodesScanned={
                  interactions.totalInteractionsQRCodesScanned
                }
                totalInteractionsMFAEntered={
                  interactions.totalInteractionsMFAEntered
                }
                totalInteractionsDownloadAttachments={
                  interactions.totalInteractionsDownloadAttachments
                }
                onClickInteraction={handleClickInteraction}
              />
            </div>
          )}
        <div className="mt-1.25">
          {usersInteraction && (
            <UsersTable
              data={usersInteraction}
              headers={headers}
              defaultOrder="desc"
              defaultOrderBy="riskScore"
              rowsPerPage={6}
              fixedTotal={
                email === "david@dune.demo" && selectedInteractionTotal
                  ? selectedInteractionTotal
                  : null
              }
              customHeader={
                <div className="flex flex-col">
                  <div>
                    <h4 className="card-header">Employees Who Selected</h4>
                  </div>
                  <div className="border-b-2 border-green w-fit">
                    <p className="h4">
                      {selectedInteraction &&
                        interactionsMap[selectedInteraction]}
                    </p>
                  </div>
                </div>
              }
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default CardInteractions;
