import React, { useEffect, useState } from "react";

import { colors } from "../../../styles";

function RiskGauge({ riskScore = 0, cells = 24 }) {
  const [riskGaugeLevel, setRiskGaugeLevel] = useState(0);

  useEffect(() => {
    if (riskScore && cells) {
      setRiskGaugeLevel(Math.ceil(riskScore / (100 / cells)));
    } else {
      setRiskGaugeLevel(0);
    }
  }, [riskScore, cells]);

  return (
    <div className="flex flex-row justify-between">
      {new Array(cells).fill(null).map((_, index) => {
        return (
          <div
            className="rounded-[2px] ml-[3px] first:ml-0"
            style={{
              background:
                riskGaugeLevel === index + 1
                  ? index >= cells - cells / 4
                    ? "#ef3c1f"
                    : index >= cells - (cells / 4) * 2
                    ? "#f59a31"
                    : index >= cells - (cells / 4) * 3
                    ? "#fee442"
                    : "#02ff82"
                  : riskGaugeLevel < index + 1
                  ? `linear-gradient(0deg, rgba(35, 35, 35, 0.50) 0%, rgba(35, 35, 35, 0.50) 100%), ${
                      index >= cells - cells / 4
                        ? colors.red
                        : index >= cells - (cells / 4) * 2
                        ? colors.orange
                        : index >= cells - (cells / 4) * 3
                        ? colors.yellow
                        : colors.green
                    }`
                  : index >= cells - cells / 4
                  ? colors.red
                  : index >= cells - (cells / 4) * 2
                  ? colors.orange
                  : index >= cells - (cells / 4) * 3
                  ? colors.yellow
                  : colors.green,
              boxShadow:
                riskGaugeLevel === index + 1
                  ? "0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4)"
                  : "1px 1px 3px 0px rgba(0, 0, 0, 0.30)",
              height: "18.182px",
              width: "3px",
            }}
          />
        );
      })}
    </div>
  );
}

export default RiskGauge;
