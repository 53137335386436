import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import { FadeInSection } from '../../../components';

import { InlineWidget } from 'react-calendly';

import './demo.css';
import { Translate } from 'react-auto-translate';

function Demo() {
  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '60px',
            md: '80px',
          }}
          padding="0 28px">
          <Box maxWidth="796px" width="100%">
            <Typography component="h1" className="dune-text-header-uppercase">
              <Translate>Book a demo</Translate>
            </Typography>
            <Typography
              component="h2"
              className="dune-text-header-main"
              marginTop="20px">
              <Translate>
                {' '}
                See Dune in action with a personalized demo
              </Translate>
            </Typography>
            <Typography component="p" marginTop="20px">
              <Translate>Schedule a 30 minute call or email us at</Translate>{' '}
              <Link
                className="demo-link-email"
                href="mailto:sales@dunesecurity.io"
                title="Email sales@dunesecurity.io.">
                sales@dunesecurity.io
              </Link>{' '}
              <Translate>for any questions.</Translate>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          minHeight="840px"
          height="100%"
          marginTop={{
            xs: '68px',
            sm: '2px',
          }}
          padding="0 28px">
          <Box
            maxWidth="1152px"
            width="100%"
            sx={{
              '& iframe': {
                height: '840px',
              },
            }}>
            <InlineWidget
              url="https://calendly.com/kaila-dune/30min"
              pageSettings={{
                backgroundColor: '#000000',
                primaryColor: '#00FF82',
                textColor: '#FFFFFF',
              }}
            />
          </Box>
        </Box>
      </FadeInSection>
    </>
  );
}

export default Demo;
