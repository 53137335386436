import { getResponseGet } from "../utils";

const capitalizeWords = (str) => {
  return str
    .split(" ") // Split the string by spaces into an array of words.
    .map((word) => {
      // Capitalize the first letter of the word and lowercase the rest.
      const capitalizedWord =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

      // Check if the word contains "(bec)" and replace it with "(BEC)".
      return capitalizedWord.replace(/\(bec\)/gi, "(BEC)");
    })
    .join(" "); // Join the array back into a string.
};

const formatAssetName = (assetName) => {
  // Remove the file extension ".html".
  const nameWithoutExtension = assetName.replace(".html", "");

  // Split the string by dashes, capitalize each word, and join them with spaces.
  const formattedAssetName = nameWithoutExtension
    .split("-") // Split by dashes.
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word.
    .join(" "); // Join with spaces.

  return formattedAssetName;
};

const formatGeography = (geography) => {
  if (geography.toLowerCase() === "united states") {
    return "🇺🇸 United States";
  } else if (geography.toLowerCase() === "international") {
    return "🌎 International";
  } else if (geography.toLowerCase() === "new york") {
    return "🗽 New York";
  }

  return capitalizeWords(geography);
};

const getSimulationTrackerOrganization = async (selectedDate) => {
  let simulationTrackerOrganization = {
    result: {
      simulationAssets: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/get-upcoming-assets/?from=${selectedDate}`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (
      data.results &&
      Array.isArray(data.results) &&
      data.results.length > 0
    ) {
      for (let i = 0; i < data.results.length; i++) {
        const asset = data.results[i];

        let recipients = [];

        if (
          asset.users &&
          Array.isArray(asset.users) &&
          asset.users.length > 0
        ) {
          for (let j = 0; j < asset.users.length; j++) {
            const recipient = asset.users[j];

            recipients.push({
              firstName: recipient.first_name || "",
              lastName: recipient.last_name || "",
              initials: `${
                recipient.first_name &&
                recipient.first_name.charAt(0).toUpperCase()
              }${
                recipient.last_name &&
                recipient.last_name.charAt(0).toUpperCase()
              }`,
              email: recipient.email,
              riskScore: recipient.latest_risk_score
                ? +recipient.latest_risk_score.toFixed()
                : 0,
            });
          }

          const sortedRecipients = recipients.sort((a, b) => {
            const firstNameA = a.firstName ? a.firstName.toLowerCase() : "";
            const firstNameB = b.firstName ? b.firstName.toLowerCase() : "";

            if (firstNameA < firstNameB) {
              return -1;
            }

            if (firstNameA > firstNameB) {
              return 1;
            }

            return 0; // If they are equal or both missing.
          });

          simulationTrackerOrganization.result.simulationAssets.push({
            assetName: asset.asset_name
              ? formatAssetName(asset.asset_name)
              : "",
            assetFileName: asset.asset_name || "",
            recipients: sortedRecipients,
            attackDepth: asset.attack_depth
              ? capitalizeWords(asset.attack_depth)
              : "",
            method: asset.method ? capitalizeWords(asset.method) : "",
            geography: asset.geography ? formatGeography(asset.geography) : "",
          });
        }
      }
    }
  } catch (error) {
    simulationTrackerOrganization.error = error;
  }

  return simulationTrackerOrganization;
};

export default getSimulationTrackerOrganization;
