import React from "react";

const PersonFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 12.6306C14.21 12.6306 16 10.8406 16 8.63062C16 6.42062 14.21 4.63062 12 4.63062C9.79 4.63062 8 6.42062 8 8.63062C8 10.8406 9.79 12.6306 12 12.6306ZM12 14.6306C9.33 14.6306 4 15.9706 4 18.6306V19.6306C4 20.1806 4.45 20.6306 5 20.6306H19C19.55 20.6306 20 20.1806 20 19.6306V18.6306C20 15.9706 14.67 14.6306 12 14.6306Z"
        fill="white"
      />
    </svg>
  );
};

export default PersonFilledIcon;
