import React from "react";

import { FirstIcon, LastIcon, NextIcon, PreviousIcon } from "../";

function Pagination({ page, setPage, totalPages }) {
  const generatePageNumbers = () => {
    let pages = [];

    if (totalPages <= 5) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (page < 4) {
        pages = [1, 2, 3, 4];
      } else if (page >= 4 && page < totalPages - 3) {
        pages = [page - 1, page, page + 1, page + 2];
      } else {
        pages = [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      }

      pages.push("...", totalPages);
    }

    return pages;
  };

  const handleClickFirst = () => {
    setPage(0);
  };

  const handleClickLast = () => {
    setPage(totalPages - 1);
  };

  const handleClickNext = () => {
    setPage((prevPage) =>
      prevPage < totalPages - 1 ? prevPage + 1 : prevPage
    );
  };

  const handleClickPrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  return (
    <div className="flex flex-row items-center">
      <div className="flex">
        <button className="py-[4px] px-[14px]" onClick={handleClickFirst}>
          <FirstIcon />
        </button>
      </div>
      <div className="flex ml-[2px]">
        <button className="py-[4px] px-[14px]" onClick={handleClickPrevious}>
          <PreviousIcon />
        </button>
      </div>
      {generatePageNumbers().map((num, index) => (
        <div className="flex" key={`page-number-${index}`}>
          {num === "..." ? (
            <span className="description py-[4px] px-[8px]">...</span>
          ) : (
            <button
              onClick={() => setPage(num - 1)}
              className={`description rounded-[4px] py-[4px] px-[8px] w-[48px] ${
                page === num - 1 ? "bg-green text-black font-bold" : ""
              }`}
            >
              {num.toLocaleString("en-US")}
            </button>
          )}
        </div>
      ))}
      <div className="flex ml-[2px]">
        <button className="py-[4px] px-[14px]" onClick={handleClickNext}>
          <NextIcon />
        </button>
      </div>
      <div className="flex ml-[2px]">
        <button className="py-[4px] px-[14px]" onClick={handleClickLast}>
          <LastIcon />
        </button>
      </div>
    </div>
  );
}

export default Pagination;
