import React from "react";

const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.3834 7.45938H5.81831L9.29318 3.98452L8.41047 3.10803L3.4375 8.08101L8.41047 13.054L9.28696 12.1775L5.81831 8.70263H13.3834V7.45938Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftArrowIcon;
