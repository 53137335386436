import React from "react";

import { RiskScoreLineChart } from "./utils";

import { Card } from "../../../../common";

function CardRiskScoreOverTime({ riskScoreOverTime, color }) {
  return (
    <Card>
      <div>
        <h3 className="card-header">Risk Score Over Time</h3>
      </div>
      <div className="mt-1.75">
        <RiskScoreLineChart
          riskScoreOverTime={riskScoreOverTime}
          color={color}
        />
      </div>
    </Card>
  );
}

export default CardRiskScoreOverTime;
