import React, { useEffect, useState } from "react";

const Calendar = ({
  recentTestingActivity,
  selectedActivity,
  setSelectedActivity,
}) => {
  const defaultCurrentYear = new Date().getFullYear();
  const defaultCurrentMonth = new Date().getMonth();

  const [currentYear, setCurrentYear] = useState(defaultCurrentYear);
  const [currentMonth, setCurrentMonth] = useState(defaultCurrentMonth);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const handleClickCalendarDay = (activity) => {
    setSelectedActivity(activity);
  };

  const handleClickNext = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleClickPrevious = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfWeek = firstDayOfMonth.getDay();

    const calendarDays = [];

    // Add empty slots for days before the first day of the month.
    for (let i = 0; i < firstDayOfWeek; i++) {
      calendarDays.push(<div key={`empty-${i}`} />);
    }

    // Add the days of the month.
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedCurrentDate = `${currentYear}/${String(
        currentMonth + 1
      ).padStart(2, "0")}/${String(day).padStart(2, "0")}`; // Format: YYYY/MM/DD

      // Check if this date has an activity.
      let activity = null;

      if (recentTestingActivity) {
        activity = recentTestingActivity.find((activity) => {
          const activityDate = new Date(activity.date);

          // Format the activity date to YYYY/MM/DD for comparison.
          const formattedActivityDate = `${activityDate.getFullYear()}/${String(
            activityDate.getMonth() + 1
          ).padStart(2, "0")}/${String(activityDate.getDate()).padStart(
            2,
            "0"
          )}`;

          return formattedActivityDate === formattedCurrentDate;
        });
      }

      let calendarDay = <></>;

      if (activity && selectedActivity) {
        calendarDay = (
          <div
            className={`description-emphasized text-center p-[10px] ${
              activity ? "cursor-pointer font-bold" : ""
            }`}
            style={{
              background:
                selectedActivity.date === activity.date
                  ? activity.passed
                    ? "#00ff82"
                    : "#ef3c1f"
                  : activity.passed
                  ? "rgba(0, 255, 130, 0.20)"
                  : "rgba(239, 60, 31, 0.20)",
              color:
                selectedActivity.date === activity.date
                  ? activity.passed
                    ? "#0c0c0b"
                    : "#0c0c0b"
                  : activity.passed
                  ? "#00974d"
                  : "#ef3c1f",
            }}
            onClick={() => handleClickCalendarDay(activity)}
            key={`calendar-day-${day}`}
          >
            {day}
          </div>
        );
      } else {
        calendarDay = (
          <div
            className="description-emphasized text-center p-[10px]"
            style={{
              color: "#737272",
            }}
            key={`calendar-day-${day}`}
          >
            {day}
          </div>
        );
      }

      calendarDays.push(calendarDay);
    }

    return calendarDays;
  };

  useEffect(() => {
    // Set the current month and current year to the recent testing activity that is selected.
    if (selectedActivity) {
      const selectedDate = selectedActivity.date;

      if (selectedDate) {
        const dateObject = new Date(selectedDate);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth();

        setCurrentYear(year);
        setCurrentMonth(month);
      }
    }
  }, [selectedActivity]);

  return (
    <div className="flex min-h-[326px] w-[310.23px]">
      <div className="">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="font-semibold description">
              {monthNames[currentMonth]} {currentYear}
            </h4>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex">
              <button onClick={handleClickPrevious} className="detailed">
                Previous
              </button>
            </div>
            <div className="flex ml-[10px]">
              <button onClick={handleClickNext} className="detailed">
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-[4px] mt-[10px]">
          {daysOfWeek.map((day, index) => (
            <div
              key={`days-of-week-${index}`}
              className="description-emphasized text-center p-[10px]"
            >
              {day}
            </div>
          ))}
          {generateCalendar()}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
