import React from "react";
import { useNavigate } from "react-router-dom";

import { ButtonOutlinedGray, UsersTable } from "../../..";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "",
  },
  {
    id: "email",
    label: "Email Address",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "150px",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "120px",
  },
];

function CardRiskEmployees({ users, enableDescendingOrder }) {
  const navigate = useNavigate();

  const handleClickViewMore = () => {
    navigate("/insights/search-employee/");
  };

  return (
    <div>
      {users && (
        <UsersTable
          data={users}
          headers={headers}
          defaultOrder={enableDescendingOrder ? "desc" : "asc"}
          defaultOrderBy="riskScore"
          disablePagination
          disableSearchBar
          customFooter={
            <div className="mt-1.25">
              <ButtonOutlinedGray onClick={handleClickViewMore}>
                View more
              </ButtonOutlinedGray>
            </div>
          }
        />
      )}
    </div>
  );
}

export default CardRiskEmployees;
