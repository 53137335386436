import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";

import { AuthProvider } from "@descope/react-sdk";

// Website
import HeadsUp from "./pages/other/heads-up/heads-up";

// Login
import Login from "./pages/login/login";

import { PlatformContainer, WebsiteContainer } from "./components";

import {
  // Website
  Home,
  // Product,
  Resources,
  ResourcesViewAll,
  ResourceBootcamp,
  ResourceWebinarRecapBlog,
  Company,
  CISOAdvisoryCouncil,
  ContactUs,
  SecurityAndCompliance,
  Demo,
  PrivacyPolicy,
  FulfillmentPolicy,
  Error,

  // Platform
  InsightsOrganization,
  InsightsDepartments,
  InsightsEmployees,
  SearchEmployee,
  InsightsEndUser,
  Trainings,
  TrainingsVideoPlayer,
  Compliance,
  Employees,
  Reporting,
  Configurations,
  Campaigns,
  Welcome,
  ComplianceOnboarding,
  SSOSettings,
  InsightsYourReports,
  TestFrequency,
  DuneGuardian,
  Onboarding,
} from "./pages";

import { theme } from "./styles";

import "./App.css";
import { DonutFilledIcon } from "./images";

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}
        baseUrl="https://auth.dunesecurity.io"
      >
        <BrowserRouter basename="/">
          <Routes>
            {/* Website */}
            <Route
              path="/"
              element={
                <WebsiteContainer
                  mainPage={<Home {...props} />}
                  title="Dune Security | AI-Powered Employee Risk Management"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/product/"
              element={
                <WebsiteContainer
                  mainPage={<Home {...props} />}
                  title="Dune Security | AI-Powered Employee Risk Management"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/resources/"
              element={
                <WebsiteContainer
                  mainPage={<Resources {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/bootcamp/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/bootcamp/:resourceID"
              element={
                <WebsiteContainer
                  mainPage={<ResourceBootcamp {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/team/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/webinar-recap-blog/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/webinar-recap-blog/:resourceID"
              element={
                <WebsiteContainer
                  mainPage={<ResourceWebinarRecapBlog {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/company/"
              element={
                <WebsiteContainer
                  mainPage={<Company {...props} />}
                  title="Our Team: Leading Cybersecurity Innovators | Dune Security"
                  description="Discover how Dune Security's team of industry veterans is revolutionizing cybersecurity training to mitigate human error and combat social engineering threats."
                />
              }
            />
            <Route
              path="/company/ciso-advisory-council/"
              element={
                <WebsiteContainer
                  mainPage={<CISOAdvisoryCouncil {...props} />}
                  title="CISO Advisory Council: Pioneering AI-Driven Cybersecurity Defense | Dune Security"
                  description="Dune Security is led by the collective expertise of leading CISOs spanning industries, crafting unparalleled defenses against AI-powered social engineering."
                />
              }
            />
            <Route
              path="/company/contact-us/"
              element={
                <WebsiteContainer
                  mainPage={<ContactUs {...props} />}
                  title="Contact Dune Security for AI-Powered Cybersecurity Defense Solutions | Dune Security"
                  description="Inquire about Dune Security's sophisticated, AI-enhanced defenses against social engineering. Elevate your security posture with our expert solutions."
                />
              }
            />
            <Route
              path="/company/security-and-compliance/"
              element={
                <WebsiteContainer
                  mainPage={<SecurityAndCompliance {...props} />}
                  title="Ensuring Top-Tier Security & Compliance Standards | Dune Security"
                  description="Explore how Dune Security upholds rigorous security and compliance, including Soc 2 Type II, through our secure AWS-hosted infrastructure and stringent testing."
                />
              }
            />
            <Route
              path="/demo/"
              element={
                <WebsiteContainer
                  mainPage={<Demo {...props} />}
                  title="AI Cybersecurity Solutions | Dune Security"
                  description="Book a demo to see Dune Security's AI-driven cybersecurity solutions in action. Tailored to prevent phishing, smishing, and more, our platform offers advanced defense against social engineering."
                />
              }
            />
            <Route
              path="/privacy-policy/"
              element={
                <WebsiteContainer
                  mainPage={<PrivacyPolicy {...props} />}
                  title="Privacy Policy | Dune Security"
                  description="Dune Security identifies your highest-risk employees at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />
            <Route
              path="/fulfillment-policy/"
              element={
                <WebsiteContainer
                  mainPage={<FulfillmentPolicy {...props} />}
                  title="Fulfillment Policy | Dune Security"
                  description="Dune Security identifies your highest-risk employees at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />

            {/* Login */}
            <Route path="/login/" element={<Login {...props} />} />
            {/* Login */}
            <Route path="/login/" element={<Login {...props} />} />

            {/* Platform */}
            <Route
              path="/insights/organization/"
              element={
                <PlatformContainer
                  mainPage={<InsightsOrganization {...props} />}
                  title="Organization"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                  badgeTitle="Organization"
                  badgeIcon={<DonutFilledIcon />}
                />
              }
            />
            <Route
              path="/insights/departments/"
              element={
                <PlatformContainer
                  mainPage={<InsightsDepartments {...props} />}
                  title="Departments"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/search-employee/"
              x
              element={
                <PlatformContainer
                  mainPage={<SearchEmployee {...props} />}
                  title="Employees"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/employees/"
              element={
                <PlatformContainer
                  mainPage={<InsightsEmployees {...props} />}
                  title="User"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/insights/"
              element={
                <PlatformContainer
                  mainPage={<InsightsEndUser {...props} />}
                  title="Insights"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/"
              element={
                <PlatformContainer
                  mainPage={<Trainings {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/learning/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/compliance/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/completed/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/compliance/"
              element={
                <PlatformContainer
                  mainPage={<Compliance {...props} />}
                  title="Compliance"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/employees/"
              element={
                <PlatformContainer
                  mainPage={<Employees {...props} />}
                  title="Employees"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/reporting/"
              element={
                <PlatformContainer
                  mainPage={<Reporting {...props} />}
                  title="Reporting"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configurations/"
              element={
                <PlatformContainer
                  mainPage={<Configurations {...props} />}
                  title="Configurations"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configurations/test-frequency/"
              element={
                <PlatformContainer
                  mainPage={<TestFrequency {...props} />}
                  title="Configurations"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/campaigns/"
              element={
                <PlatformContainer
                  mainPage={<Campaigns {...props} />}
                  title="Campaigns"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/welcome/"
              element={
                <PlatformContainer
                  mainPage={<Welcome {...props} />}
                  title="Welcome to Dune Security"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/sso-settings/"
              element={
                <PlatformContainer
                  mainPage={<SSOSettings {...props} />}
                  title="SSO Settings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/compliance-onboarding/"
              element={
                <PlatformContainer
                  mainPage={<ComplianceOnboarding {...props} />}
                  title="Compliance"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/your-reports/"
              element={
                <PlatformContainer
                  mainPage={<InsightsYourReports {...props} />}
                  title="Your Reports"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/settings/dune-nudge/"
              element={
                <PlatformContainer
                  mainPage={<DuneGuardian {...props} />}
                  title="Dune Guardian"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/settings/dune-nudge/:status"
              element={
                <PlatformContainer
                  mainPage={<DuneGuardian {...props} />}
                  title="Dune Guardian"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/onboarding/"
              element={
                <PlatformContainer
                  mainPage={<Onboarding {...props} />}
                  title="Onboarding"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />

            {/* Other */}
            <Route
              path="/heads-up/1password/"
              element={
                <HeadsUp
                  imageURL="https://d3oo9a669kwmx7.cloudfront.net/email-screenshot-1password.png"
                  {...props}
                />
              }
            />
            <Route
              path="/heads-up/draft-kings/"
              element={
                <HeadsUp
                  imageURL="https://d3oo9a669kwmx7.cloudfront.net/email-screenshot-draft-kings.png"
                  {...props}
                />
              }
            />

            {/* Error */}
            <Route
              path="/error/"
              element={
                <WebsiteContainer
                  mainPage={<Error {...props} />}
                  title="Page not found | Dune Security"
                  description="Dune Security identifies your highest-risk employees at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />
            <Route
              path="*"
              element={
                <WebsiteContainer
                  mainPage={<Error {...props} />}
                  title="Page not found | Dune Security"
                  description="Dune Security identifies your highest-risk employees at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
