import React from "react";

import { Card } from "../../..";

function CardAreasToImprove({ areasToImprove, color }) {
  return (
    <Card>
      <div>
        <h3 className="text-center card-header">Areas to Improve</h3>
      </div>
      <div className="mt-1.75">
        {areasToImprove && (
          <>
            {Array.isArray(areasToImprove) && areasToImprove.length > 0 ? (
              <ol className="list-decimal list-inside">
                {areasToImprove.map((areaToImprove, index) => (
                  <li
                    className="description first:mt-0 mt-1.75"
                    key={`area-to-improve-${index}`}
                  >
                    {areaToImprove.name}
                  </li>
                ))}
              </ol>
            ) : (
              <p className="description">No areas to improve</p>
            )}
          </>
        )}
      </div>
    </Card>
  );
}

export default CardAreasToImprove;
