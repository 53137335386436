import { getResponseGet } from "../utils";

const getTrainingCompletion = async (department) => {
  let trainingCompletionStatuses = {
    result: {
      trainingCompletion: {
        Completed: 0,
        "In Progress": 0,
        "Not Started": 0,
      },
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/get-training-completion/${
        department ? `?department=${department}` : ""
      }`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.results && data.results.percentages) {
      const trainingCompletion = {
        Completed: data.results.percentages.completed
          ? data.results.percentages.completed / 100
          : 0,
        "In Progress": data.results.percentages.in_progress
          ? data.results.percentages.in_progress / 100
          : 0,
        "Not Started": data.results.percentages.not_started
          ? data.results.percentages.not_started / 100
          : 0,
      };

      trainingCompletionStatuses.result.trainingCompletion = trainingCompletion;
    }

    if (data && data.results && data.results.data) {
      const users = [];

      for (let i = 0; i < data.results.data.length; i++) {
        const userData = data.results.data[i];

        users.push({
          firstName: userData.first_name || "",
          lastName: userData.last_name || "",
          initials: `${
            userData.first_name && userData.first_name.charAt(0).toUpperCase()
          }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
          email: userData.user_email || "",
          department: userData.department || "",
          trainingStatus: userData.training_status
            ? userData.training_status === "completed"
              ? "Completed"
              : userData.training_status === "in_progress"
              ? "In Progress"
              : userData.training_status === "not_started"
              ? "Not Started"
              : ""
            : "",
          riskScore: userData.latest_risk_score
            ? +userData.latest_risk_score.toFixed()
            : 0,
        });
      }

      trainingCompletionStatuses.result.users = users;
    }
  } catch (error) {
    trainingCompletionStatuses.error = error;
  }

  return trainingCompletionStatuses;
};

export default getTrainingCompletion;
