import React from "react";

const DonutOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 2.63062C6.5 2.63062 2 7.13062 2 12.6306C2 18.1306 6.5 22.6306 12 22.6306C17.5 22.6306 22 18.1306 22 12.6306C22 7.13062 17.5 2.63062 12 2.63062ZM13 4.70062C16.61 5.15062 19.48 8.03062 19.93 11.6306H13V4.70062ZM4 12.6306C4 8.57062 7.07 5.19062 11 4.70062V20.5706C7.07 20.0706 4 16.6906 4 12.6306ZM13 20.5606V13.6306H19.93C19.7065 15.3905 18.9045 17.0262 17.65 18.2807C16.3956 19.5351 14.7599 20.3371 13 20.5606Z"
        fill="white"
      />
    </svg>
  );
};

export default DonutOutlinedIcon;
