import React from "react";

const AssessmentFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M16 0.630615H2C0.9 0.630615 0 1.53062 0 2.63062V16.6306C0 17.7306 0.9 18.6306 2 18.6306H16C17.1 18.6306 18 17.7306 18 16.6306V2.63062C18 1.53062 17.1 0.630615 16 0.630615ZM5 14.6306C4.45 14.6306 4 14.1806 4 13.6306V8.63062C4 8.08062 4.45 7.63062 5 7.63062C5.55 7.63062 6 8.08062 6 8.63062V13.6306C6 14.1806 5.55 14.6306 5 14.6306ZM9 14.6306C8.45 14.6306 8 14.1806 8 13.6306V5.63062C8 5.08062 8.45 4.63062 9 4.63062C9.55 4.63062 10 5.08062 10 5.63062V13.6306C10 14.1806 9.55 14.6306 9 14.6306ZM13 14.6306C12.45 14.6306 12 14.1806 12 13.6306V11.6306C12 11.0806 12.45 10.6306 13 10.6306C13.55 10.6306 14 11.0806 14 11.6306V13.6306C14 14.1806 13.55 14.6306 13 14.6306Z"
        fill="white"
      />
    </svg>
  );
};

export default AssessmentFilledIcon;
