import React, { useState } from 'react';
import { DropdownArrowIcon } from '../../images';

const languages = [
  { value: 'es', label: 'Spanish', flag: '🇪🇸' },
  { value: 'pt-BR', label: 'Brazilian Portuguese', flag: '🇧🇷' },
  { value: 'zh-CN', label: 'Chinese', flag: '🇨🇳' },
  { value: 'cs', label: 'Czech', flag: '🇨🇿' },
  { value: 'nl', label: 'Dutch', flag: '🇳🇱' },
  { value: 'en', label: 'English', flag: '🇺🇸' },
  { value: 'fr', label: 'French', flag: '🇫🇷' },
  { value: 'de', label: 'German', flag: '🇩🇪' },
  { value: 'iw', label: 'Hebrew', flag: '🇮🇱' },
  { value: 'hu', label: 'Hungarian', flag: '🇭🇺' },
  { value: 'it', label: 'Italian', flag: '🇮🇹' },
  { value: 'pl', label: 'Polish', flag: '🇵🇱' },
  { value: 'pt-PT', label: 'Portugues', flag: '🇵🇹' },
];

const LanguageSelector = ({ onChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (value) => {
    setSelectedLanguage(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
    onChange(value);
  };

  const filteredLanguages = languages.filter((lang) =>
    lang.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}>
      {/* Custom dropdown trigger with only the flag */}
      <div
        className="flex text-3xl"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          cursor: 'pointer',
        }}>
        {languages.find((lang) => lang.value === selectedLanguage)?.flag}{' '}
        <div className="px-0.25 text-white">
          <DropdownArrowIcon />
        </div>
      </div>

      {/* Dropdown content */}
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'black',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1000,
            width: '300px',
            maxHeight: '360px',
            overflowY: 'auto',
            right: 0,
          }}>
          <div className="py-0.5">Choose your language</div>
          {/* Search input */}
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Type in a country"
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '5px',
              border: '1px solid gray',
              borderRadius: '3px',
              marginBottom: '10px',
              width: '100%',
            }}
          />

          {/* Language options displayed in 2xN grid */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr', // 2 columns for 2xN grid
              gap: '10px',
            }}>
            {filteredLanguages.map((lang) => (
              <div
                className="px-0.5"
                key={lang.value}
                onClick={() => handleChange(lang.value)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <span style={{ marginRight: '10px' }}>{lang.flag}</span>
                <span>{lang.label}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
