import React from "react";

const LeaderboardFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M6 21.6306H3C2.73478 21.6306 2.48043 21.5253 2.29289 21.3377C2.10536 21.1502 2 20.8958 2 20.6306V12.6306C2 12.3654 2.10536 12.111 2.29289 11.9235C2.48043 11.736 2.73478 11.6306 3 11.6306H6C6.26522 11.6306 6.51957 11.736 6.70711 11.9235C6.89464 12.111 7 12.3654 7 12.6306V20.6306C7 20.8958 6.89464 21.1502 6.70711 21.3377C6.51957 21.5253 6.26522 21.6306 6 21.6306ZM13 21.6306H10C9.73478 21.6306 9.48043 21.5253 9.29289 21.3377C9.10536 21.1502 9 20.8958 9 20.6306V3.63062C9 3.3654 9.10536 3.11104 9.29289 2.92351C9.48043 2.73597 9.73478 2.63062 10 2.63062H13C13.2652 2.63062 13.5196 2.73597 13.7071 2.92351C13.8946 3.11104 14 3.3654 14 3.63062V20.6306C14 20.8958 13.8946 21.1502 13.7071 21.3377C13.5196 21.5253 13.2652 21.6306 13 21.6306ZM20 21.6306H17C16.7348 21.6306 16.4804 21.5253 16.2929 21.3377C16.1054 21.1502 16 20.8958 16 20.6306V9.63062C16 9.3654 16.1054 9.11104 16.2929 8.92351C16.4804 8.73597 16.7348 8.63062 17 8.63062H20C20.2652 8.63062 20.5196 8.73597 20.7071 8.92351C20.8946 9.11104 21 9.3654 21 9.63062V20.6306C21 20.8958 20.8946 21.1502 20.7071 21.3377C20.5196 21.5253 20.2652 21.6306 20 21.6306Z"
        fill="white"
      />
    </svg>
  );
};

export default LeaderboardFilledIcon;
