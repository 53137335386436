import React, { useRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import './testimonials.css';
import { Translate } from 'react-auto-translate';

function Testimonials() {
  const cisoAdvisoryCouncilQuotes = [
    {
      cisos: [
        {
          name: 'Jeannine Gaudreau',
          title: 'Technical CISO',
          companyName: 'HealthEdge',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/jeannine.jpeg',
          quote:
            "Finally! A learning platform that's as dynamic as the threat landscape.",
        },
        {
          name: 'Bradley Schaufenbuel',
          title: 'VP, CISO',
          companyName: 'Paychex',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/bradley.png',
          quote:
            "I chose to join the Dune Security CISO Advisory Council because of Dune's holistic and context-sensitive approach to improving the security awareness of employees.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Benjamin Corll',
          title: 'CISO Americas',
          companyName: 'Zscaler',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/benjamin.jpeg',
          quote:
            'When it comes to Dune and their product, their vision, their mission, just yes. I am fully on board. They are taking a fresh approach to security awareness, using data, insights, and AI capabilities to provide advanced contextualized testing and training, which will actually solve this problem for enterprises.',
        },
        {
          name: 'Diego Souza',
          title: 'EVP, CISO',
          companyName: 'Prog Holdings',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/diego.jpeg',
          quote:
            "Dune Security isn't just a provider; they're pioneers in safeguarding tomorrow's digital world. With their best-in-class solutions and forward-looking cyber awareness services, they're shaping the future of cybersecurity.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Alicia Lynch',
          title: 'CISO',
          companyName: 'TD SYNNEX',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/alicia.jpeg',
          quote:
            "Companies often wait until after a breach to invest in preventive products. Dune's powerful AI-driven platform modifies employee behavior by training them to identify and prevent threat actor actions, reducing catastrophic risks.",
        },
        {
          name: 'Roy Foster',
          title: 'CISO',
          companyName: 'University of Chicago',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/leroy.jpeg',
          quote:
            "I joined the Dune Security CISO Advisory Council because Technology alone isn't enough; cybersecurity is a human endeavor. Dune Security empowers your team with the knowledge and tools to defend against phishing threats.",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Jim Motes',
          title: 'CISO',
          companyName: 'Ryan',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/jim.jpeg',
          quote:
            'Dune offers a solution perfectly aligned with industry needs, focusing on the biggest threat: employee behavior. Their approach provides CISOs with precise tools to target and train at-risk employees.',
        },
        {
          name: 'Dr. Tyrone Grandison',
          title: 'CTO, Apps, Infra & Security',
          companyName: 'Microsoft',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/tyrone.jpeg',
          quote:
            'I joined the Dune Security CISO Advisory Council because of the vision of tailored, AI-driven cybersecurity readiness.',
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Marcos Marrero',
          title: 'CTO',
          companyName: 'H.I.G. Capital',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/marcos.jpeg',
          quote:
            "I joined the CISO Advisory Council because I firmly believe in Dune's mission to revolutionize cybersecurity by accurately measuring and drastically reducing Human Cyber Risk. Dune is setting new standards in the industry.",
        },
        {
          name: 'Murtaza Nisar',
          title: 'VP, CISO',
          companyName: 'Clario',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/murtaza.jpeg',
          quote:
            "I chose to join the Dune CISO Advisory Council because in the fight against cyber threats, there's an opportunity to convert people from one of the key risks to biggest assets",
        },
      ],
    },
    {
      cisos: [
        {
          name: 'Konrad Fellmann',
          title: 'CISO',
          companyName: 'Cubic Corporation',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/konrad.jpeg',
          quote:
            "Most breaches occur due to human error. I joined the CISO Advisory Council because Dune's innovative approach is transforming the human side of security, significantly reducing risks and enhancing protection.",
        },
        {
          name: 'Thanh Thai',
          title: 'CISO',
          companyName: 'Constellis',
          profilePicture:
            'https://d3oo9a669kwmx7.cloudfront.net/headshots/thanh.jpeg',
          quote:
            "I joined the Dune Security Advisory Council after seeing their solution. Dune elevates phishing defense with personalized campaigns that effectively fill knowledge gaps. It's like having a white hat hacker on your team!",
        },
      ],
    },
  ];

  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startPos = 0;
  let scrollLeft = 0;

  const handleMouseDown = (e) => {
    isDragging = true;
    startPos = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging = false;
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startPos) * 2; // Scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center">
        <Box
          maxWidth={{
            xs: '100%',
            md: '1600px',
          }}
          width="100%">
          <Box
            ref={scrollContainerRef}
            className="home-box-ciso-scroll"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}>
            <Stack
              direction="row"
              className="home-box-ciso-content"
              width="fit-content">
              {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                <Box
                  width="300px"
                  marginLeft={quotesIndex > 0 ? '20px' : 0}
                  key={`quotes-item-${quotesIndex}`}>
                  {quotesItem.cisos.map((ciso, cisoIndex) => (
                    <Box
                      className="home-box-background-gray"
                      marginTop={cisoIndex > 0 ? '20px' : 0}
                      padding="10px"
                      key={`quotes-item-${quotesIndex}-ciso-${cisoIndex}`}>
                      <Box>
                        <Typography variant="body-gray" component="p">
                          <Translate>{ciso.quote}</Translate>
                        </Typography>
                      </Box>
                      <Box marginTop="30px">
                        <Stack direction="row" alignItems="center">
                          <Box>
                            <Box
                              component="img"
                              className="home-image-border-radius"
                              src={ciso.profilePicture}
                              alt={ciso.name}
                              height="40px"
                              width="40px"
                            />
                          </Box>
                          <Box marginLeft="12px">
                            <Box>
                              <Typography
                                variant="description-emphasized-gray"
                                component="span">
                                {ciso.name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="description-gray"
                                component="span">
                                {ciso.title} | {ciso.companyName}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
              {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                <Box
                  width="300px"
                  marginLeft="20px"
                  key={`quotes-item-duplicate-${quotesIndex}`}>
                  {quotesItem.cisos.map((ciso, cisoIndex) => (
                    <Box
                      className="home-box-background-gray"
                      marginTop={cisoIndex > 0 ? '20px' : 0}
                      padding="10px"
                      key={`quotes-item-duplicate-${quotesIndex}-ciso-${cisoIndex}`}>
                      <Box>
                        <Typography variant="body-gray" component="p">
                          <Translate>{ciso.quote}</Translate>
                        </Typography>
                      </Box>
                      <Box marginTop="30px">
                        <Stack direction="row" alignItems="center">
                          <Box>
                            <Box
                              component="img"
                              className="home-image-border-radius"
                              src={ciso.profilePicture}
                              alt={ciso.name}
                              height="40px"
                              width="40px"
                            />
                          </Box>
                          <Box marginLeft="12px">
                            <Box>
                              <Typography
                                variant="description-emphasized-gray"
                                component="span">
                                {ciso.name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="description-gray"
                                component="span">
                                {ciso.title} | {ciso.companyName}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Testimonials;
