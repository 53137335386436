import { getResponseGet } from "../utils";

const getUsersByInteraction = async (interaction, days) => {
  let usersData = {
    result: {
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/organization_sankey_incidents/?sankey_bucket=${interaction}${
        days ? `&time_window=${days}` : ""
      }`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (
      data[`list_${interaction}_users`] &&
      Array.isArray(data[`list_${interaction}_users`])
    ) {
      const groupedData = data[`list_${interaction}_users`].reduce(
        (acc, item) => {
          if (!acc[item.email]) {
            acc[item.email] = { ...item, frequency: 1 }; // Initialize with frequency 1.
          } else {
            acc[item.email].frequency += 1; // Increment frequency.
          }
          return acc;
        },
        {}
      );

      const users = [];

      for (let i = 0; i < Object.values(groupedData).length; i++) {
        const userData = Object.values(groupedData)[i];

        const user = {
          firstName: userData.first_name,
          lastName: userData.last_name,
          initials: `${
            userData.first_name && userData.first_name.charAt(0).toUpperCase()
          }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
          email: userData.email,
          department: userData.department,
          role: userData.position_title,
          compliant: userData.compliant,
          riskScore: userData.risk_score ? +userData.risk_score.toFixed() : 0,
          frequency: userData.frequency,
        };

        users.push(user);
      }

      usersData.result.users = users;
    }
  } catch (error) {
    usersData.error = error;
  }

  return usersData;
};

export default getUsersByInteraction;
