import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowBackIosNewOutlined, SearchOutlined } from '@mui/icons-material';

import { createClient } from 'contentful';

import isEmail from 'validator/lib/isEmail';

import {
  ButtonCTA,
  FadeInSection,
  GreenItalicText,
  LoadingSpinner,
  ResourcesGroup,
} from '../../../components';

import { constants } from '../../../constants';
import { postContactMessage } from '../../../constants/apis';

import { ResourcesIllustration } from '../../../images';

import { Translate } from 'react-auto-translate';

import './resources.css';

function Resources() {
  // Loading (for subscribe submission)
  const [isLoading, setIsLoading] = useState(false);

  // Messages (error and success)
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');

  // Validation
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  // Resources
  const [resourcesBootcamp, setResourcesBootcamp] = useState([]);
  const [resourcesMostRecent, setResourcesMostRecent] = useState([]);
  const [resourcesTeam, setResourcesTeam] = useState([]);
  const [resourcesWebinarRecapBlog, setResourcesWebinarRecapBlog] = useState(
    []
  );

  // Search
  const [searchTerm, setSearchTerm] = useState('');
  const [searchAnimationClass, setSearchAnimationClass] = useState(
    'resources-box-search-animate-start'
  );

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClickSubmitSubscribe = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setMessageError('');
    setMessageSuccess('');
    setIsErrorEmail(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError('Please enter a valid email.');
    } else {
      const requestBody = {
        first_name: '',
        last_name: '',
        email,
        subject: 'Subscription request',
        message: `${email} signed up for a monthly newsletter highlighting the newest social engineering hacks to watch out for and how to best prepare for them.`,
      };

      const contactMessage = await postContactMessage(requestBody);

      if (Object.keys(contactMessage.error).length > 0) {
        setMessageError(constants.ERROR_DEFAULT);
      } else {
        setMessageSuccess('Your message has been sent.');
      }
    }

    setIsLoading(false);
  };

  const handleCloseSnackbarError = () => {
    setMessageError('');
  };

  const handleCloseSnackbarSuccess = () => {
    setMessageSuccess('');
  };

  const filterResources = (resources) => {
    if (!searchTerm) {
      return [...resources];
    }

    return resources.filter((resource) => {
      const titleMatch = resource.title.toLowerCase().includes(searchTerm);
      // const tagsMatch = resource.tags.some((tag) => tag.id.toLowerCase().includes(searchTerm));

      return titleMatch;
    });
  };

  useEffect(() => {
    const fetchResources = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        let fetchedResourcesBootcamp = [];
        let fetchedResourcesTeam = [];
        let fetchedResourcesWebinarRecapBlog = [];

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: 'resourcesBootcamp',
            limit: 3,
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesBootcamp = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesBootcamp([...fetchedResourcesBootcamp]);
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: 'resourcesTeam',
            limit: 3,
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesTeam = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: fields.resourceURL,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesTeam([...fetchedResourcesTeam]);
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: 'webinarRecapBlog',
            limit: 3,
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesTeam = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/webinar-recap-blog/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesWebinarRecapBlog([...fetchedResourcesWebinarRecapBlog]);
          })
          .catch(console.error);

        const fetchedResourcesMostRecent = [
          ...fetchedResourcesBootcamp,
          ...fetchedResourcesTeam,
          ...fetchedResourcesWebinarRecapBlog,
        ];

        fetchedResourcesMostRecent.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          if (dateA < dateB) {
            return 1;
          } else if (dateA > dateB) {
            return -1;
          }

          // Dates are the same, so sort by title.
          return a.title.localeCompare(b.title);
        });

        setResourcesMostRecent(fetchedResourcesMostRecent.slice(0, 3));

        await client
          .getEntries({
            content_type: 'resourcesBootcamp',
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesBootcamp = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesBootcamp([...fetchedResourcesBootcamp]);
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: 'resourcesTeam',
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesTeam = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: fields.resourceURL,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesTeam([...fetchedResourcesTeam]);
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: 'webinarRecapBlog',
            order: '-fields.date,fields.title',
          })
          .then((response) => {
            fetchedResourcesWebinarRecapBlog = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/webinar-recap-blog/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResourcesWebinarRecapBlog([...fetchedResourcesWebinarRecapBlog]);
          })
          .catch(console.error);
      }
    };

    fetchResources();
    setSearchAnimationClass('resources-box-search-animate-end');
  }, []);

  // Filtered resources for display based on search term
  const filteredResourcesMostRecent = filterResources(resourcesMostRecent);
  const filteredResourcesBootcamp = filterResources(resourcesBootcamp);
  const filteredResourcesTeam = filterResources(resourcesTeam);
  const filteredResourcesWebinarRecapBlog = filterResources(
    resourcesWebinarRecapBlog
  );

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '60px',
            md: '80px',
          }}
          padding="0 28px">
          <Box textAlign="center" maxWidth="800px" width="100%">
            <Typography component="h1" className="dune-text-header-uppercase">
              <Translate>Resource hub</Translate>
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              className="dune-text-header-main"
              marginTop="40px">
              <Translate>Everything you</Translate>{' '}
              <GreenItalicText text=" need to know " />
              <Translate>
                {' '}
                to combat social engineering at your enterprise
              </Translate>
            </Typography>
          </Box>
        </Box>
        <Box
          className={`resources-box-search ${searchAnimationClass}`}
          display="flex"
          justifyContent="center"
          marginTop="40px"
          paddingBottom="40px">
          <Box
            display="flex"
            justifyContent="center"
            maxWidth={{
              xs: '100%',
              md: '600px',
            }}
            marginX="auto"
            width="100%">
            <TextField
              variant="filled"
              className="dune-text-field-filled resources-text-field-search"
              name="search"
              placeholder="Search for security tips, cyber news, and more"
              type="text"
              required
              fullWidth
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment>
                    <SearchOutlined className="resources-icon-search" />
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeSearch}
            />
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Typography component="h2" className="dune-text-header-main">
              <Translate>Most recent</Translate>
            </Typography>
            <Box
              marginTop={
                filteredResourcesMostRecent.length > 0 ? '80px' : '40px'
              }>
              {filteredResourcesMostRecent.length > 0 ? (
                <ResourcesGroup
                  resources={
                    filteredResourcesMostRecent.length > 3
                      ? filteredResourcesMostRecent.slice(0, 3)
                      : filteredResourcesMostRecent
                  }
                />
              ) : (
                <Typography component="p">
                  <Translate>No resources found.</Translate>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Typography component="h2" className="dune-text-header-main">
              Dune Security <Translate>Bootcamp</Translate>
            </Typography>
            <Box
              marginTop={
                filteredResourcesBootcamp.length > 0 ? '80px' : '40px'
              }>
              {filteredResourcesBootcamp.length > 0 ? (
                <ResourcesGroup
                  resources={
                    filteredResourcesBootcamp.length > 3
                      ? filteredResourcesBootcamp.slice(0, 3)
                      : filteredResourcesBootcamp
                  }
                />
              ) : (
                <Typography component="p">
                  <Translate>No resources found.</Translate>
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="80px">
              <Button
                variant="text"
                component={Link}
                className="dune-button-text-white resources-button-view-all"
                endIcon={
                  <ArrowBackIosNewOutlined className="resources-icon-view-all" />
                }
                to="/resources/bootcamp/"
                title="Go to the bootcamp resources page.">
                <Translate>View all</Translate>
              </Button>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Typography component="h2" className="dune-text-header-main">
              <Translate>From the team</Translate>
            </Typography>
            <Box marginTop={filteredResourcesTeam.length > 0 ? '80px' : '40px'}>
              {filteredResourcesTeam.length > 0 ? (
                <ResourcesGroup
                  resources={
                    filteredResourcesTeam.length > 3
                      ? filteredResourcesTeam.slice(0, 3)
                      : filteredResourcesTeam
                  }
                />
              ) : (
                <Typography component="p">
                  <Translate>No resources found.</Translate>
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="80px">
              <Button
                variant="text"
                component={Link}
                className="dune-button-text-white resources-button-view-all"
                endIcon={
                  <ArrowBackIosNewOutlined className="resources-icon-view-all" />
                }
                to="/resources/team/"
                title="Go to the team resources page.">
                <Translate>View all</Translate>
              </Button>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Typography component="h2" className="dune-text-header-main">
              <Translate>Events</Translate>
            </Typography>
            <Box
              marginTop={
                filteredResourcesWebinarRecapBlog.length > 0 ? '80px' : '40px'
              }>
              {filteredResourcesWebinarRecapBlog.length > 0 ? (
                <ResourcesGroup
                  resources={
                    filteredResourcesWebinarRecapBlog.length > 3
                      ? filteredResourcesWebinarRecapBlog.slice(0, 3)
                      : filteredResourcesWebinarRecapBlog
                  }
                />
              ) : (
                <Typography component="p">
                  <Translate>No resources found.</Translate>
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="80px">
              <Button
                variant="text"
                component={Link}
                className="dune-button-text-white resources-button-view-all"
                endIcon={
                  <ArrowBackIosNewOutlined className="resources-icon-view-all" />
                }
                to="/resources/team/"
                title="Go to the team resources page.">
                <Translate>View all</Translate>
              </Button>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          className="resources-box-subscribe"
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '80px',
            md: '120px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%" padding="40px 0 144px">
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              alignItems="flex-end">
              <Box
                maxWidth={{
                  xs: '100%',
                  md: '800px',
                }}
                width="100%">
                <Typography component="h2" className="dune-text-header-main">
                  <Translate>Get our</Translate>{' '}
                  <GreenItalicText text="best content " />
                  <Translate>delivered in your inbox</Translate>
                </Typography>
                <Typography variant="body" component="p" marginTop="40px">
                  <Translate>
                    Sign up for a monthly newsletter highlighting the newest
                    social engineering hacks to watch out for and how to best
                    prepare for them.
                  </Translate>
                </Typography>
                <Box
                  component="form"
                  noValidate
                  marginTop="40px"
                  onSubmit={handleClickSubmitSubscribe}>
                  <Stack direction="row" alignItems="center">
                    <Box maxWidth="520px" width="100%">
                      <TextField
                        variant="filled"
                        className="resources-text-field-email"
                        name="email"
                        placeholder={'Email'}
                        type="text"
                        error={isErrorEmail}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Box>
                    <Box marginLeft="20px">
                      <ButtonCTA
                        text={<Translate>{'Subscribe'}</Translate>}
                        isSubmit
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box maxWidth="720px" width="100%" marginTop="20px">
                  <Typography component="p" className="resources-text-privacy">
                    <Translate>
                      We value your privacy and we'll only send you relevant
                      info. For more details, check out our
                    </Translate>{' '}
                    <Link
                      className="resources-link-privacy-policy"
                      to="/privacy-policy/"
                      title="Go to the Privacy Policy page.">
                      <Translate>privacy policy</Translate>
                    </Link>
                    .
                  </Typography>
                </Box>
              </Box>
              <Box
                maxWidth="400px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: '80px',
                }}
                marginTop={{
                  xs: '40px',
                  md: 0,
                }}>
                <ResourcesIllustration />
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>
      <Snackbar
        open={messageSuccess !== ''}
        onClose={handleCloseSnackbarSuccess}>
        <Alert className="dune-alert-success" severity="success">
          {messageSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={messageError !== ''} onClose={handleCloseSnackbarError}>
        <Alert className="dune-alert-error" severity="error">
          {messageError}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Resources;
