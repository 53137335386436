const capitalizeWords = (str) => {
  return str.replace(/\b\w+/g, (word) => {
    return word === "bec"
      ? "BEC"
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

const formatAreasToImproveOrganization = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  const formattedAreasToImproveOrganization = Object.entries(areasToImprove)
    .sort(([, a], [, b]) => b - a) // Sort by value (descending).
    .slice(0, 6) // Limit to top 6.
    .map(([name]) => ({ name: capitalizeWords(name) })); // Map to the desired format.

  return formattedAreasToImproveOrganization;
};

export default formatAreasToImproveOrganization;
