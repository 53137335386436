import React from "react";

const CautionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M15.3615 11.0209L9.68844 2.1223C9.54668 1.90429 9.3443 1.72353 9.10136 1.59793C8.85842 1.47232 8.58335 1.40625 8.30343 1.40625C8.02351 1.40625 7.74844 1.47232 7.5055 1.59793C7.26256 1.72353 7.06018 1.90429 6.91842 2.1223L1.24539 11.0209C1.10899 11.2318 1.03711 11.4716 1.03711 11.7159C1.03711 11.9601 1.10899 12.1999 1.24539 12.4108C1.38534 12.6301 1.58738 12.8119 1.83077 12.9374C2.07416 13.0629 2.35015 13.1277 2.6304 13.125H13.9765C14.2565 13.1275 14.5322 13.0626 14.7754 12.9371C15.0185 12.8116 15.2203 12.6299 15.3602 12.4108C15.4968 12.2 15.5689 11.9602 15.5691 11.716C15.5693 11.4718 15.4977 11.2319 15.3615 11.0209ZM7.78446 6.09379C7.78446 5.96947 7.83913 5.85024 7.93646 5.76233C8.03379 5.67442 8.16579 5.62504 8.30343 5.62504C8.44107 5.62504 8.57307 5.67442 8.6704 5.76233C8.76773 5.85024 8.8224 5.96947 8.8224 6.09379V8.43754C8.8224 8.56186 8.76773 8.68109 8.6704 8.769C8.57307 8.8569 8.44107 8.90629 8.30343 8.90629C8.16579 8.90629 8.03379 8.8569 7.93646 8.769C7.83913 8.68109 7.78446 8.56186 7.78446 8.43754V6.09379ZM8.30343 11.25C8.14947 11.25 7.99896 11.2088 7.87094 11.1315C7.74292 11.0543 7.64315 10.9445 7.58423 10.816C7.52531 10.6875 7.50989 10.5461 7.53993 10.4097C7.56997 10.2733 7.64411 10.1481 7.75298 10.0497C7.86185 9.9514 8.00055 9.88443 8.15156 9.8573C8.30257 9.83017 8.45909 9.84409 8.60133 9.89731C8.74358 9.95053 8.86516 10.0407 8.9507 10.1563C9.03623 10.2719 9.08189 10.4078 9.08189 10.5469C9.08189 10.7334 8.99987 10.9122 8.85388 11.0441C8.70789 11.176 8.50989 11.25 8.30343 11.25Z"
        fill="#FEE442"
      />
    </svg>
  );
};

export default CautionIcon;
