const formatAreasToImproveOld = (threats) => {
  if (!threats || threats.length === 0) {
    return [];
  }

  let areasToImprove = [];
  let actualAreasToImprove = [];

  for (let i = 0; i < threats.length; i++) {
    const areaToImprove = threats[i];

    if (areaToImprove.threat === "link manipulation") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Link manipulation",
      });
    } else if (areaToImprove.threat === "c suite impersonation") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "C-suite impersonation",
      });
    } else if (areaToImprove.threat === "internal processes") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Impersonation of internal processes",
      });
    } else if (areaToImprove.point_of_failure === "display name deception") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Display name deception",
      });
    } else if (areaToImprove.point_of_failure === "request for action") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Request for action",
      });
    } else if (areaToImprove.point_of_failure === "financial transactions") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Unexpected financial transactions",
      });
    } else if (areaToImprove.point_of_failure === "tech notifs") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Fake technical notifications",
      });
    } else if (areaToImprove.point_of_failure === "pretexting") {
      actualAreasToImprove.push({
        count: areaToImprove.count,
        threat: "Pretexting",
      });
    }
  }

  actualAreasToImprove.sort(
    (a, b) =>
      b.count - a.count &&
      a.threat.toUpperCase().localeCompare(b.threat.toUpperCase())
  );

  areasToImprove = actualAreasToImprove.map((areaToImprove) => {
    return {
      name: areaToImprove.threat,
    };
  });

  return areasToImprove;
};

export default formatAreasToImproveOld;
