import React from "react";

const ButtonOutlinedGray = ({
  children,
  padding = "py-[5px] px-[10px]",
  additionalClassName = "",
  title,
  onClick,
}) => {
  return (
    <button
      className={`description-emphasized ${padding} border border-border-color rounded-small ${additionalClassName}`}
      title={title}
      aria-label={title}
      onClick={onClick ? onClick : null}
    >
      {children}
    </button>
  );
};

export default ButtonOutlinedGray;
