import React from "react";

import {
    Box,
    Stack
} from "@mui/material";

import "./stepper.css";

function Stepper({
    steps,
    activeStep
}) {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            {
                Array.from({ length: steps }).map((_, index) =>
                    <Box
                        className={index === activeStep - 1 ? "stepper-box-step-active stepper-box-step" : "stepper-box-step"}
                        height="5px"
                        width="18px"
                        marginLeft={index > 0 ? "10px" : 0}
                        sx={{
                            background: index <= activeStep - 1 ? "#00FF82" : "#4F4E4D"
                        }}
                    ></Box>
                )
            }
        </Stack>
    );
}

export default Stepper;