const formatShowHeadsUp = (pastAttacks) => {
    if (!pastAttacks || pastAttacks.length === 0) {
        return false;
    }

    const sortedPastAttacks = [...pastAttacks];
    sortedPastAttacks.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp : a.date;
        const timestampB = b.timestamp ? b.timestamp : b.date;

        const dateA = new Date(timestampA);
        const dateB = new Date(timestampB);

        return dateA - dateB;
    });

    if (!sortedPastAttacks[sortedPastAttacks.length - 1].link_clicked) {
        return false;
    }

    let recentPastAttackTimestamp = null;

    if (sortedPastAttacks[sortedPastAttacks.length - 1].timestamp) {
        recentPastAttackTimestamp = sortedPastAttacks[sortedPastAttacks.length - 1].timestamp;
    } else if (sortedPastAttacks[sortedPastAttacks.length - 1].date) {
        recentPastAttackTimestamp = sortedPastAttacks[sortedPastAttacks.length - 1].date;
    } else {
        return false;
    }

    const recentDate = new Date(recentPastAttackTimestamp);

    const twoDaysBefore = new Date();
    twoDaysBefore.setDate(twoDaysBefore.getDate() - 2);

    return recentDate > twoDaysBefore;
};

export default formatShowHeadsUp;