import { formatAreasToImproveOld, getResponseGet } from "../utils";

const getInsightsOrganizationAreasToImproveOld = async () => {
  let insightsOrganizationAreasToImprove = {
    result: {
      areasToImprove: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      "/organization_data/?component=points_of_failure"
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    insightsOrganizationAreasToImprove.result = {
      areasToImprove: formatAreasToImproveOld(data.points_of_failure),
    };
  } catch (error) {
    insightsOrganizationAreasToImprove.error = error;
  }

  return insightsOrganizationAreasToImprove;
};

export default getInsightsOrganizationAreasToImproveOld;
