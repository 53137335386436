import React from "react";

const LeaderboardOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18 5.63062H14V2.63062C14 1.52762 13.103 0.630615 12 0.630615H8C6.897 0.630615 6 1.52762 6 2.63062V7.63062H2C0.897 7.63062 0 8.52762 0 9.63062V18.6306C0 18.8958 0.105357 19.1502 0.292893 19.3377C0.48043 19.5253 0.734784 19.6306 1 19.6306H19C19.2652 19.6306 19.5196 19.5253 19.7071 19.3377C19.8946 19.1502 20 18.8958 20 18.6306V7.63062C20 6.52762 19.103 5.63062 18 5.63062ZM2 9.63062H6V17.6306H2V9.63062ZM8 8.63062V2.63062H12V17.6306H8V8.63062ZM18 17.6306H14V7.63062H18V17.6306Z"
        fill="white"
      />
    </svg>
  );
};

export default LeaderboardOutlinedIcon;
