import React, { useEffect } from "react";

import { Card } from "../..";

import { CloseIcon } from "../../../images";

const Modal = ({ isOpen, onClose, children, title }) => {
  // Disable background scrolling when modal is open.
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Clean up on modal close.
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      <div className="z-10 p-[40px] max-w-[540px] w-full">
        <Card padding="p-0">
          <div className="flex items-center justify-between p-1.25">
            <h4 className="description-emphasized">{title}</h4>
            <button
              className="text-white"
              title="Close asset viewer."
              aria-label="Close asset viewer."
              onClick={onClose}
            >
              <div className="w-[16px] h-[16px]">
                <CloseIcon />
              </div>
            </button>
          </div>
          <div>{children}</div>
        </Card>
      </div>
    </div>
  );
};

export default Modal;
