import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { spacing } from '../../../styles';
import './privacy-policy.css';
import { Translate } from 'react-auto-translate';

function PrivacyPolicy() {
  const [bodyContent, setBodyContent] = useState(null);

  useEffect(() => {
    const updateComponent = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        try {
          const response = await client.getEntries({
            content_type: 'privacyPolicy',
            limit: 1,
          });
          if (response.items.length > 0) {
            const { fields } = response.items[0];
            setBodyContent(fields.body.content);
          }
        } catch (error) {
          console.error('Error fetching privacy policy:', error);
        }
      }
    };

    updateComponent();
  }, []);

  return (
    <Box>
      <Box
        className="privacy-policy-box-header"
        display="flex"
        justifyContent="center"
        marginTop="30px"
        padding="0 30px">
        <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%" padding="40px 0">
          <Typography variant="h1" component="h1">
            <Translate>Privacy Policy</Translate>
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop="40px"
        padding="0 30px">
        <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
          <Box className="privacy-policy-box-body-content">
            {bodyContent ? (
              bodyContent.map((contentItem, index) =>
                documentToReactComponents(contentItem)
              )
            ) : (
              <Typography>
                <Translate>Loading...</Translate>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
