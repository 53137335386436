import React from "react";

const AssessmentOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M8 17.6306C7.45 17.6306 7 17.1806 7 16.6306V11.6306C7 11.0806 7.45 10.6306 8 10.6306C8.55 10.6306 9 11.0806 9 11.6306V16.6306C9 17.1806 8.55 17.6306 8 17.6306ZM12 17.6306C11.45 17.6306 11 17.1806 11 16.6306V8.63062C11 8.08062 11.45 7.63062 12 7.63062C12.55 7.63062 13 8.08062 13 8.63062V16.6306C13 17.1806 12.55 17.6306 12 17.6306ZM16 17.6306C15.45 17.6306 15 17.1806 15 16.6306V14.6306C15 14.0806 15.45 13.6306 16 13.6306C16.55 13.6306 17 14.0806 17 14.6306V16.6306C17 17.1806 16.55 17.6306 16 17.6306ZM18 19.6306H6C5.45 19.6306 5 19.1806 5 18.6306V6.63062C5 6.08062 5.45 5.63062 6 5.63062H18C18.55 5.63062 19 6.08062 19 6.63062V18.6306C19 19.1806 18.55 19.6306 18 19.6306ZM19 3.63062H5C3.9 3.63062 3 4.53062 3 5.63062V19.6306C3 20.7306 3.9 21.6306 5 21.6306H19C20.1 21.6306 21 20.7306 21 19.6306V5.63062C21 4.53062 20.1 3.63062 19 3.63062Z"
        fill="white"
      />
    </svg>
  );
};

export default AssessmentOutlinedIcon;
