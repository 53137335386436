import React from "react";

const GPPGoodOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M20.9954 7.5306C20.9788 7.36255 20.9198 7.20146 20.824 7.06241C20.7281 6.92337 20.5986 6.81093 20.4474 6.7356L12.4474 2.7356C12.3085 2.66608 12.1553 2.62988 11.9999 2.62988C11.8446 2.62988 11.6914 2.66608 11.5524 2.7356L3.55243 6.7356C3.4017 6.81135 3.27254 6.92394 3.17692 7.06291C3.0813 7.20189 3.02231 7.36275 3.00543 7.5306C2.99443 7.6376 2.04443 18.2976 11.5944 22.5446C11.722 22.6022 11.8604 22.632 12.0004 22.632C12.1404 22.632 12.2788 22.6022 12.4064 22.5446C21.9564 18.2976 21.0064 7.6386 20.9954 7.5306ZM12.0004 20.5276C5.23143 17.2556 4.91143 10.2726 4.96643 8.2656L12.0004 4.7486L19.0294 8.2636C19.0664 10.2526 18.7014 17.2816 12.0004 20.5276Z"
        fill="white"
      />
      <path
        d="M11 13.2166L8.70697 10.9236L7.29297 12.3376L11 16.0446L16.707 10.3376L15.293 8.92365L11 13.2166Z"
        fill="white"
      />
    </svg>
  );
};

export default GPPGoodOutlinedIcon;
