import React from 'react';

import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { FadeInSection } from '../../../components';

import './security-and-compliance.css';
import { Translate } from 'react-auto-translate';

function SecurityAndCompliance() {
  const strategyPoints = [
    {
      point: 'Advanced network and application security protocols',
    },
    {
      point: 'Rigorous identity and access management',
    },
    {
      point: 'Proactive change and vulnerability management',
    },
    {
      point: 'External third-party penetration testing',
    },
    {
      point: 'Detailed logging',
    },
    {
      point: 'Vendor risk management',
    },
    {
      point: 'Thorough physical and endpoint security measures',
    },
    {
      point: 'Comprehensive governance, compliance, and HR security',
    },
    {
      point: 'Well-prepared disaster recovery plans',
    },
    {
      point:
        "Advanced Cloud and Infrastructure Security including Longbow's Security Risk Remediation Platform for AWS",
    },
    {
      point: 'Additional security controls',
    },
  ];

  return (
    <Box
      sx={{
        backgroundImage:
          "url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/security-and-compliance-background.png')",
        backgroundSize: 'min(100vw, 1600px)',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
      }}>
      <FadeInSection>
        <Box display="flex" justifyContent="center" padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Typography
              component="h1"
              className="dune-text-header-uppercase"
              marginTop="80px">
              <Translate>Company</Translate>
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              className="dune-text-header-main"
              marginTop="40px">
              <Translate>Security and compliance</Translate>
            </Typography>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: '40px',
            md: '80px',
          }}
          padding="0 28px">
          <Box maxWidth="1152px" width="100%">
            <Box
              className="security-and-compliance-box-white-border"
              padding={{
                xs: '40px 28px',
                md: '80px',
              }}>
              <Typography variant="body" component="p">
                <Translate>
                  At Dune Security, we're proud to be pioneers in developing the
                  world's first Employee Security Posture Management Solution.
                  Our commitment extends beyond innovation; we prioritize the
                  protection of our clients' data with the highest standards of
                  security and compliance in the industry. Our solutions not
                  only meet but exceed the world-class data security
                  expectations of our clients, earning recognition from highly
                  security-conscious organizations worldwide.
                </Translate>
              </Typography>
              <Typography variant="body" component="p" marginTop="28px">
                <Translate>
                  Understanding the critical nature of our customers' data, we
                  deploy an unwavering approach to its management, security, and
                  storage. Our dedicated security team orchestrates a holistic
                  information security strategy that encompasses:
                </Translate>
              </Typography>
              <Box marginTop="28px">
                <List className="security-and-compliance-list" disablePadding>
                  {strategyPoints.map((strategyPointItem, index) => (
                    <ListItem
                      className="security-and-compliance-list-item"
                      disablePadding
                      key={`strategy-point-${index}`}>
                      <ListItemText
                        className="security-and-compliance-list-item-text"
                        primary={
                          <Translate>{strategyPointItem.point}</Translate>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Typography variant="body" component="p" marginTop="28px">
                <Translate>
                  Our infrastructure, entirely hosted on AWS, undergoes regular
                  scans and annual penetration tests to swiftly identify and
                  rectify vulnerabilities. We process and store customer data
                  securely within specified AWS regions, ensuring strict access
                  controls, vigilant monitoring, and auditing for compliance. We
                  utilize TLS 1.3 encryption for the utmost security of data in
                  transit and at rest. Our AWS-hosted solutions benefit from
                  independent SOC 2 Type II audits, affirming our adherence to
                  top-tier security standards.
                </Translate>
              </Typography>
              <Typography variant="body" component="p" marginTop="28px">
                <Translate>
                  We urge our customers to practice vigilant security
                  management, including the maintenance of strong passwords and
                  access restrictions. Should any account credential compromises
                  occur within your Identity Provider, we request immediate
                  notification through our support team at
                </Translate>{' '}
                <Link
                  className="security-and-compliance-link-email"
                  href="mailto:support@dunesecurity.io"
                  title="Email support@dunesecurity.io.">
                  support@dunesecurity.io
                </Link>
                <Translate>
                  , ensuring prompt and effective resolution.
                </Translate>
              </Typography>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <Box className="resource-box-main-content">
        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="120px"
            paddingTop="80px"
            paddingBottom="120px"
            paddingLeft="28px"
            paddingRight="28px">
            <Box maxWidth="1152px" width="100%">
              <Typography
                component="h3"
                className="security-and-compliance-text-heading">
                Dune Security's <Translate>compliance</Translate>
              </Typography>
              <Box
                className="security-and-compliance-box-black-border"
                marginTop={{
                  xs: '40px',
                  md: '80px',
                }}
                padding={{
                  xs: '40px 28px',
                  md: '80px',
                }}>
                <Box display="flex" justifyContent="center">
                  <Box
                    component="img"
                    loading="lazy"
                    alt="SOC 2 Type II compliant"
                    src="https://d3oo9a669kwmx7.cloudfront.net/logos/aicpa-soc-logo.png"
                    height="240px"
                  />
                </Box>
                <Typography
                  component="h4"
                  className="security-and-compliance-text-heading"
                  marginTop="28px">
                  SOC 2 Type II examination
                </Typography>
                <Typography component="p" marginTop="28px">
                  <Translate>
                    Dune Security has been independently audited against SOC 2
                    Security, Availability, and Confidentiality Trust Services
                    Criteria (TSC) by Dr. Vinod Singh with Accorp Partners. This
                    examination affirms Dune Security's commitment to and
                    maintenance of the highest levels of information security,
                    availability, and confidentiality of our internal
                    infrastructure, controls, and care to customer data.
                  </Translate>
                </Typography>
                <Typography component="p" marginTop="28px">
                  <Translate>
                    SOC 2 is an industry standard examination that was developed
                    and maintained by the American Institute of Certified Public
                    Accountants (AICPA).
                  </Translate>
                </Typography>
                <Typography component="p" marginTop="28px">
                  <Translate>
                    Dune Security's customers can submit a request for SOC 2
                    report through our customer support team.
                  </Translate>
                </Typography>
              </Box>
            </Box>
          </Box>
        </FadeInSection>
      </Box>
    </Box>
  );
}

export default SecurityAndCompliance;
