import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import { ListDune } from "../../../../../components";

import "./step-with-images.css";

function StepWithImages({ selectedIdentityProvider, listItems, images }) {
  const [imageIndex, setImageIndex] = useState(0);

  const intervalRef = useRef(null);

  const clearExistingInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const handleClickArrowBack = () => {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1);
      startInterval();
    }
  };

  const handleClickArrowForward = () => {
    if (imageIndex < images.length - 1) {
      setImageIndex(imageIndex + 1);
      startInterval();
    }
  };

  const startInterval = useCallback(() => {
    clearExistingInterval();

    intervalRef.current = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
  }, [clearExistingInterval, images.length]);

  useEffect(() => {
    startInterval();

    return () => clearExistingInterval();
  }, [startInterval, clearExistingInterval]);

  return (
    <Box display="flex" justifyContent="center">
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box width={{ xs: "100%", md: "552px" }}>
          <Card className="step-with-images-card">
            <CardContent className="step-with-images-card-content">
              <Box>
                <Typography variant="h2" component="h2">
                  <Typography
                    component="span"
                    className="step-with-images-text-header-identity-provider"
                  >
                    {selectedIdentityProvider.name}
                  </Typography>{" "}
                  Configuration
                </Typography>
              </Box>
              <Box marginTop="30px">
                <ListDune listItems={listItems} />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box
          maxWidth={{ xs: "100%", md: "656px" }}
          marginLeft={{ xs: 0, md: "100px" }}
          width="100%"
        >
          <Box>
            <Box
              component="img"
              className="step-with-images-image"
              src={images[imageIndex].link}
              width="100%"
            />
          </Box>
          {images.length > 1 && (
            <Box display="flex" justifyContent="center">
              <Stack direction="row" alignItems="center">
                <Box>
                  <IconButton onClick={handleClickArrowBack}>
                    <ArrowBackIos className="step-with-images-icon-arrow" />
                  </IconButton>
                </Box>
                <Box width="36px">
                  <Typography
                    variant="description-emphasized"
                    component="p"
                    textAlign="center"
                  >
                    {imageIndex + 1} of {images.length}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={handleClickArrowForward}>
                    <ArrowForwardIos className="step-with-images-icon-arrow" />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default StepWithImages;
