import {
  formatAreasToImproveUser,
  formatBlastRadius,
  formatComplianceFrameworks,
  formatLearningModulesActivity,
  formatProfileData,
  formatRecentTestingActivity,
  formatRiskScore,
  formatRiskScoreOverTime,
  formatScoreChange,
  getResponseGet,
} from "../utils";

/**
 * Fetches personalized risk data for an end user.
 * This function retrieves detailed risk data for an individual user, including descriptions,
 * profile information, risk scores, and suggested areas for improvement.
 *
 * @returns {Object} An object containing the fetched user insights data in the 'result' field, or an error object in the 'error' field.
 *   - The 'result' object includes:
 *     - descriptionData: An object with detailed descriptions such as the user's first interaction date, name, and total points.
 *     - profileData: An object with the user's profile information including name, email, department, role, and direct manager.
 *     - riskScore: A numerical value representing the user's current risk score.
 *     - changeInRiskScore: A numerical value indicating the change in the user's risk score over a specified period.
 *     - riskScoreOverTime: An array of the user's risk scores over time to track trends.
 *     - areasToImprove: An array of specific areas where the user can improve their risk profile.
 *   - The 'error' field contains an error object if the request fails, detailing the issue encountered.
 */
const getInsightsUsers = async (email) => {
  let insightsUsers = {
    result: {
      profileData: {
        name: "",
        email: "",
        department: "",
        role: "",
        directManager: "",
      },
      blastRadius: {
        level: "",
        function: "",
      },
      riskScore: 0,
      scoreChange: 0,
      userAverage: 0,
      riskScoreOverTime: [],
      areasToImprove: [],
      complianceFrameworks: [],
      recentTestingActivity: [],
      learningModulesActivity: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/other_user_risk_data/${email}/?area_to_improve=method`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      let riskScore = 0;

      if (data.risk_score && data.risk_score.current_risk_score) {
        riskScore = formatRiskScore(data.risk_score.current_risk_score);
      } else {
        riskScore = 0;
      }

      insightsUsers.result = {
        profileData: formatProfileData(data.user_information),
        blastRadius: formatBlastRadius(data.blast_radius),
        riskScore,
        scoreChange: formatScoreChange(riskScore, data.time_series),
        userAverage: formatRiskScore(data.user_average),
        riskScoreOverTime: formatRiskScoreOverTime(data.time_series),
        areasToImprove: formatAreasToImproveUser(data.areas_to_improve),
        complianceFrameworks: formatComplianceFrameworks(data.compliance),
        recentTestingActivity: formatRecentTestingActivity(data.past_attacks),
        learningModulesActivity: formatLearningModulesActivity(
          data.assigned_training
        ),
      };
    }
  } catch (error) {
    insightsUsers.error = error;
  }

  return insightsUsers;
};

export default getInsightsUsers;
