import React from "react";

import {
    Box,
    Card,
    CardContent
} from "@mui/material";

import "./step-with-card-only.css";

function StepWithCardOnly({
    mainContent,
    cardWidth
}) {
    return (
        <Box display="flex" justifyContent="center">
            <Box width={cardWidth}>
                <Card className="step-with-images-card">
                    <CardContent className="step-with-images-card-content">
                        <Box maxHeight="500px" height="100%" overflow="auto">
                            {mainContent}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default StepWithCardOnly;