import React from "react";

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";

import { CopyToClipboard } from "react-copy-to-clipboard";

import "./list-dune.css";

function ListDune({ listItems }) {
  const handleChangeInputValue = (event, setInputValue) => {
    if (setInputValue) {
      setInputValue(event.target.value);
    }
  };

  return (
    <List disablePadding>
      {listItems.map((item, index) => (
        <ListItem
          className="list-dune-list-item"
          disablePadding
          key={`list-item-${index}`}
        >
          <ListItemIcon className="list-dune-list-item-icon">
            <Typography
              variant="description-emphasized"
              component="span"
              className="list-dune-text-list-item-icon"
            >
              {index + 1}
            </Typography>
          </ListItemIcon>
          <ListItemText
            className="list-dune-list-item-text"
            primary={
              item.copyValue || item.setInputValue ? (
                <Box className="list-dune-box-list-item-text">
                  <Box>
                    <Typography
                      variant="h4"
                      component="span"
                      className="list-dune-text-list-item-text"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  {item.copyValue && (
                    <>
                      <Box marginTop="10px">
                        <TextField
                          variant="filled"
                          className="dune-text-field-filled-gray dune-text-field-filled-gray-multiline"
                          value={item.copyValue}
                          rows={3}
                          multiline
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                          }}
                        />
                      </Box>
                      <Box marginTop="10px">
                        <CopyToClipboard text={item.copyValue}>
                          <Button
                            variant="contained"
                            className="dune-button-contained-gray dune-text-field-filled-gray-multiline"
                            startIcon={<FileCopy />}
                            title="Copy this value."
                            aria-label="Copy this value."
                          >
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Box>
                    </>
                  )}
                  {item.setInputValue && (
                    <Box marginTop="10px">
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled-gray"
                        value={item.inputValue}
                        placeholder="Metadata URL"
                        rows={3}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) =>
                          handleChangeInputValue(event, item.setInputValue)
                        }
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  component="span"
                  className="list-dune-text-list-item-text"
                >
                  {item.text}
                </Typography>
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default ListDune;
