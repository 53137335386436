import { getResponseGet } from "../utils";

const getTestHTMLSimulationSchedule = async (assetFileName) => {
  let testHTML = {
    result: {
      testHTML: "",
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/get-simulation-templates/?asset_name=${assetFileName}`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    testHTML.result.testHTML = `
      <div className="p-[10px]">
          ${data}
      </div>`;
  } catch (error) {
    testHTML.error = error;
  }

  return testHTML;
};

export default getTestHTMLSimulationSchedule;
