import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import { LogoItem } from "../../common";

import {
  pagesPlatform,
  pagesPlatformBeforeBRA,
  pagesPlatformEndUser,
  pagesPlatformManager,
} from "../../../constants";

import "./navigation-platform-side.css";

function NavigationPlatformSide({
  email,
  isBRACompleted,
  isAdmin,
  isManager,
  pagePath,
}) {
  const [open, setOpen] = useState({
    Insights: window.location.pathname.startsWith("/insights/"),
    "My dashboard": window.location.pathname.startsWith("/dashboard/"),
  });
  const [pathPageWithSubpages, setPathPageWithSubpages] = useState("");

  const getUpdatedOpen = useCallback(() => {
    const insights = window.location.pathname.startsWith("/insights/");
    const dashboard = window.location.pathname.startsWith("/dashboard/");

    return {
      Insights: insights,
      "My dashboard": dashboard,
    };
  }, []);

  const handleClickExpand = (key) => {
    const updatedOpen = { ...open };
    updatedOpen[key] = !updatedOpen[key];
    setOpen(updatedOpen);
  };

  const handleClickPageWithSubpages = (path) => {
    setPathPageWithSubpages(path);
  };

  useEffect(() => {
    if (pathPageWithSubpages) {
      setPathPageWithSubpages("");
    } else {
      setOpen(getUpdatedOpen());
    }
  }, [pathPageWithSubpages, getUpdatedOpen]);

  useEffect(() => {
    setOpen(getUpdatedOpen());
  }, [pagePath, getUpdatedOpen]);

  const pathname = window.location.pathname;

  // Navigate to the first subpage of the clicked page that has subpages.
  if (pathPageWithSubpages) {
    return <Navigate to={pathPageWithSubpages} />;
  }

  const pages = isManager
    ? pagesPlatformManager
    : isAdmin
    ? !isBRACompleted
      ? pagesPlatformBeforeBRA
      : pagesPlatform
    : pagesPlatformEndUser;

  return (
    <Box className="navigation-platform-side-box" width="100%">
      <Box display="flex">
        <LogoItem height="32px" />
      </Box>
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Box flexGrow={1} padding="30px 0">
          <List component="nav" disablePadding>
            {Object.keys(pages).map((pageKey, pageIndex) => {
              const page = pages[pageKey];

              if (
                !(
                  email &&
                  email === "david@dune.demo" &&
                  page.path === "/dashboard/insights/"
                )
              ) {
                let isPageSelected = false;

                if (page.path === "/dashboard/insights/") {
                  isPageSelected =
                    window.location.pathname === "/dashboard/insights/";
                } else if (page.path === "/insights/organization/") {
                  isPageSelected = [
                    "/insights/organization/",
                    "/insights/departments/",
                    "/insights/search-employee/",
                    "/insights/user/",
                  ].includes(window.location.pathname);
                } else {
                  const currentPagePath = page.path.substring(1).split("/");

                  for (let i = 0; i < pagePath.length; i++) {
                    for (let j = 0; j < currentPagePath.length; j++) {
                      if (pagePath[i] === currentPagePath[j]) {
                        isPageSelected = true;
                        break;
                      }
                    }
                  }
                }

                return (
                  <Fragment key={`navigation-platform-list-item-${pageIndex}`}>
                    <ListItem
                      className="navigation-platform-side-list-item-page"
                      disablePadding
                    >
                      {page.subpages ? (
                        // If this page has subpages.
                        <ListItemButton
                          className={
                            isPageSelected
                              ? "navigation-platform-list-item-button navigation-platform-list-item-button-selected"
                              : "navigation-platform-list-item-button"
                          }
                          aria-label={page.name}
                        >
                          <ListItemIcon className="navigation-platform-side-list-item-icon">
                            <div className="w-[24px] h-[24px]">
                              {isPageSelected ? page.activeIcon : page.icon}
                            </div>
                          </ListItemIcon>
                          <ListItemText
                            onClick={() =>
                              handleClickPageWithSubpages(page.path)
                            }
                          >
                            <Typography
                              variant={
                                isPageSelected ? "body-emphasized" : "body"
                              }
                            >
                              {page.name}
                            </Typography>
                          </ListItemText>
                          {page.subpages &&
                            (open[page.name] ? (
                              <ArrowDropUp
                                className="navigation-platform-side-icon-expand"
                                onClick={() => handleClickExpand(page.name)}
                              />
                            ) : (
                              <ArrowDropDown
                                className="navigation-platform-side-icon-expand"
                                onClick={() => handleClickExpand(page.name)}
                              />
                            ))}
                        </ListItemButton>
                      ) : (
                        // If this page has no subpages.
                        <ListItemButton
                          className={
                            isPageSelected
                              ? "navigation-platform-list-item-button navigation-platform-list-item-button-selected"
                              : "navigation-platform-list-item-button"
                          }
                          component={Link}
                          to={page.path}
                        >
                          <ListItemIcon className="navigation-platform-side-list-item-icon">
                            <div className="w-[24px] h-[24px]">
                              {isPageSelected ? page.activeIcon : page.icon}
                            </div>
                          </ListItemIcon>
                          <ListItemText>
                            <Typography
                              variant={
                                isPageSelected ? "body-emphasized" : "body"
                              }
                            >
                              {page.name}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      )}
                    </ListItem>
                    {page.subpages && (
                      <Collapse
                        in={open[page.name]}
                        className="navigation-platform-collapse"
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {Object.keys(page.subpages).map(
                            (subpageKey, subpageIndex) => {
                              const subpage = page.subpages[subpageKey];

                              const isSubpageSelected =
                                subpage.path === pathname ||
                                (pathname === "/insights/employees/" &&
                                  subpage.path ===
                                    "/insights/search-employee/");

                              return (
                                <ListItem
                                  disablePadding
                                  className="navigation-platform-side-list-item-subpage"
                                >
                                  <ListItemButton
                                    className={
                                      isSubpageSelected
                                        ? "navigation-platform-list-item-button navigation-platform-list-item-button-selected"
                                        : "navigation-platform-list-item-button"
                                    }
                                    component={Link}
                                    to={subpage.path}
                                    key={`page-${pageIndex}-subpage-${subpageIndex}`}
                                  >
                                    <ListItemIcon className="navigation-platform-side-list-item-icon">
                                      <div className="w-[24px] h-[24px]">
                                        {isSubpageSelected
                                          ? subpage.activeIcon
                                          : subpage.icon}
                                      </div>
                                    </ListItemIcon>
                                    <ListItemText>
                                      <Typography
                                        variant={
                                          isSubpageSelected
                                            ? "body-emphasized"
                                            : "body"
                                        }
                                      >
                                        {subpage.name}
                                      </Typography>
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </Collapse>
                    )}
                  </Fragment>
                );
              }

              return <></>;
            })}
          </List>
        </Box>
        <Box>
          <Typography
            variant="detailed"
            component="p"
            className="navigation-platform-side-text-copyright"
          >
            Copyright © 2024 Dune Security
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default NavigationPlatformSide;
