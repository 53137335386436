import React, { Fragment, useState } from "react";

import { HalfCircleIcon, OpenCircleIcon } from "./utils";

import { Card } from "../../../../common";

import {
  CheckmarkIcon,
  DropdownArrowIcon,
  FailIcon,
} from "../../../../../images";

const headers = [
  {
    id: "title",
    label: "Title",
    align: "left",
    width: "80%",
  },
  {
    id: "status",
    label: "Status",
    align: "center",
    width: "20%",
  },
];

function CardRecentTrainingActivity({ learningModulesActivity }) {
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const formatDate = (date) => {
    const givenDate = new Date(date);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return givenDate.toLocaleDateString("en-US", options);
  };

  return (
    <Card>
      <div id="card-recent-testing-activity">
        <h3 className="card-header">Recent Training Activity</h3>
      </div>

      <table className="mt-0.625">
        <colgroup>
          {headers.map((header, index) => (
            <col style={{ width: header.width }} key={`col-${index}`} />
          ))}
        </colgroup>
        <thead>
          <tr className="border border-b-border-color">
            {headers.map((header, index) => (
              <th
                key={`head-cell-${index}`}
                className={`card-header text-white p-[8px] cursor-pointer`}
              >
                <div
                  className={`flex flex-row items-center justify-${
                    header.align === "center"
                      ? "center"
                      : header.align === "right"
                      ? "end"
                      : "start"
                  }`}
                >
                  {header.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {learningModulesActivity &&
            learningModulesActivity.map((learningModule, index) => (
              <Fragment key={`row-${index}`}>
                <tr
                  className={`${
                    expandedRow !== index ? "border border-b-border-color" : ""
                  } ${
                    learningModule.status.toLowerCase() === "completed"
                      ? "cursor-pointer"
                      : ""
                  } last:border-0`}
                  onClick={
                    learningModule.status.toLowerCase() === "completed"
                      ? () => toggleRow(index)
                      : null
                  }
                >
                  <td>
                    <div className="flex flex-row items-center">
                      <div className="flex w-[20px] h-[20px] mr-[10px]">
                        {learningModule.status.toLowerCase() === "completed" &&
                        learningModule.userAnswer &&
                        learningModule.scoreOverall >= 4 ? (
                          <CheckmarkIcon />
                        ) : learningModule.status.toLowerCase() ===
                            "completed" &&
                          learningModule.userAnswer &&
                          learningModule.scoreOverall < 4 ? (
                          <FailIcon />
                        ) : learningModule.status.toLowerCase() ===
                          "in progress" ? (
                          <HalfCircleIcon />
                        ) : (
                          <OpenCircleIcon />
                        )}
                      </div>
                      <div>
                        <p className="overflow-hidden description-emphasized whitespace-nowrap text-ellipsis">
                          {learningModule.name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="p-[8px]">
                    <div className="flex flex-row items-center justify-center">
                      {learningModule.status.toLowerCase() === "completed" &&
                      learningModule.userAnswer &&
                      learningModule.scoreOverall >= 4 ? (
                        <div className="flex flex-row items-center justify-center">
                          <div className="flex flex-row items-center bg-green py-[2px] px-[8px] rounded-[4px]">
                            <p className="text-black detailed-emphasized">
                              Completed
                            </p>
                          </div>
                          <div
                            className={`ml-[20px] ${
                              expandedRow === index ? "rotate-180" : ""
                            }`}
                          >
                            <DropdownArrowIcon />
                          </div>
                        </div>
                      ) : learningModule.status.toLowerCase() === "completed" &&
                        learningModule.userAnswer &&
                        learningModule.scoreOverall < 4 ? (
                        <div className="flex flex-row items-center justify-center">
                          <div className="flex flex-row items-center bg-red py-[2px] px-[8px] rounded-[4px]">
                            <p className="text-black detailed-emphasized">
                              Completed
                            </p>
                          </div>
                          <div
                            className={`ml-[20px] ${
                              expandedRow === index ? "rotate-180" : ""
                            }`}
                          >
                            <DropdownArrowIcon />
                          </div>
                        </div>
                      ) : learningModule.status.toLowerCase() ===
                        "in progress" ? (
                        <div className="flex flex-row items-center bg-yellow py-[2px] px-[8px] rounded-[4px]">
                          <p className="text-black detailed-emphasized">
                            In Progress
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center bg-gray py-[2px] px-[8px] rounded-[4px]">
                          <p className="text-black detailed-emphasized">
                            Not Started
                          </p>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr className="border border-b-border-color">
                    <td colSpan={headers.length}>
                      <div className="mt-0.625 mb-1.25">
                        <div className="bg-[#201F1E] rounded-small py-[20px] px-[50px]">
                          <div className="flex items-center justify-between">
                            <p className="description-emphasized">
                              Overall score:{" "}
                              <span
                                className={
                                  learningModule.scoreOverall >= 7
                                    ? "text-green"
                                    : learningModule.scoreOverall >= 4
                                    ? "text-yellow"
                                    : "text-red"
                                }
                              >
                                {learningModule.scoreOverall}/10
                              </span>
                            </p>
                            {learningModule.completionDate && (
                              <p className="description-emphasized">
                                Date completed:{" "}
                                {formatDate(learningModule.completionDate)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="mt-1.25">
                            <h5 className="card-header tracking-[5px]">
                              User Response
                            </h5>
                            <p className="mt-0.625 description">
                              {learningModule.userAnswer}
                            </p>
                          </div>
                          <div className="mt-1.25">
                            <h5 className="card-header tracking-[5px]">
                              Score Analysis
                            </h5>
                            <p className="mt-0.625 description">
                              {learningModule.justificationUnderstanding}
                            </p>
                          </div>
                          <div className="mt-1.25">
                            <h5 className="card-header tracking-[5px]">
                              Sentiment Justification
                            </h5>
                            <p className="mt-0.625 description">
                              {learningModule.justificationSentiment}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
    </Card>
  );
}

export default CardRecentTrainingActivity;
