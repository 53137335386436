import {
  formatRiskScore,
  formatRiskScoreOverTime,
  formatScoreChange,
  getResponseGet,
} from "../utils";

const getInsightsOrganizationRiskScore = async (days) => {
  let insightsOrganizationRiskScore = {
    result: {
      riskScore: 0,
      riskScoreOverTime: [],
      scoreChange: 0,
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/organization_data/?component=org_time_series${
        days ? `&time_window=${days}` : ""
      }`
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    insightsOrganizationRiskScore.result = {
      riskScore: formatRiskScore(data.average_risk_score.average_risk_score),
      riskScoreOverTime: formatRiskScoreOverTime(data.org_time_series),
      scoreChange: formatScoreChange(
        data.average_risk_score.average_risk_score,
        data.org_time_series
      ),
    };
  } catch (error) {
    insightsOrganizationRiskScore.error = error;
  }

  return insightsOrganizationRiskScore;
};

export default getInsightsOrganizationRiskScore;
