import React from "react";
import { useNavigate } from "react-router-dom";

import { ButtonOutlinedGray, UsersTable } from "../../..";

const headers = [
  {
    id: "departmentName",
    label: "Department",
    isSortable: true,
    isSearchable: false,
    align: "left",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "120px",
  },
];

function CardDepartmentRiskScores({ departments }) {
  const navigate = useNavigate();

  const handleClickViewMore = () => {
    navigate("/insights/departments/");
  };

  return (
    <div>
      {departments && (
        <UsersTable
          data={departments.map(({ name, riskScore }) => ({
            departmentName: name,
            riskScore,
          }))}
          headers={headers}
          rowsPerPage={6}
          defaultOrder="desc"
          defaultOrderBy="riskScore"
          disablePagination
          disableSearchBar
          customFooter={
            <div className="mt-1.25">
              <ButtonOutlinedGray onClick={handleClickViewMore}>
                View more
              </ButtonOutlinedGray>
            </div>
          }
        />
      )}
    </div>
  );
}

export default CardDepartmentRiskScores;
