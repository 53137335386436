import React from "react";

const DropdownArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
    >
      <path
        d="M1.24492 3.18367L4.85187 6.79063C5.39501 7.33376 6.27237 7.33376 6.81551 6.79063L10.4225 3.18367C11.2998 2.3063 10.6731 0.802246 9.43368 0.802246L2.21977 0.802246C0.980316 0.802246 0.367552 2.3063 1.24492 3.18367Z"
        fill="white"
      />
    </svg>
  );
};

export default DropdownArrowIcon;
