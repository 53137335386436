import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

import { getTrainings } from "../../../api";

import "./trainings.css";

function Trainings({ isInitialLoading, email, setIsPlatformLoading }) {
  // Modules
  const [learningModules, setLearningModules] = useState(null);
  const [complianceModules, setComplianceModules] = useState(null);
  const [completedModules, setCompletedModules] = useState(null);

  const getThumbnailFileName = (module, isCompliance = false) => {
    if (!(module && module.fileName)) {
      return "";
    }

    let extension = ".jpg"; // Default is .jpg.

    if (module.startDate) {
      const dateUpdate = new Date("2024-06-04T00:00:00");
      const dateModule = new Date(module.startDate);

      if (dateModule < dateUpdate) {
        extension = ".png";
      }
    }

    return `https://d36cattz2ccgpt.cloudfront.net/${
      !isCompliance ? "general/" : ""
    }${module.fileName.slice(0, -4)}${extension}`;
  };

  useEffect(() => {
    const loadTrainings = async () => {
      setIsPlatformLoading(true);

      if (email === "duncan.idaho@dune.demo") {
        // Demo account for end user
        setLearningModules([
          {
            id: "0",
            status: "Not started",
            title: "Domain Spoofing",
            fileName: "domain-spoofing.mp4",
            startDate: "2024-06-18T00:00:00",
          },
          {
            id: "1",
            status: "Not started",
            title: "Malicious Attachments",
            fileName: "malicious-attachments.mp4",
            startDate: "2024-06-18T00:00:00",
          },
          {
            id: "2",
            status: "Not started",
            title: "Business Email Compromise",
            fileName: "business-email-compromise.mp4",
            startDate: "2024-06-18T00:00:00",
          },
        ]);

        setComplianceModules([
          {
            name: "SOC 2 Type 2",
            videos: [
              {
                id: "3",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Foundations of SOC 2 Compliance",
                fileName: "soc-2-type-2/01-foundations-of-soc-2-compliance.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "4",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Key Security Practices for SOC 2",
                fileName:
                  "soc-2-type-2/02-key-security-practices-for-soc-2.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "5",
                category: "SOC 2 Type 2",
                status: "Not started",
                title: "Role-Specific Training and Responsibilities",
                fileName:
                  "soc-2-type-2/03-role-specific-training-and-responsibilities.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "HIPAA",
            videos: [
              {
                id: "6",
                category: "HIPAA",
                status: "Not started",
                title: "Foundations of HIPAA",
                fileName: "hipaa/01-foundations-of-hipaa.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "7",
                category: "HIPAA",
                status: "Not started",
                title: "Understanding and Protecting PHI",
                fileName: "hipaa/02-understanding-and-protecting-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "8",
                category: "HIPAA",
                status: "Not started",
                title: "A Guide to Protecting Electronic PHI",
                fileName: "hipaa/03-a-guide-to-protecting-electronic-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "ISO 27001",
            videos: [
              {
                id: "9",
                category: "ISO 27001",
                status: "completed",
                title: "Introduction to ISO 27001",
                fileName: "iso-27001/01-introduction-to-iso-27001.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "10",
                category: "ISO 27001",
                status: "Not started",
                title: "The Information Security Management System (ISMS)",
                fileName:
                  "iso-27001/02-the-information-security-management-system-isms.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "11",
                category: "ISO 27001",
                status: "Not started",
                title: "Certification and Continuous Improvement",
                fileName:
                  "iso-27001/03-certification-and-continuous-improvement.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
        ]);

        setCompletedModules([]);
      } else if (email === "aaron.chavez@dune.demo") {
        // Demo account for manager
        setLearningModules([]);

        setComplianceModules([
          {
            name: "SOC 2 Type 2",
            videos: [
              {
                id: "3",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Foundations of SOC 2 Compliance",
                fileName: "soc-2-type-2/01-foundations-of-soc-2-compliance.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "4",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Key Security Practices for SOC 2",
                fileName:
                  "soc-2-type-2/02-key-security-practices-for-soc-2.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "5",
                category: "SOC 2 Type 2",
                status: "completed",
                title: "Role-Specific Training and Responsibilities",
                fileName:
                  "soc-2-type-2/03-role-specific-training-and-responsibilities.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "HIPAA",
            videos: [
              {
                id: "6",
                category: "HIPAA",
                status: "completed",
                title: "Foundations of HIPAA",
                fileName: "hipaa/01-foundations-of-hipaa.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "7",
                category: "HIPAA",
                status: "completed",
                title: "Understanding and Protecting PHI",
                fileName: "hipaa/02-understanding-and-protecting-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "8",
                category: "HIPAA",
                status: "completed",
                title: "A Guide to Protecting Electronic PHI",
                fileName: "hipaa/03-a-guide-to-protecting-electronic-phi.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
          {
            name: "ISO 27001",
            videos: [
              {
                id: "9",
                category: "ISO 27001",
                status: "completed",
                title: "Introduction to ISO 27001",
                fileName: "iso-27001/01-introduction-to-iso-27001.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "10",
                category: "ISO 27001",
                status: "completed",
                title: "The Information Security Management System (ISMS)",
                fileName:
                  "iso-27001/02-the-information-security-management-system-isms.mp4",
                startDate: "2024-06-03T00:00:00",
              },
              {
                id: "11",
                category: "ISO 27001",
                status: "completed",
                title: "Certification and Continuous Improvement",
                fileName:
                  "iso-27001/03-certification-and-continuous-improvement.mp4",
                startDate: "2024-06-03T00:00:00",
              },
            ],
          },
        ]);

        setCompletedModules([
          {
            id: "0",
            status: "Not started",
            title: "Domain Spoofing",
            fileName: "domain-spoofing.mp4",
            startDate: "2024-06-18T00:00:00",
          },
        ]);
      } else {
        const trainings = await getTrainings();

        if (Object.keys(trainings.error).length > 0) {
          console.error(trainings.error.message);
        } else {
          const { learningModules, complianceModules, completedModules } =
            trainings.result;

          setLearningModules(learningModules);
          setComplianceModules(complianceModules);
          setCompletedModules(completedModules);
        }
      }

      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      loadTrainings();
    }
  }, [isInitialLoading, email, setIsPlatformLoading]);

  return (
    <Box>
      <Box marginTop="80px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Your learning modules
        </Typography>
        {learningModules && Array.isArray(learningModules) ? (
          learningModules.length > 0 ? (
            <>
              <Typography component="p" margin="40px 0">
                Lower your risk score by completing your assigned trainings.
              </Typography>
              <Grid container>
                {learningModules.map((module, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    marginTop={{
                      xs: index !== 0 ? "40px" : 0,
                      sm: index !== 0 && index !== 1 ? "40px" : 0,
                      lg: index > 2 ? "40px" : 0,
                    }}
                    paddingLeft={{
                      xs: 0,
                      sm: index % 2 === 1 ? "20px" : 0,
                      lg:
                        index % 3 === 2
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      sm: index % 2 === 0 ? "20px" : 0,
                      lg:
                        index % 3 === 0
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                  >
                    <Link
                      className="trainings-link"
                      to="/dashboard/trainings/learning/"
                      title={`Go to the module named "${module.title}".`}
                      state={{
                        selectedVideo: module,
                        modules: learningModules,
                        category: "Learning modules",
                      }}
                    >
                      <Card className="resources-group-card">
                        <Box
                          className="resources-group-box-image"
                          overflow="hidden"
                        >
                          <CardMedia
                            component="img"
                            className="resources-group-card-media"
                            alt={module.title}
                            src={getThumbnailFileName(module)}
                          />
                        </Box>
                        <CardContent className="resources-group-card-content">
                          <Typography component="p">{module.title}</Typography>
                          {/* <Typography component="p" marginTop="12px" className="resources-group-text-date">
                                                    {formatDate(module.date)}
                                                </Typography> */}
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography component="p" margin="40px 0">
              No assigned trainings
            </Typography>
          )
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            marginTop="40px"
            minHeight="253px"
            height="100%"
          >
            <Skeleton
              variant="rectangular"
              className="dune-skeleton-rectangular"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </Box>
      {(complianceModules === null ||
        (Array.isArray(complianceModules) && complianceModules.length > 0)) && (
        <Box marginTop="100px">
          <Typography component="h2" className="dune-text-header-dashboard">
            Compliance trainings
          </Typography>
          {complianceModules && Array.isArray(complianceModules) ? (
            <>
              <Typography component="p" margin="40px 0">
                Keep watching your compliance videos to breeze through your
                mandatory trainings.
              </Typography>
              <Grid container>
                {complianceModules.map((module, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    marginTop={{
                      xs: index !== 0 ? "80px" : 0,
                      sm: index !== 0 && index !== 1 ? "80px" : 0,
                      lg: index > 2 ? "80px" : 0,
                    }}
                    paddingLeft={{
                      xs: 0,
                      sm: index % 2 === 1 ? "20px" : 0,
                      lg:
                        index % 3 === 2
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      sm: index % 2 === 0 ? "20px" : 0,
                      lg:
                        index % 3 === 0
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                  >
                    <Link
                      className="trainings-link"
                      to="/dashboard/trainings/compliance/"
                      title={`Go to the ${module.name} modules.`}
                      state={{
                        selectedVideo: module.videos[0],
                        modules: module.videos,
                        category: `${module.name} compliance`,
                      }}
                    >
                      <Card className="resources-group-card">
                        <Box
                          className="resources-group-box-image"
                          overflow="hidden"
                        >
                          <CardMedia
                            component="img"
                            className="resources-group-card-media"
                            alt={module.name}
                            src={getThumbnailFileName(module.videos[0], true)}
                          />
                        </Box>
                        <CardContent className="resources-group-card-content">
                          <Typography component="p">{module.name}</Typography>
                          {/* <Typography component="p" marginTop="12px" className="resources-group-text-date">
                                                                {formatDate(module.date)}
                                                            </Typography> */}
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              marginTop="40px"
              minHeight="253px"
              height="100%"
            >
              <Skeleton
                variant="rectangular"
                className="dune-skeleton-rectangular"
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </Box>
      )}
      <Box marginTop="80px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Completed modules
        </Typography>
        {completedModules && Array.isArray(completedModules) ? (
          completedModules.length > 0 ? (
            <>
              <Typography component="p" margin="40px 0">
                Check out the learning modules you've already mastered. Keep up
                the great work!
              </Typography>
              <Grid container>
                {completedModules.map((module, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    marginTop={{
                      xs: index !== 0 ? "40px" : 0,
                      sm: index !== 0 && index !== 1 ? "40px" : 0,
                      lg: index > 2 ? "40px" : 0,
                    }}
                    paddingLeft={{
                      xs: 0,
                      sm: index % 2 === 1 ? "20px" : 0,
                      lg:
                        index % 3 === 2
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      sm: index % 2 === 0 ? "20px" : 0,
                      lg:
                        index % 3 === 0
                          ? `${(60 / 3) * 2}px`
                          : index % 3 === 1
                          ? `${60 / 3}px`
                          : 0,
                    }}
                  >
                    <Link
                      className="trainings-link"
                      to="/dashboard/trainings/completed/"
                      title={`Go to the module named "${module.title}".`}
                      state={{
                        selectedVideo: module,
                        modules: [...learningModules, ...completedModules],
                        category: "Learning modules",
                      }}
                    >
                      <Card className="resources-group-card">
                        <Box
                          className="resources-group-box-image"
                          overflow="hidden"
                        >
                          <CardMedia
                            component="img"
                            className="resources-group-card-media"
                            alt={module.title}
                            src={getThumbnailFileName(module)}
                          />
                        </Box>
                        <CardContent className="resources-group-card-content">
                          <Typography component="p">{module.title}</Typography>
                          {/* <Typography component="p" marginTop="12px" className="resources-group-text-date">
                                                                        {formatDate(module.date)}
                                                                    </Typography> */}
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography component="p" margin="40px 0">
              No completed modules
            </Typography>
          )
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            marginTop="40px"
            minHeight="253px"
            height="100%"
          >
            <Skeleton
              variant="rectangular"
              className="dune-skeleton-rectangular"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Trainings;
