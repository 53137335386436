import { getResponseGet } from "../utils";

const getUsersByDepartment = async (department) => {
  let usersData = {
    result: {
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(`/users/department/${department}/`);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    for (let i = 0; i < data.length; i++) {
      const userData = data[i];

      const user = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        initials: `${
          userData.first_name && userData.first_name.charAt(0).toUpperCase()
        }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
        email: userData.email,
        department: userData.department,
        riskScore: userData.risk_score ? +userData.risk_score.toFixed() : 0,
      };

      usersData.result.users.push(user);
    }
  } catch (error) {
    usersData.error = error;
  }

  return usersData;
};

export default getUsersByDepartment;
