import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { Box, IconButton, Stack, Typography, TextField } from "@mui/material";
import { YouTube } from "@mui/icons-material";

import {
  ButtonCTA,
  LinkedInIcon,
  LogoItem,
  DuneButton,
  XIcon,
  EmailIcon,
  GreenItalicText,
} from "../../common";

import { pagesWebsiteLeft } from "../../../constants";

import { spacing } from "../../../styles";
import { constants } from "../../../constants";

import isEmail from "validator/lib/isEmail";

import { postHubspotSubscribe } from "../../../api/database";

import { Translate } from "react-auto-translate";

import "./footer.css";

function Footer() {
  // const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const handleClickSubmitSubscribe = async (event) => {
    event.preventDefault();

    // setIsLoading(true);
    setMessageError("");
    setMessageSuccess("");
    setIsErrorEmail(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError("Please enter a valid email.");
    } else {
      const requestBody = {
        email,
      };

      try {
        const hubspotSubscribe = await postHubspotSubscribe(requestBody);

        if (hubspotSubscribe && hubspotSubscribe.error) {
          setMessageError(constants.ERROR_DEFAULT);
        } else {
          setMessageSuccess(
            "Your subscription has been successfully processed."
          );
          alert("Subscription request sent.");
        }
      } catch (error) {
        setMessageError(
          "There was an issue processing your request. Please try again later."
        );
      }
    }

    // setIsLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      marginTop="160px"
      padding="0 30px"
    >
      <Box
        maxWidth={{
          xs: "100%",
          md: spacing.MAX_WIDTH_WEBSITE,
        }}
        width="100%"
      >
        <Box className="footer-box-main" padding="20px">
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            justifyContent="space-between"
          >
            <Box>
              <Box maxWidth="450px" width="100%">
                <Box>
                  <Typography variant="h3" component="h3">
                    <Translate>Contact Us</Translate>
                  </Typography>
                </Box>
                <Box marginTop="10px">
                  <Typography variant="body-gray" component="p">
                    <Translate>
                      Reach out to our team for expert assistance and to find
                      out more about Dune Security.
                    </Translate>
                  </Typography>
                </Box>
                <Box marginTop="24px">
                  <ButtonCTA
                    text="Get in touch"
                    path="/company/contact-us/"
                    tooltip="Go to the contact us page."
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="center"
                component="form"
                onSubmit={handleClickSubmitSubscribe}
              >
                <TextField
                  id="standard-basic"
                  variant="standard"
                  name="email"
                  placeholder="Sign up for our newsletter"
                  aria-label="Sign up for our newsletter"
                  sx={{
                    maxWidth: "275px",
                    width: "100%",
                    marginTop: "20px",
                    marginRight: "20px",
                    "& .MuiInputBase-root": {
                      color: "#C2BFB8",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#C2BFB8",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#C2BFB8",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#C2BFB8",
                    },
                  }}
                  error={isErrorEmail}
                  helperText={isErrorEmail ? "Invalid email address" : ""}
                />
                <Box marginTop="12px" maxWidth="100%">
                  <DuneButton text="Sign up" />
                </Box>
              </Box>
              {messageError && (
                <Box marginTop="10px">
                  <Typography variant="body2" color="error">
                    {messageError}
                  </Typography>
                </Box>
              )}
              {messageSuccess && (
                <Box marginTop="10px">
                  <Typography variant="body2" color="success">
                    {messageSuccess}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              marginTop={{
                xs: "60px",
                md: 0,
              }}
            >
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                }}
              >
                <Box
                  marginLeft={{
                    xs: 0,
                    md: "30px",
                  }}
                >
                  <Box>
                    <LogoItem height="40px" />
                  </Box>
                  <Box marginTop="10px">
                    <Typography variant="body-gray" component="p">
                      <Translate>Employee Risk Management</Translate>
                      <br />
                      <GreenItalicText text="powered by AI" />
                    </Typography>
                  </Box>
                  <Box marginTop="20px">
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        className="footer-icon-button"
                        title="Go to Dune Security's LinkedIn page."
                        aria-label="Go to Dune Security's LinkedIn page."
                        href="https://www.linkedin.com/company/dune-security/"
                        target="_blank"
                      >
                        <LinkedInIcon role="button" />
                      </IconButton>
                      <IconButton
                        className="footer-icon-button"
                        title="Go to Dune Security's YouTube page."
                        aria-label="Go to Dune Security's YouTube page."
                        href="https://www.youtube.com/@DuneSecurity"
                        target="_blank"
                      >
                        <YouTube role="button" />
                      </IconButton>
                      <IconButton
                        className="footer-icon-button"
                        title="Go to Dune Security's X page."
                        aria-label="Go to Dune Security's X page."
                        href="https://x.com/DuneSecurity"
                        target="_blank"
                      >
                        <XIcon role="button" />
                      </IconButton>
                      <IconButton
                        component="a"
                        href="mailto:marketing@dunesecurity.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-icon-button"
                        title="Go to Dune Security's email."
                        aria-label="Go to Dune Security's email."
                      >
                        <EmailIcon role="button" />
                      </IconButton>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      marginTop: {
                        xs: "10px",
                        md: "50px",
                      },
                    }}
                  >
                    <Typography variant="detailed" component="p">
                      <Translate>
                        Copyright © {new Date().getFullYear()} Dune Security
                      </Translate>
                    </Typography>
                    <Typography variant="detailed" component="p">
                      <Link
                        className="dune-link copyright-link"
                        title="Go to the Privacy Policy page."
                        aria-label="Go to the Privacy Policy page."
                        to="/privacy-policy/"
                      >
                        <Translate>Privacy Policy</Translate>
                      </Link>{" "}
                      |{" "}
                      <Link
                        className="dune-link copyright-link"
                        title="Go to the Fulfillment Policy page."
                        aria-label="Go to the Fulfillment Policy page."
                        to="/fulfillment-policy/"
                      >
                        <Translate>Fulfillment Policy</Translate>
                      </Link>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={{
                    xs: "20px",
                    md: 0,
                  }}
                >
                  <Stack
                    direction={{
                      xs: "column",
                      md: "row",
                    }}
                  >
                    {pagesWebsiteLeft.map((page, pageIndex) => (
                      <Box
                        marginLeft={{
                          xs: 0,
                          md: "30px",
                        }}
                        marginTop={{
                          xs: pageIndex > 0 ? "40px" : 0,
                          md: 0,
                        }}
                      >
                        <Stack direction="column">
                          <NavLink
                            variant="text"
                            className="footer-button-main-page"
                            to={page.path}
                            title={page.tooltip}
                            key={`footer-page-${pageIndex}`}
                          >
                            <Translate>{page.name}</Translate>
                          </NavLink>
                          {page.subpages &&
                            page.subpages.map((subpage, subpageIndex) => (
                              <NavLink
                                variant="text"
                                className="footer-button-subpage"
                                to={subpage.path}
                                title={subpage.tooltip}
                                key={`footer-page-${pageIndex}-subpage-${subpageIndex}`}
                              >
                                <Translate>{subpage.name}</Translate>
                              </NavLink>
                            ))}
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
