import {
  Dashboard,
  DashboardOutlined,
  School,
  SchoolOutlined,
} from "@mui/icons-material";

import {
  AssessmentFilledIcon,
  AssessmentOutlinedIcon,
  DonutFilledIcon,
  DonutOutlinedIcon,
  GPPGoodFilledIcon,
  GPPGoodOutlinedIcon,
  LeaderboardFilledIcon,
  LeaderboardOutlinedIcon,
  NetworkFilledIcon,
  NetworkOutlinedIcon,
  PersonFilledIcon,
  PersonOutlinedIcon,
} from "../images";

const pagesPlatform = {
  INSIGHTS: {
    name: "Insights",
    path: "/insights/organization/",
    icon: <LeaderboardOutlinedIcon />,
    activeIcon: <LeaderboardFilledIcon />,
    tooltip: "Go to the insights page.",
    subpages: {
      ORGANIZATION: {
        name: "Organization",
        path: "/insights/organization/",
        icon: <DonutOutlinedIcon />,
        activeIcon: <DonutFilledIcon />,
        tooltip: "Go to the organization insights page.",
      },
      DEPARTMENTS: {
        name: "Departments",
        path: "/insights/departments/",
        icon: <NetworkOutlinedIcon />,
        activeIcon: <NetworkFilledIcon />,
        tooltip: "Go to the department insights page.",
      },
      EMPLOYEES: {
        name: "Employees",
        path: "/insights/search-employee/",
        icon: <PersonOutlinedIcon />,
        activeIcon: <PersonFilledIcon />,
        tooltip: "Go to the employees insights page.",
      },
    },
  },
  COMPLIANCE: {
    name: "Compliance",
    path: "/compliance/",
    icon: <GPPGoodOutlinedIcon />,
    activeIcon: <GPPGoodFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  REPORTING: {
    name: "Reporting",
    path: "/reporting/",
    icon: <AssessmentOutlinedIcon />,
    activeIcon: <AssessmentFilledIcon />,
    tooltip: "Go to the reporting page.",
  },
  MY_DASHBOARD: {
    name: "My dashboard",
    path: "/dashboard/insights/",
    icon: <DashboardOutlined />,
    activeIcon: <Dashboard />,
    tooltip: "Go to your dashboard page.",
    subpages: {
      INSIGHTS: {
        name: "Insights",
        path: "/dashboard/insights/",
        icon: <LeaderboardOutlinedIcon />,
        activeIcon: <LeaderboardFilledIcon />,
        tooltip: "Go to the insights page.",
      },
      TRAININGS: {
        name: "Trainings",
        path: "/dashboard/trainings/",
        icon: <SchoolOutlined />,
        activeIcon: <School />,
        tooltip: "Go to the trainings page.",
      },
    },
  },
};

export default pagesPlatform;
