import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import { Translate } from 'react-auto-translate';

import './button-cta.css';

function ButtonCTA({
  text = 'Book a demo',
  path = '/demo/',
  tooltip = 'Go to the demo page.',
  isSubmit = false,
}) {
  if (isSubmit) {
    return (
      <Button
        variant="contained"
        type="submit"
        className="button-cta-button"
        title={tooltip}
        sx={{
          maxWidth: {
            xs: '100%',
            md: 'fit-content',
          },
        }}>
        {text}
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      type="button"
      className="button-cta-button"
      fullWidth
      component={Link}
      to={path}
      title={tooltip}
      aria-label={tooltip}
      sx={{
        maxWidth: {
          xs: '100%',
          md: 'fit-content',
        },
      }}>
      {<Translate>{text}</Translate>}
    </Button>
  );
}

export default ButtonCTA;
