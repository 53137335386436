const formatProfileData = (userInformation) => {
  let profileData = {
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    role: "",
    directManager: "",
  };

  if (userInformation) {
    profileData.firstName = userInformation.first_name || "";
    profileData.lastName = userInformation.last_name || "";
    profileData.initials = `${
      userInformation.first_name && userInformation.first_name.charAt(0)
    }${userInformation.last_name && userInformation.last_name.charAt(0)}`;
    profileData.email = userInformation.email || "";
    profileData.department = userInformation.department || "";
    profileData.role = userInformation.role || "";
    profileData.directManager = userInformation.manager || "";
  }

  return profileData;
};

export default formatProfileData;
