import { getResponsePost } from '../utils';

export const postContactUs = async (requestBody) => {
  let contactUs = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost('/contact-us/', requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    contactUs.result = response.data;
  } catch (error) {
    contactUs.error = error;
  }

  return contactUs;
};

export default postContactUs;
