import React from "react";

const LegendBoxIcon = ({ color = "#00ff82" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M0 6.5C0 3.18629 2.68629 0.5 6 0.5H12C15.3137 0.5 18 3.18629 18 6.5V12.5C18 15.8137 15.3137 18.5 12 18.5H6C2.68629 18.5 0 15.8137 0 12.5V6.5Z"
        fill={color}
      />
    </svg>
  );
};

export default LegendBoxIcon;
