const formatScoreChange = (riskScore, timeSeries) => {
  if (!riskScore || !timeSeries || timeSeries.length === 0) {
    return 0;
  }

  // Sort the time series data by date first.
  const sortedTimeSeries = [...timeSeries];
  sortedTimeSeries.sort((a, b) => {
    const timestampA = a.timestamp ? a.timestamp : a.date;
    const timestampB = b.timestamp ? b.timestamp : b.date;

    const dateA = new Date(timestampA);
    const dateB = new Date(timestampB);

    return dateA - dateB;
  });

  const firstScore = sortedTimeSeries[0].risk_score;
  const scoreChange = riskScore - firstScore;

  return scoreChange;
};

export default formatScoreChange;
