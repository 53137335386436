import { getResponsePost } from '../utils';

export const postHubspotSubscribe = async (requestBody) => {
  let hubspotSubscribe = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost(
      '/subscribe-to-hubspot/',
      requestBody
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    hubspotSubscribe.result = response.data;
  } catch (error) {
    hubspotSubscribe.error = error;
  }

  return hubspotSubscribe;
};

export default postHubspotSubscribe;
