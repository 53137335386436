const pagesWebsiteLeft = [
  {
    name: 'Product',
    path: '/product/',
    tooltip: 'Go to the product page.',
    subpages: [
      {
        name: 'Quantify risk',
        path: '/product/#quantify-risk',
        tooltip: 'Go to the quantify risk section.',
      },
      {
        name: 'Remediate risk',
        path: '/product/#remediate-risk',
        tooltip: 'Go to the remediate risk section.',
      },
      {
        name: 'Behavioral data',
        path: '/product/#behavioral-data',
        tooltip: 'Go to the behavioral data section.',
      },
    ],
  },
  {
    name: 'Resources',
    path: '/resources/',
    tooltip: 'Go to the resource hub page.',
    subpages: [
      {
        name: 'Resource hub',
        path: '/resources/',
        tooltip: 'Go to the resource hub page.',
      },
      {
        name: 'Bootcamp',
        path: '/resources/bootcamp/',
        tooltip: 'Go to the bootcamp resources page.',
      },
      {
        name: 'From the team',
        path: '/resources/team/',
        tooltip: 'Go to the team resources page.',
      },
    ],
  },
  {
    name: 'Company',
    path: '/company/',
    tooltip: 'Go to the about page.',
    subpages: [
      {
        name: 'About',
        path: '/company/',
        tooltip: 'Go to the about page.',
      },
      {
        name: 'CISO Advisory Council',
        path: '/company/ciso-advisory-council/',
        tooltip: 'Go to the CISO Advisory Council page.',
      },
      {
        name: 'Contact us',
        path: '/company/contact-us/',
        tooltip: 'Go to the contact us page.',
      },
      {
        name: 'Compliance',
        path: '/company/security-and-compliance/',
        tooltip: 'Go to the security and compliance page.',
      },
    ],
  },
];

// const pagesWebsiteLeft = [
//     {
//         name: "Product",
//         path: "/product/",
//         tooltip: "Go to the product page.",
//         categories: [
//             {
//                 name: "Product",
//                 subpages: [
//                     {
//                         name: "Product",
//                         description: "Target your high-risk employees with contextualized training on their points of weakness. Let your low-risk employees get back to work.",
//                         path: "/product/",
//                         tooltip: "Go to the product page."
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         name: "Resources",
//         categories: [
//             {
//                 name: "Resources",
//                 subpages: [
//                     {
//                         name: "Resource hub",
//                         description: "Everything you need to know to combat social engineering at your enterprise.",
//                         path: "/resources/",
//                         tooltip: "Go to the resource hub page."
//                     },
//                     {
//                         name: "Dune Security Bootcamp",
//                         description: "All the resources you need from Dune Security",
//                         path: "/resources/bootcamp/",
//                         tooltip: "Go to the bootcamp resources page."
//                     },
//                     {
//                         name: "From the team",
//                         description: "All the resources made by the team",
//                         path: "/resources/team/",
//                         tooltip: "Go to the team resources page."
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         name: "Company",
//         categories: [
//             {
//                 name: "About",
//                 subpages: [
//                     {
//                         name: "About",
//                         description: "Dune Security is reducing the attack surface at every enterprise.",
//                         path: "/company/",
//                         tooltip: "Go to the about page."
//                     },
//                     {
//                         name: "Contact us",
//                         description: "Leave us a message below or sent us an email at info@dunesecurity.io.",
//                         path: "/company/contact-us/",
//                         tooltip: "Go to the contact us page."
//                     }
//                 ]
//             },
//             {
//                 name: "Documents",
//                 subpages: [
//                     {
//                         name: "Security and compliance",
//                         description: "",
//                         path: "/company/documents/security-and-compliance/",
//                         tooltip: "Go to the security and compliance page."
//                     }
//                 ]
//             }
//         ]
//     }
// ];

export default pagesWebsiteLeft;
